import { AxiosError } from 'axios';
import { ApiError, ElementClassificationMasterAddPayload, ElementClassificationMasterUpdatePayload } from 'models';
import elementClassificationMasterService from '../elementClassificationMasterService';

function useElementClassificationMaster() {
  async function addElementClassificationMaster(payload: ElementClassificationMasterAddPayload) {
    try {
      const response = await elementClassificationMasterService.add(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  async function updateElementClassificationMaster(payload: ElementClassificationMasterUpdatePayload[]) {
    try {
      const response = await elementClassificationMasterService.update(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  return { addElementClassificationMaster, updateElementClassificationMaster };
}

export default useElementClassificationMaster;
