import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFound } from 'components/common/NotFound';
import PartsConfigurationList from './pages/PartsConfigurationList';
import PartsConfigurationDetail from './pages/PartsConfigurationDetail';

function PartsConfiguration() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PartsConfigurationList />
      </Route>
      <Route exact path={`${path}/copy/:element_cd`}>
        <PartsConfigurationDetail />
      </Route>
      <Route exact path={`${path}/edit/:element_cd`}>
        <PartsConfigurationDetail />
      </Route>
      <Route exact path={`${path}/new`}>
        <PartsConfigurationDetail />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default PartsConfiguration;
