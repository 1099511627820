import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFound } from 'components/common/NotFound';
import PurchaseProcessedCalorieAllergyDetailSetting from './pages/CalorieAllergyDetailSetting';
import PurchaseProcessedCalorieAllergySettingList from './pages/PurchaseProcessedCalorieAllergySettingList';

function PurchaseProcessedCalorieAllergySetting() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PurchaseProcessedCalorieAllergySettingList />
      </Route>
      <Route exact path={`${path}/:sms_cd`}>
        <PurchaseProcessedCalorieAllergyDetailSetting />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default PurchaseProcessedCalorieAllergySetting;
