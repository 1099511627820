import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AllergyItemMasterUpdatePayload } from 'models/masterdata';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { allergyItemMasterActions, selectAllergyItemMasterData } from 'store/AllergyItemMaster/allergyItemMasterSlice';
import AllergyItemMasterForm from './components/AllergyItemMasterForm';
import AllergyItemMasterList from './components/AllergyItemMasterList';
import useAllergyItemMaster from './hooks/useAllergyItemMaster';
import './style.scss';

export type AllergyItemMasterListFormValues = {
  data: AllergyItemMasterUpdatePayload[];
};

function AllergyItemMasterFeature() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // hooks
  const { updateAllergyItemMaster } = useAllergyItemMaster();

  // refs
  const formRef = useRef<HTMLFormElement>(null);
  const nextDisplayOrder = useRef<number>(1);

  // states
  const [showForm, setShowForm] = useState(false);

  // selectors
  const allergyItemMasterList = useAppSelector(selectAllergyItemMasterData);

  // side effects
  useEffect(() => {
    dispatch(allergyItemMasterActions.fetchAllergyItemMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allergyItemMasterList.length === 0) {
      nextDisplayOrder.current = 1;
      return;
    }

    const maxDisplayOrder = allergyItemMasterList.reduce(
      (max, item) => (item.display_order > max ? item.display_order : max),
      allergyItemMasterList[0].display_order
    );
    nextDisplayOrder.current = maxDisplayOrder + 1;
  }, [allergyItemMasterList]);

  //events
  function handleOpenForm() {
    setShowForm(true);
  }

  function handleCloseForm() {
    setShowForm(false);
  }

  function handleAllergyItemMasterFormReflect() {
    dispatch(allergyItemMasterActions.fetchAllergyItemMasterList());
  }

  function handleSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }

  function handleSubmitAllergyItemMasterListForm(formValues: AllergyItemMasterListFormValues) {
    (async () => {
      const { data, error } = await updateAllergyItemMaster(formValues.data);
      if (data) {
        toast.success(data?.message);
        dispatch(allergyItemMasterActions.fetchAllergyItemMasterList());
      } else {
        if (error && error.error) {
          toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        }
      }
    })();
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('AllergyItemMaster.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleSubmit}>{t('AllergyItemMaster.update')}</Button>
              <Button onClick={handleOpenForm}>{t('AllergyItemMaster.register')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* list */}
      <AllergyItemMasterList
        formRef={formRef}
        data={allergyItemMasterList}
        onSubmitForm={handleSubmitAllergyItemMasterListForm}
      />

      {/* Form Modal */}
      {showForm &&
        createPortal(
          <AllergyItemMasterForm
            nextDisplayOrder={nextDisplayOrder.current}
            onClose={handleCloseForm}
            onReflect={handleAllergyItemMasterFormReflect}
          />,
          document.body
        )}
    </div>
  );
}

export default AllergyItemMasterFeature;
