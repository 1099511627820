export const authFeatureTranslationJP = {
  Login: {
    title: 'アレルギー栄養システム',
    company_name: '会社名',
    employee_code: '社員コード',
    password: 'パスワード',
    login_with_previous_month_organization: '前月の組織でログイン',
    selection: '選択',
    sign_in: 'ログイン',
    concurrent_post_proxy_authority_exists: '兼務・代理権限が存在します。',
    please_select_a_job: '職務を選択してください。',
    anrakutei_AAA_block_block_length: '安楽亭 AAAブロック ブロック長',
    manager_of_anrakutei: '安楽亭 浦安店 店長',
    please_select_a_company: '会社を選択して下さい',
  },
  Error: {
    password: 'パスワードを入力してください',
    company: '会社名を入力してください',
    employee_id: '従業員コードを入力してください',
  },
};
