const ITEM_TABLE_HEADER: { title: string; width: number | string }[] = [
  {
    title: '選択',
    width: 15,
  },
  {
    title: 'コード',
    width: 20,
  },
  {
    title: 'カテゴリ / 分類',
    width: 20,
  },
  {
    title: '名称',
    width: 50,
  },
  {
    title: '適用開始日',
    width: 70,
  },
  {
    title: '適用終了日',
    width: 60,
  },
  {
    title: '構成量',
    width: 60,
  },
  {
    title: '構成単位',
    width: 40,
  },
  {
    title: '業態',
    width: 40,
  },
];

export { ITEM_TABLE_HEADER };
