import { PDF_COLOR } from 'features/PDF/constants/style';

const ALLERGENS_LEFT_MARGIN = 32;
const INFO_BACKGROUD_COLOR = PDF_COLOR['background']['yellow'];

export const YellowFrame = () => {
  return (
    <div style={{ marginBottom: 2, width: '80%' }}>
      <div style={{ backgroundColor: INFO_BACKGROUD_COLOR, padding: 1 }}>
        <div
          style={{
            fontWeight: 'bold',
            marginLeft: ALLERGENS_LEFT_MARGIN,
            marginBottom: 3,
          }}
        >
          〈小麦アレルゲン由来原料〉
        </div>
        <div
          style={{
            fontWeight: 'bold',
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            color: PDF_COLOR['text']['red'],
            marginBottom: 3,
          }}
        >
          小麦⇒小麦製品を由来原料とするもの(小麦たんぱく加水分解物を含む）
        </div>
        <div
          style={{
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            marginBottom: 3,
          }}
        >
          調味料⇒醤油、味噌などを由来原料とするもの
        </div>
      </div>
      <div style={{ backgroundColor: INFO_BACKGROUD_COLOR, padding: 1 }}>
        <div
          style={{
            fontWeight: 'bold',
            marginLeft: ALLERGENS_LEFT_MARGIN,
            marginBottom: 3,
          }}
        >
          〈小麦アレルゲン由来原料〉
        </div>
        <div
          style={{
            fontWeight: 'bold',
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            color: PDF_COLOR['text']['red'],
            marginBottom: 3,
          }}
        >
          小麦⇒小麦製品を由来原料とするもの(小麦たんぱく加水分解物を含む）
        </div>
        <div
          style={{
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            marginBottom: 2,
          }}
        >
          調味料⇒醤油、味噌などを由来原料とするもの
        </div>
      </div>
    </div>
  );
};
