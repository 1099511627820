import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface AppState {
  countCallAPI: number;
}

const initialState: AppState = {
  countCallAPI: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showAppLoading(state) {
      state.countCallAPI++;
    },
    hideAppLoading(state) {
      state.countCallAPI--;
    },
  },
});

// Actions
export const appActions = appSlice.actions;

// Selectors
export const selectAppLoading = (state: RootState) => state.app.countCallAPI > 0;

// Reducer
export const appReducer = appSlice.reducer;
