import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotFound } from 'components/common/NotFound';
import CalorieAllergySettingList from './screens/CalorieAllergyList';
import CalorieAllergySettingEdit from './screens/CalorieAllergyEdit';

function CalorieAllergyManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <CalorieAllergySettingList />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <CalorieAllergySettingEdit />
      </Route>
      <Route exact path={`${path}/copy/:id`}>
        <CalorieAllergySettingEdit />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default CalorieAllergyManagement;
