import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NutritionMaster } from 'models/masterdata';
import { RefObject, memo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  nutritionUnitMasterActions,
  selectNutritionUnitMasterData,
} from 'store/NutritionUnitMaster/nutritionUnitMasterSlice';
import * as yup from 'yup';
import { NutritionMasterListFormValues } from '..';

const COLUMN_WIDTH = [50, 220, 140, 130];

export interface NutritionMasterListProps {
  formRef: RefObject<HTMLFormElement>;
  data: NutritionMaster[];
  onSubmitForm?: (formValues: NutritionMasterListFormValues) => void;
}

function NutritionMasterList({ formRef, data, onSubmitForm }: NutritionMasterListProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // selectors
  const nutritionUnitMasterList = useAppSelector(selectNutritionUnitMasterData);

  // side effects
  useEffect(() => {
    dispatch(nutritionUnitMasterActions.fetchNutritionUnitMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // schema validation
  const schema = yup.object({
    data: yup
      .array(
        yup.object({
          nutrition_cd: yup.number().required(),
          nutrition_name: yup.string().required(t('NutritionMaster.warn_field_required')),
          nutrition_unit_master: yup
            .number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string' && originalValue.trim() === '') {
                return undefined;
              }
              return value;
            })
            .required(t('NutritionMaster.warn_field_required')),
          display_order: yup
            .number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string' && originalValue.trim() === '') {
                return undefined;
              }
              return value;
            })
            .min(1, t('NutritionMaster.warn_number_required'))
            .integer(t('NutritionMaster.warn_number_required'))
            .required(t('NutritionMaster.warn_number_required')),
          deleted: yup.boolean().required(),
        })
      )
      .required(),
  });

  // form setup
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NutritionMasterListFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { data: data },
    mode: 'onSubmit',
  });
  const { fields } = useFieldArray({
    name: 'data',
    control,
    // keyName: 'key',
  });

  // side effects
  useEffect(() => {
    reset({
      data: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //events
  function onSubmit(formValues: NutritionMasterListFormValues) {
    onSubmitForm?.(formValues);
  }

  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1">
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="div-table fixsize-column">
          <div className="div-thead div-tr">
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
              {t('NutritionMaster.delete')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
              {t('NutritionMaster.nutrition_name')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
              {t('NutritionMaster.nutrition_unit_master')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
              {t('NutritionMaster.display_order')}
            </div>
          </div>

          {fields.map((field, index) => {
            return (
              <div className="div-tr" key={field.nutrition_cd}>
                <div
                  className="div-td d-flex align-items-center justify-content-center"
                  style={{ width: `${COLUMN_WIDTH[0]}px` }}
                >
                  <Form.Check
                    {...register(`data.${index}.deleted`)}
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                  />
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.nutrition_name`)}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors?.data?.[index]?.nutrition_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.nutrition_name?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                  <Form.Select
                    {...register(`data.${index}.nutrition_unit_master`)}
                    isInvalid={!!errors?.data?.[index]?.nutrition_unit_master}
                  >
                    {nutritionUnitMasterList.map((nutritionUnit) => (
                      <option key={nutritionUnit.nutrition_unit_cd} value={nutritionUnit.nutrition_unit_cd}>
                        {nutritionUnit.nutrition_unit_name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.nutrition_unit_master?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.display_order`)}
                    type="number"
                    placeholder=""
                    min={1}
                    className="text-end"
                    isInvalid={!!errors?.data?.[index]?.display_order}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.display_order?.message}
                  </Form.Control.Feedback>
                </div>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
}

export default memo(NutritionMasterList);
