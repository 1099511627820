import { AxiosError } from 'axios';
import { AllergyMasterAddPayload, AllergyMasterUpdatePayload, ApiError } from 'models';
import allergyMasterService from '../allergyMasterService';

function useAllergyMaster() {
  async function addAllergyMaster(payload: AllergyMasterAddPayload) {
    try {
      const response = await allergyMasterService.add(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  async function updateAllergyMaster(payload: AllergyMasterUpdatePayload[]) {
    try {
      const response = await allergyMasterService.update(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  return { addAllergyMaster, updateAllergyMaster };
}

export default useAllergyMaster;
