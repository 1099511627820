import { GROUP_HEADER } from 'features/PDF/constants/group-header';
import { ColTableStyleByTypeInterFace, PDF_BREAK_TITLES, PDF_COL_STYLE_TABLE_BY_TYPE } from '../../constants/style';
import { GROUP_HEADER_TYPE } from '../../constants/type';
import { addCharactersAtPositions } from '../common/text';
import { groupBy } from 'utils/group-by';

export interface GroupHeaderValueTypeInterface {
  title: string;
  group: string;
  style: {
    backgroundColor: string;
    width?: number;
  };
  subHeader?: {
    title: string;
    style: ColTableStyleByTypeInterFace[keyof ColTableStyleByTypeInterFace];
  }[];
}

export interface HeaderValueTypeInterface {
  title: string;
  group: string;
  style: ColTableStyleByTypeInterFace[keyof ColTableStyleByTypeInterFace];
}

const HEADER_API: HeaderValueTypeInterface[] = [
  {
    title: 'メニュー名',
    group: GROUP_HEADER_TYPE['name'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['text'],
  },
  {
    title: 'エネルギー',
    group: GROUP_HEADER_TYPE['calory'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
  },
  {
    title: 'たんぱく質',
    group: GROUP_HEADER_TYPE['calory'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
  },
  {
    title: '脂質',
    group: GROUP_HEADER_TYPE['calory'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
  },
  {
    title: '炭水化物',
    group: GROUP_HEADER_TYPE['calory'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
  },
  {
    title: '食物繊維量',
    group: GROUP_HEADER_TYPE['calory'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
  },
  {
    title: '食物繊維量',
    group: GROUP_HEADER_TYPE['calory'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
  },
  {
    title: '卵',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '小麦',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '※小麦由来原料',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark'],
  },
  {
    title: '乳',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'えび',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'かに',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'そば',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '落花生',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'くるみ',
    group: GROUP_HEADER_TYPE['materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '大豆',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '鶏肉',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'あわび',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '鶏肉',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'あわび',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'イクラ',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'イクラ',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '山芋',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'オレンジ',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'キウイ',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'キウイ',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'バナナ',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'ゼラチン',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
  {
    title: '※ごま由来原料',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark'],
  },
  {
    title: 'りんご',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: PDF_COL_STYLE_TABLE_BY_TYPE['icon_mark'],
  },
];

const otherColWidthCal = (cols: any, totalWidth: any = 100): number => {
  const colNumber = cols.length;
  let totalSepecialColWidth = 0;
  let totalNumberOfSpecialCol = 0;

  cols.forEach((col: any) => {
    if (col.style.width) {
      totalNumberOfSpecialCol += 1;
      totalSepecialColWidth += col.style.width;
    }
  });

  const otherWidth = (totalWidth - totalSepecialColWidth) / (colNumber - totalNumberOfSpecialCol);
  return otherWidth;
};

const mappingGroupHeaderStyleToHeader = (
  headers: HeaderValueTypeInterface[],
  headerGroups = GROUP_HEADER,
  headerWidth: any = null
) => {
  return headers.map((header, index) => {
    const groupedHeaderGroup = groupBy(headerGroups, ({ group }: any) => group) as any;
    const otherWidth = otherColWidthCal(headers, headerWidth ?? remainWidthAfterSelection());

    return {
      ...header,
      style: {
        ...header.style,
        backgroundColor: groupedHeaderGroup[header.group][0]['style']['backgroundColor'],
        width: header.style.width ?? otherWidth,
      },
    };
  });
};

const breakCustomWord = (headers: HeaderValueTypeInterface[], customWords = PDF_BREAK_TITLES) => {
  return headers.map((header, index) => {
    const foundHeader = customWords.find((word) => word.value === header.title);
    if (foundHeader) {
      let newTitle = addCharactersAtPositions(foundHeader.value, foundHeader.breakPosition, '\n');
      return {
        ...header,
        title: newTitle,
      };
    }
    return header;
  });
};

const genWidthGroupHeader = (
  headers: HeaderValueTypeInterface[],
  headerGroups = GROUP_HEADER,
  headerWidth: any = null
) => {
  const mappedHeaders = mappingGroupHeaderStyleToHeader(headers, headerGroups, headerWidth);

  return headerGroups.map((headerGroup) => {
    let total = 0;

    mappedHeaders.forEach((header) => {
      if (header.group === headerGroup.group) {
        total += header.style.width;
      }
    });
    return {
      ...headerGroup,
      style: {
        ...headerGroup.style,
        width: total,
      },
    };
  });
};

const remainWidthAfterSelection = () => {
  return 100 - (PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] || 0) * 2;
};

export {
  HEADER_API,
  mappingGroupHeaderStyleToHeader,
  genWidthGroupHeader,
  otherColWidthCal,
  breakCustomWord,
  remainWidthAfterSelection,
};
