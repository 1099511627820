import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { MenuCategoryMasterFilterValues } from 'features/MasterDataManagement/MenuCategoryMaster';
import menuCategoryMasterService from 'features/MasterDataManagement/MenuCategoryMaster/menuCategoryMasterService';
import { ApiResponse, MenuCategoryMaster } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { menuCategoryMasterActions } from './menuCategoryMasterSlice';

function* fetchMenuCategoryMasterList(action: PayloadAction<MenuCategoryMasterFilterValues>) {
  try {
    const response: ApiResponse<MenuCategoryMaster[]> = yield call(menuCategoryMasterService.getAll, action.payload);
    yield put(menuCategoryMasterActions.fetchMenuCategoryMasterListSuccess(response));
  } catch (e) {
    yield put(menuCategoryMasterActions.fetchMenuCategoryMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* menuCategoryMaster() {
  yield takeLatest(menuCategoryMasterActions.fetchMenuCategoryMasterList.type, fetchMenuCategoryMasterList);
}
