import axiosClient from 'utils/axiosClient';
import { API } from './Interface';
import {
  AllergyMaster,
  AllergyItem,
  NutritionMaster,
  PurchaseCalorieSetting,
  PurchaseCalorieSettingRequest,
} from './models/PurchaseProcessedCalorieAllergySetting';
import { PaginationResponse } from 'models/common';

const api: API = {
  getById: async (id: string) => {
    return await axiosClient.get(`calories-settings-masters/edit/${id}`);
  },
  patchByIds: async (data: { sms_cds: string }) => {
    return await axiosClient.patch(`calories-settings-masters`, data);
  },
  confirmRequest: async (data: PurchaseCalorieSettingRequest) => {
    return await axiosClient.post(`calories-settings-masters/confirm-request`, data);
  },
  approveRequest: async (data: { sms_cd: string }) => {
    return await axiosClient.post(`calories-settings-masters/approve`, data);
  },
  admitRequest: async (data: { sms_cd: string }) => {
    return await axiosClient.post(`calories-settings-masters/admit`, data);
  },
  cancelRequest: async (data: { sms_cd: string }) => {
    return await axiosClient.post(`calories-settings-masters/reject`, data);
  },
  getAll: async (url: string): Promise<PaginationResponse<PurchaseCalorieSetting>> => {
    return await axiosClient.get(url);
  },
  getAllNutritions: async (): Promise<NutritionMaster[]> => {
    return await axiosClient.get('/nutritions');
  },
  getAllItemUnitAllergies: async (): Promise<AllergyItem[]> => {
    return await axiosClient.get('/allergy-units');
  },
  getAllItemMasterAllergies: async (): Promise<AllergyMaster[]> => {
    return await axiosClient.get('/allergies');
  },
};

export default api;
