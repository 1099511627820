import { yupResolver } from '@hookform/resolvers/yup';
import { AllergyItemMaster } from 'models/masterdata';
import { RefObject, memo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CHART_COL_WIDTH_SOURCE, PDF_FORMAT_SOURCE } from 'utils';
import * as yup from 'yup';
import { AllergyItemMasterListFormValues } from '..';

const COLUMN_WIDTH = [50, 220, 320, 130, 130, 100, 120];

export interface AllergyItemMasterListProps {
  formRef: RefObject<HTMLFormElement>;
  data: AllergyItemMaster[];
  onSubmitForm?: (formValues: AllergyItemMasterListFormValues) => void;
}

function AllergyItemMasterList({ formRef, data, onSubmitForm }: AllergyItemMasterListProps) {
  const { t } = useTranslation();

  // schema validation
  const schema = yup.object({
    data: yup
      .array(
        yup.object({
          allergy_item_cd: yup.number().required(),
          allergy_item_name: yup.string().required(t('AllergyItemMaster.warn_field_required')),
          description: yup.string().default(''),
          priority: yup
            .number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string' && originalValue.trim() === '') {
                return undefined;
              }
              return value;
            })
            .min(1, t('AllergyItemMaster.warn_number_required'))
            .integer(t('AllergyItemMaster.warn_number_required'))
            .required(t('AllergyItemMaster.warn_number_required')),
          display_order: yup
            .number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string' && originalValue.trim() === '') {
                return undefined;
              }
              return value;
            })
            .min(1, t('AllergyItemMaster.warn_number_required'))
            .integer(t('AllergyItemMaster.warn_number_required'))
            .required(t('AllergyItemMaster.warn_number_required')),
          pdf_format: yup.number().required(),
          chart_col_width: yup.number().required(),
          deleted: yup.boolean().required(),
        })
      )
      .required(),
  });

  // form setup
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AllergyItemMasterListFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { data: data },
    mode: 'onSubmit',
  });
  const { fields } = useFieldArray({
    name: 'data',
    control,
    // keyName: 'key',
  });

  // side effects
  useEffect(() => {
    reset({
      data: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //events
  function onSubmit(formValues: AllergyItemMasterListFormValues) {
    onSubmitForm?.(formValues);
  }

  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1">
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="div-table fixsize-column">
          <div className="div-thead div-tr">
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
              {t('AllergyItemMaster.delete')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
              {t('AllergyItemMaster.allergy_item_name')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
              {t('AllergyItemMaster.description')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
              {t('AllergyItemMaster.priority')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[4]}px` }}>
              {t('AllergyItemMaster.display_order')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[5]}px` }}>
              {t('AllergyItemMaster.pdf_format')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[6]}px` }}>
              {t('AllergyItemMaster.chart_col_width')}
            </div>
          </div>

          {fields.map((field, index) => {
            return (
              <div className="div-tr" key={field.allergy_item_cd}>
                <div
                  className="div-td d-flex align-items-center justify-content-center"
                  style={{ width: `${COLUMN_WIDTH[0]}px` }}
                >
                  <Form.Check
                    {...register(`data.${index}.deleted`)}
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                  />
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.allergy_item_name`)}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors?.data?.[index]?.allergy_item_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.allergy_item_name?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.description`)}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors?.data?.[index]?.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.description?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.priority`)}
                    type="number"
                    placeholder=""
                    min={1}
                    className="text-end"
                    isInvalid={!!errors?.data?.[index]?.priority}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.priority?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[4]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.display_order`)}
                    type="number"
                    placeholder=""
                    min={1}
                    className="text-end"
                    isInvalid={!!errors?.data?.[index]?.display_order}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.display_order?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[5]}px` }}>
                  <Form.Select {...register(`data.${index}.pdf_format`)}>
                    {PDF_FORMAT_SOURCE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[6]}px` }}>
                  <Form.Select {...register(`data.${index}.chart_col_width`)}>
                    {CHART_COL_WIDTH_SOURCE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
}

export default memo(AllergyItemMasterList);
