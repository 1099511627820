import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { CaloryPDF } from './components/CaloryPDF';
import { contents } from './components/api/api-content';

const PDFgeneration = () => {
  return (
    <div>
     <>
        <PDFDownloadLink document={<CaloryPDF values={contents} />} fileName="CaloryPDF.pdf">
          {/* {({ loading }: any) => (loading ? 'Loading document...' : 'Download now!')} */}
        </PDFDownloadLink>
      </>
      <div>
        <BlobProvider document={<CaloryPDF values={contents} />}>
          {({ loading, url }: any) =>
            loading ? (
              'Loading document...'
            ) : (
              <a className="button" href={url || ''} target="_blank" rel="noopener noreferrer">
                Open PDF preview in New Tab
              </a>
            )
          }
        </BlobProvider>
      </div>
      {/* <div style={{}}>
        <PDFViewer width="1000px" height="950px">
          <CaloryPDF />
        </PDFViewer>
      </div> */}
    </div>
  );
};

export default PDFgeneration;
