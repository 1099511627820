import * as CryptoTS from 'crypto-ts';

export default class EncryptUtils {
  static encrypt(plainText: string) {
    const encryptionKey = process.env.REACT_APP_ENCRYPT_KEY;
    if (!encryptionKey) {
      throw new Error('Encryption key is not set');
    }
    try {
      let encryptedString = CryptoTS.AES.encrypt(plainText, encryptionKey).toString();
      return encryptedString;
    } catch (error) {
      console.error('Error when encrypting', error);
      throw error;
    }
  }

  static decrypt(encryptedString: string) {
    const encryptionKey = process.env.REACT_APP_ENCRYPT_KEY;
    if (!encryptionKey) {
      throw new Error('Encryption key is not set');
    }
    try {
      let bytes = CryptoTS.AES.decrypt(encryptedString, encryptionKey);
      let plainText = bytes.toString(CryptoTS.enc.Utf8);
      return plainText;
    } catch (error) {
      console.error('Error when decrypting', error);
      throw error;
    }
  }
}
