import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuStructureSetting } from '../models';
import { CommonResponse } from 'models';

// import { MENU_BOOK_LIST } from './mockdata';

export interface MenuStructureSettingState {
  loading: boolean;
  data: MenuStructureSetting | {};
}

const initialState: MenuStructureSettingState = {
  loading: false,
  data: {},
};

const menuStructureSettingSlice = createSlice({
  name: 'menu_structure_setting',
  initialState,
  reducers: {
    fetchMenuStructureSetting(state, action: PayloadAction<{ id: string }>) {
      state.loading = true;
    },
    fetchMenuStructureSettingSuccess(state, action: PayloadAction<CommonResponse<MenuStructureSetting>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchMenuStructureSettingFailed(state) {
      state.loading = false;
    },
    clear(state) {
      state.loading = false;
      state.data = {};
    },
  },
});

// Actions
export const menuStructureSettingActions = menuStructureSettingSlice.actions;

// Selectors
export const selectMenuStructureSettingLoading = (state: any) => state.menuStructureSetting.loading;
export const selectMenuStructureSettingData = (state: any) => state.menuStructureSetting.data;

// Reducer
export const menuStructureSettingReducer = menuStructureSettingSlice.reducer;
