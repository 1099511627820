import { NotFound } from 'components/common/NotFound';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import DemoFormPage from './pages/DemoFormPage';
import DemoListPage from './pages/DemoListPage';
import DemoModalPage from './pages/DemoModalPage';
import DemoTablePage from './pages/DemoTablePage';
import DemoPrivilege from './pages/DemoPrivilege';
import DemoTraditionalListPage from './pages/DemoTraditionalListPage';
import DemoPDF from './pages/DemoPDF';

function DemoFeature() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/form`} />
      </Route>
      <Route exact path={`${path}/form`}>
        <DemoFormPage />
      </Route>
      <Route exact path={`${path}/table`}>
        <DemoTablePage />
      </Route>
      <Route exact path={`${path}/list`}>
        <DemoListPage />
      </Route>
      <Route exact path={`${path}/privilege`}>
        <DemoPrivilege />
      </Route>
      <Route exact path={`${path}/list2`}>
        <DemoTraditionalListPage />
      </Route>
      <Route exact path={`${path}/modal`}>
        <DemoModalPage />
      </Route>
      <Route exact path={`${path}/PDF`}>
        <DemoPDF />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default DemoFeature;
