export const itemReplacementTranslationJP = {
  ItemReplacementScreen: {
    head_title: '仕入品一括置換',
    business: '業態一括選択',
    bulk_replacement: '一括置換',
    back: '戻る',
    name_code_search: '名称・コード検索',
    target_menu: '■対象メニュー',
    target_part: '■対象部品',
    execution: '実行',
    warn_field_required: 'この項目は必須です',
    replace_item_modal_note:
      '一括設定する仕入品・加工品・部品を指定せずに実行すると、置換対象の適用終了日のみを更新します。',
    replaced_target: '■置換対象',
    replace_target: '■一括設定する仕入品・部品',
  },
};
