import { ApiResponse, BusinessFormatMaster } from 'models';
import axiosClient from 'utils/axiosClient';

const ROOT_API_ROUTE = '/business-format-masters';

const businessFormatMasterService = {
  getAll(): Promise<ApiResponse<BusinessFormatMaster[]>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.get(url);
  },
};

export default businessFormatMasterService;
