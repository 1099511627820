import { StyleSheet, Text, View } from '@react-pdf/renderer';

const InlineText = ({ children, style }: any) => {
  const styles = StyleSheet.create({
    style: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      ...style,
    },
  });
  return <View style={styles.style}>{children}</View>;
};

const VerticalText = ({ text, style }: any) => {
  const styles = StyleSheet.create({
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...style,
    },
  });

  return (
    <View style={styles.section}>
      {text.split('').map((char: string, index: number) => (
        <Text key={index}>{char}</Text>
      ))}
    </View>
  );
};

// components manually break word base on the space width and the letter width
// 1 JP letter the same as 1 unit
const ManualText = ({ text, spaceWidth, letterWidth = 1, paddingWidth = 2, style }: any) => {
  const styles = StyleSheet.create({
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...style,
    },
  });
  const lettersPerRow = Math.floor((spaceWidth - paddingWidth) / letterWidth);

  const verticalLines: string[] = [];
  for (let i = 0; i < text.length; i += lettersPerRow) {
    verticalLines.push(text.slice(i, i + lettersPerRow));
  }

  return (
    <View style={styles.section}>
      <Text>{verticalLines.join('\n')}</Text>
    </View>
  );
};

const ManualTextNormal = ({ text, spaceWidth, letterWidth = 1, paddingWidth = 2, style }: any) => {
  const lettersPerRow = Math.floor((spaceWidth - paddingWidth) / letterWidth);

  const verticalLines: string[] = [];
  for (let i = 0; i < text.length; i += lettersPerRow) {
    verticalLines.push(text.slice(i, i + lettersPerRow));
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', ...style }}>
      <div>{verticalLines.join('\n')}</div>
    </div>
  );
};

function addCharactersAtPositions(inputText: string, positions: number[], addText: string): string {
  let result = '';
  let posIndex = 0;

  for (let i = 0; i < inputText.length; i++) {
    if (posIndex < positions.length && i === positions[posIndex]) {
      result += addText;
      posIndex++;
    }
    result += inputText[i];
  }

  // If positions contain values greater than the length of inputText, append the addText
  while (posIndex < positions.length && positions[posIndex] >= inputText.length) {
    result += addText;
    posIndex++;
  }

  return result;
}

export { InlineText, VerticalText, ManualText, addCharactersAtPositions, ManualTextNormal };
