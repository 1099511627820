import { ApiResponse, MenuCategoryMaster, MenuCategoryMasterAddPayload, MenuCategoryMasterUpdatePayload } from 'models';
import axiosClient from 'utils/axiosClient';
import { MenuCategoryMasterFilterValues } from '.';

const ROOT_API_ROUTE = '/menu-category-masters';

const menuCategoryMasterService = {
  getAll(filter: MenuCategoryMasterFilterValues): Promise<ApiResponse<MenuCategoryMaster[]>> {
    let url = ROOT_API_ROUTE;

    const params = new URLSearchParams();
    params.append('type', filter.businessType.toString());

    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return axiosClient.get(url);
  },
  add(payload: MenuCategoryMasterAddPayload): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.post(url, payload);
  },
  update(payload: MenuCategoryMasterUpdatePayload[]): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.patch(url, payload);
  },
};

export default menuCategoryMasterService;
