import { Company } from './models/company';
import axiosClient from 'utils/axiosClient';
import { AxiosResponse } from 'axios';
import { Login, User } from './models/login';

const authService = {
  postLogin(data: Login): Promise<AxiosResponse<User>> {
    const url = '/login';
    return axiosClient.post(url, data);
  },
  postLogout(): Promise<AxiosResponse> {
    const url = '/logout';
    return axiosClient.post(url);
  },

  getCompanies(): Promise<AxiosResponse<Company[]>> {
    let url = '/companies';
    return axiosClient.get(url);
  },
  postUserSelectedJobs(data: string): Promise<AxiosResponse> {
    let url = '/level';
    return axiosClient.post(url, { level: data });
  },
};

export default authService;
