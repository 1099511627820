import { Button, Form, Table } from 'react-bootstrap';
import { CREATE_TABLE_HEADER } from '../constants/tableHeader';
import { MenuCompositionStructureSetting } from 'features/CalorieManagement/MenuStructureSetting/models';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { MenuStructureSettingForm } from '../../MenuStructureSettingEdit';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const CreateTable = ({
  compositions,
  disabled,
  onOpenAddCompositionModal,
  register,
  errors,
  onRemoveComposition,
  elementUnitMasters,
  purchaseUnitMasters,
}: {
  compositions: MenuCompositionStructureSetting[];
  disabled: boolean;
  register: UseFormRegister<MenuStructureSettingForm>;
  errors: FieldErrors<MenuStructureSettingForm>;
  onOpenAddCompositionModal: (code: string) => void;
  onRemoveComposition: (idx: number) => void;
  elementUnitMasters: any[];
  purchaseUnitMasters: any[];
}) => {
  const { t } = useTranslation();
  const CodeCell = ({ code }: { code: string }) => {
    return (
      <td style={{ color: 'blue' }} onClick={() => onOpenAddCompositionModal(code)}>
        {code}
      </td>
    );
  };

  return (
    <div className="sticky-table">
      <Table>
        <thead>
          <tr>
            {CREATE_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={idx}>
                {t(`MenuStructureSettingScreen.${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {compositions.map((composition: any, idx) => {
            const isElement = composition.supplier_item_cd ? false : true;
            const filteredPurchaseUnitMasters = purchaseUnitMasters.filter(
              (unit) =>
                unit.purchase_unit_cd === composition.purchase_item_unit_cd ||
                unit.purchase_unit_cd === composition.process_item_unit_cd ||
                unit.purchase_unit_cd === composition?.supplementary_unit_cd_1 ||
                unit.purchase_unit_cd === composition?.supplementary_unit_cd_2
            );
            const filteredElementUnitMasters = elementUnitMasters.filter(
              (unit) =>
                unit.element_unit_cd === composition.element_unit_master ||
                unit.element_unit_cd === composition?.supplementary_unit_cd_1 ||
                unit.element_unit_cd === composition?.supplementary_unit_cd_2
            );
            return (
              <tr key={idx}>
                <CodeCell code={composition.code} />
                <td>{composition.name}</td>
                <td className="text-center">
                  <div className="d-flex justify-content-between ">
                    <div>
                      <div className="d-flex justify-content-between align-middle">
                        <Form.Control
                          step={'0.1'}
                          style={{ width: 95 }}
                          isInvalid={Boolean(errors?.compositions?.[idx]?.usage_amount)}
                          {...register(`compositions.${idx}.usage_amount`)}
                          type="number"
                          min="0"
                          disabled={disabled}
                        />
                      </div>
                      <div className="text-start">
                        {errors?.compositions?.[idx]?.usage_amount && (
                          <span className="small text-danger d-inline-block mt-1">
                            {errors?.compositions?.[idx]?.usage_amount?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div style={{ marginLeft: '5px' }}>
                      <div className="d-flex justify-content-between align-middle">
                        <Form.Select
                          isInvalid={Boolean(errors?.compositions?.[idx]?.unit)}
                          style={{ width: 100 }}
                          {...register(`compositions.${idx}.unit`)}
                          disabled={disabled}
                        >
                          {(isElement ? filteredElementUnitMasters : filteredPurchaseUnitMasters).map(
                            (option: any, idx: number) => {
                              return (
                                <option key={idx} value={isElement ? option.element_unit_cd : option.purchase_unit_cd}>
                                  {option.name}
                                </option>
                              );
                            }
                          )}
                        </Form.Select>
                      </div>
                      <div className="text-start">
                        {errors?.compositions?.[idx]?.unit && (
                          <span className="small text-danger d-inline-block mt-1">
                            {errors?.compositions?.[idx]?.unit?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-end">{composition.sort_key}</td>
                <td className="text-end">{composition.display_group}</td>
                <td className="text-end">
                  {composition.effective_start_date
                    ? dayjs(composition.effective_start_date).format('YYYY/MM/DD')
                    : composition.effective_start_date}
                </td>
                <td className="text-end">
                  {composition.effective_end_date
                    ? dayjs(composition.effective_end_date).format('YYYY/MM/DD')
                    : composition.effective_end_date}
                </td>
                <td className="text-end">{composition.remarks}</td>
                <td className="text-center">
                  <Button onClick={() => onRemoveComposition(idx)} disabled={disabled}>
                    {t('MenuStructureSettingScreen.delete')}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export { CreateTable };
