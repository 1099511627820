import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { LiaTimesSolid } from 'react-icons/lia';
import { useTranslation } from 'react-i18next';

export interface SaveChartModalForm {
  remark?: string;
}

const NoteChartModal = ({ onClose, onSave, remark, onRemarkChange }: any) => {
  const { t } = useTranslation();
  const handleClickSave = async (data: any) => {
    onSave();
    onClose();
  };

  return (
    <Modal show size="lg" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div>
            <Row className="align-items-center mb-3 gap-4">
              <Form.Group as={Row} className="mb-3" controlId="combobox">
                <Form.Label column sm="3" className="text-end">
                  {t('CalorieManagementSettingScreen.remark')}
                </Form.Label>
                <Col sm="8">
                  <Form.Control onChange={onRemarkChange} value={remark} as="textarea" rows={10} />
                </Col>
              </Form.Group>
            </Row>
          </div>

          <div className="d-flex justify-content-center" style={{ marginTop: '12px' }}>
            <Button onClick={handleClickSave}>{t('CalorieManagementSettingScreen.save')}</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NoteChartModal;
