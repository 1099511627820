import { NavLink } from 'react-router-dom';

export function NotFound() {
  return (
    <div className="page-content d-flex flex-column align-items-center justify-content-center">
      <h1 style={{ fontSize: '8rem' }}>404</h1>
      <h2 className="mb-5">Page Not Found</h2>
      <NavLink to="/" className="btn btn-primary">
        Return Home Page
      </NavLink>
    </div>
  );
}
