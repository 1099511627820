import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AllergyItemMaster, ApiResponse } from 'models';

export interface AllergyItemMasterState {
  loading: boolean;
  data: AllergyItemMaster[];
}

const initialState: AllergyItemMasterState = {
  loading: false,
  data: [],
};

const allergyItemMasterSlice = createSlice({
  name: 'AllergyItemMaster',
  initialState,
  reducers: {
    fetchAllergyItemMasterList(state) {
      state.loading = true;
    },
    fetchAllergyItemMasterListSuccess(state, action: PayloadAction<ApiResponse<AllergyItemMaster[]>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchAllergyItemMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const allergyItemMasterActions = allergyItemMasterSlice.actions;

// Selectors
export const selectAllergyItemMasterData = (state: RootState) => state.allergyItemMaster.data;

// Reducer
export const allergyItemMasterReducer = allergyItemMasterSlice.reducer;
