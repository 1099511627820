import { TOKEN_KEY } from './constants/authConstant';
import EncryptUtils from '../../utils/encript-utils';
import { Token } from './models/login';

const authStorage = {
  getTokens() {
    const storage = localStorage.getItem(TOKEN_KEY);
    let token: Token | null = null;
    try {
      if (storage !== null && storage !== undefined) {
        const decryptedStorage = EncryptUtils.decrypt(storage);
        token = JSON.parse(decryptedStorage);
      }
    } catch (error) {
      console.error('Error when parsing token from localStorage', error);
    }
    return token;
  },

  getAccessToken() {
    let token = this.getTokens();
    let access = token !== null ? token.access : token;
    return access;
  },

  getRefreshToken() {
    const token = this.getTokens();
    let refresh = token && token.refresh ? token.refresh : null;
    return refresh;
  },

  setTokens(token: Token) {
    const tokenStorage = {
      access: token.access,
      refresh: token.refresh,
      expired_at: Date.now() + token.token_expired_second * 1000,
    };
    const encryptedTokenStorage = EncryptUtils.encrypt(JSON.stringify(tokenStorage) as string);
    localStorage.setItem(TOKEN_KEY, encryptedTokenStorage);
  },

  removeTokens() {
    localStorage.removeItem(TOKEN_KEY);
  },

  isAuthenticated() {
    const tokens = this.getTokens();
    return tokens !== null && !this.isTokenExpired(tokens);
  },

  isTokenExpired(token: Token) {
    const currentTime = Date.now();
    if (token.expired_at === null || token.expired_at === undefined) {
      return true;
    }
    if (token.expired_at <= currentTime) {
      this.removeTokens();
    }
    return token.expired_at <= currentTime;
  },
};

export default authStorage;
