import { NotFound } from 'components/common/NotFound';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PurchaseProcessedCalorieAllergySetting from './PurchaseProcessedCalorieAllergySetting';
import PartsConfiguration from './PartsConfigurationSetting';
import ItemReplacement from './ItemReplacement';
import CalorieAllergyManagement from './CalorieAllergySetting';
import MenuStructureSettingManagement from './MenuStructureSetting';

function CalorieManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/purchase-processed-calorie-allergy-setting`} />
      </Route>
      <Route path={`${path}/purchase-processed-calorie-allergy-setting`}>
        <PurchaseProcessedCalorieAllergySetting />
      </Route>
      <Route path={`${path}/parts-configuration-settings`}>
        <PartsConfiguration />
      </Route>
      <Route path={`${path}/menus-structure-setting`}>
        <MenuStructureSettingManagement />
      </Route>
      <Route path={`${path}/item-replacement`}>
        <ItemReplacement />
      </Route>
      <Route path={`${path}/calorie-allergy-chart-setting`}>
        <CalorieAllergyManagement />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default CalorieManagement;
