import { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import '../style.scss';

interface GridItemProps {
  text: string;
  readonly: boolean;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NutritionInputItem = ({ text, readonly, id, onChange }: GridItemProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef<any>(null);

  const handleEditClick = () => {
    if (readonly) return;
    setIsEditMode(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Set parent background color
    const parent = inputRef.current?.parentElement?.parentElement;
    if (parent) {
      parent.style.background = '#f8cecc';
    }
    onChange(e);
  };

  return (
    <div className="grid-item" onClick={handleEditClick} style={{ cursor: 'pointer', padding: '5px' }}>
      {isEditMode && !readonly ? (
        <Form.Control
          type="number"
          id={id}
          min={0}
          step={0.1}
          defaultValue={text}
          onChange={handleChange}
          onBlur={() => setIsEditMode(false)}
          ref={inputRef}
        />
      ) : (
        <div>{text || <span style={{ color: 'gray' }}> {!readonly ? '修正する' : ''} </span>}</div>
      )}
    </div>
  );
};

export default NutritionInputItem;
