import { PDF_COL_STYLE_TABLE_BY_TYPE, PDF_COLOR } from '../../constants/style';
import { HEADER_API, otherColWidthCal, remainWidthAfterSelection } from './api-header';

type DynamicObject = { [key: string]: any };

function createObjectArray(numFields: number, numObjects: number): DynamicObject[] {
  const objectArray: DynamicObject[] = [];

  for (let i = 0; i < numObjects; i++) {
    const obj: DynamicObject = {};
    for (let j = 0; j < numFields; j++) {
      // You can set a default value here if needed
      if (j === 0 && i <= 25) {
        obj[`field${j + 1}`] = {
          value: '得々ダブル120ランチ ※下記よりもう1品チ ' + i,
          blank: 0,
        };
      } else if (j === 0 && i >= 26 && i <= 65) {
        obj[`field${j + 1}`] = {
          value: '得々ダブル120ランチ ※下記よりもう1品チ ' + i,
          blank: 1,
        };
      } else if (j === 0 && i >= 66) {
        obj[`field${j + 1}`] = {
          value: '得々ダブル120ランチ ※下記よりもう1品チ ' + i,
          blank: 2,
        };
      } else if (j >= 1 && j <= 6) {
        obj[`field${j + 1}`] = {
          value: 9.999,
        };
      } else if (j >= 7 && j <= 20) {
        obj[`field${j + 1}`] = {
          value: '▲',
        };
      } else if (j >= 21 && j <= 27) {
        obj[`field${j + 1}`] = {
          value: 'ー',
        };
      } else if (j >= 28 && j <= 39) {
        obj[`field${j + 1}`] = {
          value: '○',
        };
      }
    }
    if (i === 0) {
      objectArray.push({
        title: '《選べるお肉》',
        style: {
          backgroundColor: PDF_COLOR.background.lightOrange,
        },
        category: true,
      });
    }
    if (i === 10 || i === 40) {
      objectArray.push({
        title: 'セレクトランチ（キムチセレクト）',
        category: true,
        style: {
          backgroundColor: PDF_COLOR.background.red,
        },
      });
    }
    if (i === 20 || i === 60) {
      objectArray.push({
        title: 'セレクトランチ（ミニサラダセレクト）',
        category: true,
        style: {
          backgroundColor: PDF_COLOR.background.green,
        },
      });
    }
    objectArray.push({
      ...obj,
    });
  }

  return objectArray.map((eles, rowIndex) => {
    Object.keys(eles).forEach((ele, index) => {
      if (eles.category) return;
      if (index === 0) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'],
          },
          type: 'text',
          ...eles[ele],
        };
      } else if (index >= 1 && index <= 6) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['number']['width'],
          },
          type: 'number',
          ...eles[ele],
        };
      } else if ((index === 9 && rowIndex === 1) || (index === 35 && rowIndex === 1)) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width'],
          },
          type: 'text_mark',
          ...eles[ele],
          value: 'ー',
        };
      } else if (index === 9 && rowIndex === 2) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width'],
          },
          type: 'text_mark',
          ...eles[ele],
          value: '調味料',
        };
      } else if (index === 9 && rowIndex >= 3) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width'],
          },
          type: 'text_mark',
          ...eles[ele],
          value: '小麦',
        };
      } else if (index === 35 && rowIndex === 2) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width'],
          },
          type: 'text_mark',
          ...eles[ele],
          value: 'ごま',
        };
      } else if (index === 35 && rowIndex >= 3) {
        eles[ele] = {
          style: {
            width: PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width'],
          },
          type: 'text_mark',
          ...eles[ele],
          value: 'ごま油',
        };
      } else {
        eles[ele] = {
          style: {
            width: otherColWidthCal(HEADER_API, remainWidthAfterSelection()),
          },
          type: 'icon_mark',
          ...eles[ele],
        };
      }
    });
    return eles;
  });
}
const Colnumber = 37;
const contents = createObjectArray(Colnumber, 100).map((ele: any, index: number) => {
  return {
    // approved: {
    //   value: false,
    //   style: {
    //     width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'],
    //   },
    // },
    // confirmed: {
    //   value: false,
    //   style: {
    //     width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'],
    //   },
    // },
    ...ele,
    id: String(index),
  };
});

const contentsForEditPDF = createObjectArray(Colnumber, 20).map((obj: any, index: number) => {
  return {
    ...obj,
    isOpen: true,
    compositions: [
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: 'Group 1',
          style: {
            width: 12,
          },
        },
        type: 'group',
        groupId: '1',
        id: 'c-1-' + index,
      },
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: '新お弁当ナポリタンスパ',
          style: {
            width: 12,
          },
        },
        type: 'composition',
        groupId: '1',
        id: 'c-2-' + index,
      },
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: 'カレーライス',
          style: {
            width: 12,
          },
        },
        type: 'composition',
        groupId: '1',
        id: 'c-3-' + index,
      },
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: 'Group 2',
          style: {
            width: 12,
          },
        },
        type: 'group',
        groupId: '2',
        id: 'c-4-' + index,
      },
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: '白米（にがり無',
          style: {
            width: 12,
          },
        },
        type: 'composition',
        groupId: '2',
        id: 'c-5-' + index,
      },
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: 'Group 3',
          style: {
            width: 12,
          },
        },
        type: 'group',
        groupId: '3',
        id: 'c-6-' + index,
      },
      {
        ...obj,
        field1: {
          ...obj.field1,
          value: 'ゼリー',
          style: {
            width: 12,
          },
        },
        type: 'composition',
        groupId: '3',
        id: 'c-7-' + index,
      },
    ],
    id: String(index),
  };
});

const newMenus = contentsForEditPDF.map((i) => ({ ...i, id: i.id + 'item' }));

export { contents, contentsForEditPDF, newMenus };
