import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { InputFormDatePicker } from 'components/formfields/InputFormDatePicker';
import { useIsAllowEdit } from 'components/formfields/Privilege/components/editForm';
import { ApproveButtonResource } from 'components/formfields/Privilege/models/viewApproveButtonprivilege';
import ApproveGroupButton from 'features/ApproveGroupButton';
import menuCategoryMasterService from 'features/MasterDataManagement/MenuCategoryMaster/menuCategoryMasterService';
import SearchCompositionModal from 'features/SearchCompositionModal';
import ViewDetailProductModal from 'features/ViewDetailCompositionModal';
import { MouseEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { MenuCompositionStructureSetting, MenuStructureSetting } from '../../models';
import '../../style.scss';
import MenuStructureSettingService from '../menuStructureSettingService';
import { menuStructureSettingActions, selectMenuStructureSettingData } from '../menuStructureSettingSlice';
import { EditTable } from './components/compositionTable';
import PurchaseUnitMasterService from '../purchaseUnitMastersService';
import ElementUnitMasterService from '../elementUnitMastersService';

const NEW = '新規';
const PENDING_CONFIRMATION = '確認待ち';
const PENDING_APPROVAL = '承認待ち';
const APPROVED = '承認済み';
const REJECTED = '';

const MAP_PRIVILEDGE: any = {
  [NEW]: 'new',
  [PENDING_CONFIRMATION]: 'wait_for_confirmed',
  [PENDING_APPROVAL]: 'wait_for_approval',
  [APPROVED]: 'admitted',
  [REJECTED]: 'none',
};

const DEFAULT_SUSPENSION_DATE = new Date('2099/12/31');
export type MenuStructureSettingForm = Omit<MenuStructureSetting, 'id' | 'status' | 'confirmUserId'>;

const MenuStructureSettingEdit = () => {
  //param
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const menuStructureData = useAppSelector(selectMenuStructureSettingData) as any;

  const menuStatus = menuStructureData.status;
  const verifierCd = menuStructureData.applicant_cd;

  const isAllowedEdit = useIsAllowEdit({
    status: MAP_PRIVILEDGE[menuStatus],
  });
  const history = useHistory();
  const dispatch = useAppDispatch();

  // yup setup
  const schema = yup.object({
    menu_code: yup.string(),
    menu_name: yup.string().required(t('MenuStructureSettingScreen.warn_field_required')),
    menu_category: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(t('MenuStructureSettingScreen.warn_field_required')),
    business: yup.number().required(t('MenuStructureSettingScreen.warn_field_required')),
    suspension_date: yup.date().required(t('MenuStructureSettingScreen.warn_field_required')),
    sort_key: yup
      .number()
      .min(0, t('MenuStructureSettingScreen.negative_number'))
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    display_menu: yup.boolean().required(t('MenuStructureSettingScreen.warn_field_required')),
    compositions: yup.array(
      yup.object({
        changed: yup.boolean(),
        name: yup.string().required(),
        code: yup.string().required(),
        usage_amount: yup
          .number()
          .min(0, t('MenuStructureSettingScreen.negative_number'))
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('MenuStructureSettingScreen.warn_field_required')),
        sort_key: yup.number().nullable(),
        display_group: yup.number(),
        remarks: yup.string(),
        effective_start_date: yup.string().nullable(),
        effective_end_date: yup.string().nullable(),
        unit: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('MenuStructureSettingScreen.warn_field_required')),
      })
    ),
  });

  // react-hook-form setup
  const {
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<MenuStructureSettingForm>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });
  const { fields } = useFieldArray({
    name: 'compositions',
    control,
    keyName: 'key',
  });

  const suspensioDateValue = watch('suspension_date');

  // state
  const [filter, setFilter] = useState({
    date: new Date(),
  });
  const [applyFilter, setApplyFilter] = useState<{ date?: Date }>({});
  const [isOpenAddCompositionModal, setIsOpenAddCompositionModal] = useState<boolean>(false);
  const [isDetailCompositionModal, setIsDetailCompositionModal] = useState<boolean>(false);
  const [chosenDetailComposition, setChosenDetailComposition] = useState<string | null>(null);
  const [menuCategoryOptions, setMenuCategoryOptions] = useState<any>([]);
  const [purchaseUnitMasters, setPurchaseUnitMasters] = useState<any>([]);
  const [elementUnitMasters, setElementUnitMasters] = useState<any>([]);

  // call API
  const fetchMenuStructureSetting = async () => {
    dispatch(menuStructureSettingActions.fetchMenuStructureSetting({ id: id }));
  };

  const fetchPurchaseUnitMasters = async () => {
    try {
      const res = await PurchaseUnitMasterService.getAll();
      setPurchaseUnitMasters(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchElementUnitMasters = async () => {
    try {
      const res = await ElementUnitMasterService.getAll();
      setElementUnitMasters(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchMenuStructureSetting();
    fetchPurchaseUnitMasters();
    fetchElementUnitMasters();
  }, []);

  const selectedBusiness = watch('business');

  const fetchMenuCategoryMaster = async (selectedBusiness?: number) => {
    try {
      if (selectedBusiness === undefined) return;
      const res = await menuCategoryMasterService.getAll({ businessType: selectedBusiness });
      setMenuCategoryOptions(res.data);
      setValue('menu_category', res.data[0]?.menu_category_cd ?? undefined);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchMenuCategoryMaster(selectedBusiness);
  }, [selectedBusiness]);

  // set API data to react-hook-form
  useEffect(() => {
    if (!id) return;
    if (!menuStructureData) return;

    const mappedMenuStructureData = {
      menu_code: menuStructureData.menu_cd,
      menu_name: menuStructureData.menu_name,
      menu_category: menuStructureData.menu_category_master_id,
      business: menuStructureData.business_format_cd,
      suspension_date: new Date(menuStructureData.suspend_date || DEFAULT_SUSPENSION_DATE),
      sort_key: menuStructureData?.sort_key ?? null,
      display_menu: menuStructureData?.breakdown_display_flg ?? false,
      compositions: menuStructureData.menu_composition_masters?.map((composition: any) => {
        return {
          ...composition,
          usage_amount: Number(composition.composition_quantity).toFixed(2),
          code: composition?.composition_cd,
          name: composition?.purchase_item_name || composition?.process_item_name || composition?.element_name,
          unit: composition.composition_unit_cd,
        };
      }),
      effective_end_date: menuStructureData.effective_end_date,
      effective_start_date: menuStructureData.effective_start_date,
    };
    reset(mappedMenuStructureData);
  }, [menuStructureData, menuCategoryOptions]);

  useEffect(() => {
    return () => {
      dispatch(menuStructureSettingActions.clear());
    };
  }, []);

  // composition func
  const handleRemoveComposition = (index: number) => {
    const compositions = watch('compositions')?.filter((_, i) => i !== index);
    setValue('compositions', compositions, { shouldDirty: false });
  };

  const hanleAddComposition = (composition: any) => {
    const newComposition = {
      ...composition,
      isAdded: true,
      code: composition.purchase_item_cd || composition.process_item_cd || composition.element_cd,
      name: composition.purchase_item_name || composition.process_item_name || composition.element_name,
      supplementary_unit_cd_1:
        composition.purchase_supplementary_unit_cd_1 ||
        composition.process_supplementary_unit_cd_1 ||
        composition.element_supplementary_unit_1,
      supplementary_unit_cd_2:
        composition.purchase_supplementary_unit_cd_2 ||
        composition.process_supplementary_unit_cd_2 ||
        composition.element_supplementary_unit_2,
      usage_amount: '',
    };

    const compositions = watch('compositions');
    compositions?.push(newComposition);
    setValue('compositions', compositions, { shouldDirty: false });
  };

  // modal func
  const handleAddCompositionClick = () => {
    setIsOpenAddCompositionModal(true);
  };

  const handleCloseSearchCompositionModalClick = () => {
    setIsOpenAddCompositionModal(false);
  };

  const handleCloseDetailCompositionModalClick = () => {
    setIsDetailCompositionModal(false);
  };

  const updateMenuDataAPICall = async (payload: MenuStructureSettingForm) => {
    try {
      const mappedData = {
        ...payload,
        business_format_cd: payload.business,
        menu_category_master: payload.menu_category,
        breakdown_display_flg: payload.display_menu,
        sort_key: payload?.sort_key ?? null,
        change_flg: isDirty,
        compositions: payload?.compositions?.map((composition: any, idx: number) => {
          const isChange = composition.isAdded
            ? false
            : dirtyFields?.compositions?.[idx]?.usage_amount || dirtyFields?.compositions?.[idx]?.unit;
          return {
            ...composition,
            composition_cd: composition.code,
            composition_quantity: composition.usage_amount,
            composition_unit_cd: composition.unit,
            change_flg: isChange,
          };
        }),
      };
      const res = await MenuStructureSettingService.update(id, { ...mappedData, status: PENDING_CONFIRMATION });
      toast.success(res.message);
      history.push(`/calorie-management/menus-structure-setting`);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  const updateMeneStatusAPICall = async (payload: MenuStructureSettingForm, resource: ApproveButtonResource) => {
    let status = null;
    // next status after press approve button
    switch (resource) {
      case 'approval_request_btn':
        status = PENDING_APPROVAL;
        break;
      case 'approval_btn':
        status = APPROVED;
        break;
      case 'turn_back_btn':
        status = REJECTED;
        break;
      case 'confirmation_cancel_btn':
        status = REJECTED;
        break;
    }

    if (status === null) {
      console.error('error');
      return;
    }

    try {
      const res = await MenuStructureSettingService.updateStatus(id, { status: status, menu_name: payload.menu_name });
      toast.success(res.message);
      history.push(`/calorie-management/menus-structure-setting`);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  // approve func
  const onSubmit = async (payload: MenuStructureSettingForm, resource: ApproveButtonResource) => {
    if (resource === 'confirmation_request_btn') {
      updateMenuDataAPICall(payload);
    } else {
      updateMeneStatusAPICall(payload, resource);
    }
  };

  const handleApproveClick = (resource: ApproveButtonResource, event: MouseEvent<HTMLElement>) => {
    return handleSubmit((data) => onSubmit(data, resource))(event);
  };

  // table filter
  const handleApplyFilter = () => {
    setApplyFilter({ ...filter });
  };

  const handleRemoveFilter = () => {
    setApplyFilter({});
  };

  const compositionsMapFilter = (compositions: MenuCompositionStructureSetting[], applyFilter: { date?: Date }) => {
    if (!applyFilter?.date) return compositions;

    const filterDate = new Date(applyFilter.date.setHours(0, 0, 0, 0)).getTime();
    return compositions.map((composition, idx) => {
      if (!composition?.effective_start_date || !composition?.effective_end_date) {
        return composition;
      }

      const compositionStartDate = new Date(
        new Date(composition?.effective_start_date as any).setHours(0, 0, 0, 0)
      ).getTime();
      const compositionEndDate = new Date(
        new Date(composition?.effective_end_date as any).setHours(0, 0, 0, 0)
      ).getTime();

      if (filterDate <= compositionStartDate || filterDate >= compositionEndDate) {
        return {
          isRemoveFromFilter: true,
          ...composition,
        };
      }

      return composition;
    });
  };

  const onOpenAddCompositionModal = (code: string) => {
    setChosenDetailComposition(code);
    setIsDetailCompositionModal(true);
  };

  return (
    <Form className="page-content d-flex flex-column">
      {/* Modal */}
      {isOpenAddCompositionModal &&
        createPortal(
          <SearchCompositionModal
            onClose={handleCloseSearchCompositionModalClick}
            onClickAddComposition={hanleAddComposition}
            selectedCompositions={fields.map((composition) => composition.code)}
          />,
          document.body
        )}
      {isDetailCompositionModal &&
        createPortal(
          <ViewDetailProductModal
            onClose={handleCloseDetailCompositionModalClick}
            chosenDetailComposition={chosenDetailComposition}
          />,
          document.body
        )}

      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('MenuStructureSettingScreen.edit_head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <ApproveGroupButton
                status={MAP_PRIVILEDGE[menuStatus]}
                confirmUserId={verifierCd}
                onClick={handleApproveClick}
              />
              <Button onClick={history.goBack}>{t('MenuStructureSettingScreen.back')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* Form field */}
      <div className="py-3">
        <div style={{ maxWidth: '700px' }}>
          <Form.Group as={Row} className="mb-3" controlId="textbox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.menu')}
            </Form.Label>
            <Col sm="8">
              <Form.Control {...register('menu_code')} type="text" disabled />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="textbox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.name')}
            </Form.Label>
            <Col sm="8">
              <Form.Control
                {...register('menu_name')}
                type="text"
                isInvalid={Boolean(errors?.menu_name?.message)}
                disabled={!isAllowedEdit}
              />
              {errors?.menu_name && (
                <span className="small text-danger d-inline-block mt-1">{errors?.menu_name?.message}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="combobox">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.menu_category')}
            </Form.Label>
            <Col sm="8">
              <Form.Select
                isInvalid={Boolean(errors?.menu_category)}
                style={{ width: '200px' }}
                {...register(`menu_category`)}
                disabled={!isAllowedEdit}
              >
                {menuCategoryOptions.map((option: any, idx: number) => {
                  return (
                    <option key={idx} value={option.menu_category_cd} hidden={option?.hidden}>
                      {option.menu_category_name}
                    </option>
                  );
                })}
              </Form.Select>
              {errors?.menu_category && (
                <span className="small text-danger d-inline-block mt-1">{errors?.menu_category?.message}</span>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.suspension_date')}
            </Form.Label>
            <Col sm="8">
              <div style={{ maxWidth: '120px' }}>
                <ReactDatePicker
                  locale="ja"
                  selected={suspensioDateValue || DEFAULT_SUSPENSION_DATE}
                  onChange={(date: Date) => {
                    setValue('suspension_date', date);
                  }}
                  customInput={<InputFormDatePicker />}
                  dateFormat="yyyy/MM/dd"
                  name="suspension_date"
                  disabled={!isAllowedEdit}
                />
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="3" className="text-start">
              {t('MenuStructureSettingScreen.sort_key')}
            </Form.Label>
            <Col sm="8">
              <Form.Control {...register('sort_key')} type="number" disabled={!isAllowedEdit} />
              {errors?.sort_key && (
                <span className="small text-danger d-inline-block mt-1">{errors?.sort_key?.message}</span>
              )}
            </Col>
          </Form.Group>
        </div>
      </div>

      {/* Table filter */}
      <div className="d-flex justify-content-between py-2">
        <Form.Label column sm="4" className="text-start">
          {t('MenuStructureSettingScreen.composition')}
        </Form.Label>
        <div style={{ display: 'flex' }}>
          <Stack direction="horizontal" gap={4}>
            <div style={{ display: 'flex' }}>
              <Stack direction="horizontal" gap={4}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4" className="text-start">
                    {t('MenuStructureSettingScreen.applicable_date')}
                  </Form.Label>
                  <Col sm="8">
                    <div style={{ maxWidth: '120px' }}>
                      <ReactDatePicker
                        locale="ja"
                        selected={filter.date}
                        onChange={(date: Date) => {
                          setFilter({
                            ...filter,
                            date: date,
                          });
                        }}
                        customInput={<InputFormDatePicker />}
                        dateFormat="yyyy/MM/dd"
                        name="data_date"
                      />
                    </div>
                  </Col>
                </Form.Group>

                <Button onClick={handleApplyFilter}>{t('MenuStructureSettingScreen.apply')}</Button>
                <Button onClick={handleRemoveFilter}>{t('MenuStructureSettingScreen.clear')}</Button>
              </Stack>
            </div>

            <Button onClick={handleAddCompositionClick} disabled={!isAllowedEdit}>
              {t('MenuStructureSettingScreen.additional')}
            </Button>
          </Stack>
        </div>
      </div>

      <EditTable
        purchaseUnitMasters={purchaseUnitMasters}
        elementUnitMasters={elementUnitMasters}
        compositions={compositionsMapFilter(fields, applyFilter)}
        disabled={!isAllowedEdit}
        register={register}
        errors={errors}
        dirtyFields={dirtyFields}
        onOpenAddCompositionModal={onOpenAddCompositionModal}
        onRemoveComposition={handleRemoveComposition}
      />

      <Form.Check
        className="anra-checkbox"
        style={{ marginTop: 5 }}
        type="checkbox"
        {...register('display_menu')}
        inline
        label={t('MenuStructureSettingScreen.display_menu')}
        disabled={!isAllowedEdit}
      />
    </Form>
  );
};

export default MenuStructureSettingEdit;
