import { forwardRef } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

export const CustomInputDatePicker = forwardRef((props: any, ref: any) => {
  // You can style this further
  return (
    <>
      <div className="position-relative">
        <FaCalendarAlt
          style={{ position: 'absolute', top: '0.625rem', left: '0.625rem', cursor: 'pointer' }}
          onClick={props.onClick}
        />
        <input
          className="form-control"
          onClick={props.onClick}
          style={{ paddingLeft: '32px' }}
          value={props.value}
          readOnly={true}
          type="text"
        />
      </div>
    </>
  );
});
