import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ApiResponse, NutritionMaster } from 'models';

export interface NutritionMasterState {
  loading: boolean;
  data: NutritionMaster[];
}

const initialState: NutritionMasterState = {
  loading: false,
  data: [],
};

const nutritionMasterSlice = createSlice({
  name: 'NutritionMaster',
  initialState,
  reducers: {
    fetchNutritionMasterList(state) {
      state.loading = true;
    },
    fetchNutritionMasterListSuccess(state, action: PayloadAction<ApiResponse<NutritionMaster[]>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchNutritionMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const nutritionMasterActions = nutritionMasterSlice.actions;

// Selectors
export const selectNutritionMasterData = (state: RootState) => state.nutritionMaster.data;

// Reducer
export const nutritionMasterReducer = nutritionMasterSlice.reducer;
