import { useAppDispatch, useAppSelector } from 'app/hooks';
import clsx from 'clsx';
import { authActions, selectUserInfo, selectUserSelectedJob } from 'features/Auth/authSlice';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './style.scss';

export interface StatusHeaderProps {
  actionName?: 'back' | 'logout';
  onAction?: () => void;
}

function StatusHeader({ actionName = 'logout', onAction }: StatusHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const menuData = [
    {
      groupName: t('Status_Header.calorie_management'),
      path: '/calorie-management',
      features: [
        {
          name: t('Status_Header.feature_A003'),
          path: '/calorie-management/purchase-processed-calorie-allergy-setting',
        },
        {
          name: t('Status_Header.feature_A008'),
          path: '/calorie-management/parts-configuration-settings',
        },
        {
          name: t('Status_Header.feature_A010'),
          path: '/calorie-management/menus-structure-setting',
        },
        {
          name: t('Status_Header.feature_A011'),
          path: '/calorie-management/item-replacement',
        },
        {
          name: t('Status_Header.feature_A012'),
          path: '/calorie-management/calorie-allergy-chart-setting',
        },
      ],
    },
    {
      groupName: t('Status_Header.master_management'),
      path: '/master-management',
      features: [
        {
          name: t('Status_Header.feature_A006'),
          path: '/master-management/nutrition-master',
        },
        {
          name: t('Status_Header.feature_A007'),
          path: '/master-management/nutrition-unit-master',
        },
        {
          name: t('Status_Header.feature_A004'),
          path: '/master-management/allergy-master',
        },
        {
          name: t('Status_Header.feature_A005'),
          path: '/master-management/allergy-item-master',
        },
        {
          name: t('Status_Header.feature_A009'),
          path: '/master-management/menu-category-master',
        },
        {
          name: t('Status_Header.feature_A013'),
          path: '/master-management/element-classification-master',
        },
      ],
    },
    // {
    //   groupName: 'Demo',
    //   path: '/demo',
    //   features: [
    //     {
    //       name: 'Demo Form',
    //       path: '/demo/form',
    //     },
    //     {
    //       name: 'Demo Table',
    //       path: '/demo/table',
    //     },
    //     {
    //       name: 'Demo List Page (table using div)',
    //       path: '/demo/list',
    //     },
    //     {
    //       name: 'Demo Privilege Page',
    //       path: '/demo/privilege',
    //     },
    //     {
    //       name: 'Demo List Page (traditional table)',
    //       path: '/demo/list2',
    //     },
    //     {
    //       name: 'Demo Modal',
    //       path: '/demo/modal',
    //     },
    //     {
    //       name: 'Demo PDF',
    //       path: '/demo/pdf',
    //     },
    //   ],
    // },
  ];

  // state
  const userInfo = useAppSelector(selectUserInfo);
  const userJobInfo = useAppSelector(selectUserSelectedJob);

  const handleActionClick = () => {
    if (actionName === 'logout') {
      dispatch(authActions.actionLogout());
    } else {
      onAction && onAction();
    }
  };

  return (
    <div className="status-header">
      <Container fluid>
        <div className="user-info d-flex align-items-center justify-content-between">
          <div className="status-header__info d-inline-flex">
            <a className="me-4 text-white text-decoration-none" href="/">
              <h3>{t('App.app_name')}</h3>
            </a>
          </div>
          <div className="status-header__actions">
            <div className="d-inline-flex align-items-center">
              {/* <div className="me-4">
                {t('Status_Header.login_time')}:&nbsp;
                <span className="status-header__value">{employeeInfo?.last_time_login}</span>
              </div> */}
              <div className="me-4">
                {t('Status_Header.company_name')}:&nbsp;
                <span className="status-header__value">{userJobInfo?.company_name}</span>
              </div>
              <div className="me-4">
                {t('Status_Header.department_name')}:&nbsp;
                <span className="status-header__value">{userJobInfo?.department_name}</span>
              </div>
              <div className="me-4">
                {t('Status_Header.job_name')}:&nbsp;
                <span className="status-header__value">{userJobInfo?.job_name ? userJobInfo?.job_name : '-'}</span>
              </div>
              <div className="me-4">
                {t('Status_Header.employee_name')}:&nbsp;
                <span className="status-header__value">{userInfo?.employee_name}</span>
              </div>
              <div className="me-4">
                {t('Status_Header.employee_code')}:&nbsp;
                <span className="status-header__value">{userInfo?.employee_cd}</span>
              </div>

              <Button variant="light" onClick={handleActionClick}>
                {actionName === 'back' ? t('Status_Header.return') : t('Status_Header.logout')}
              </Button>
            </div>
          </div>
        </div>
        <div className="main-menu">
          <ul>
            {menuData.map((group) => {
              const features = group.features.map((feature) => {
                return (
                  <li key={feature.path}>
                    <Link to={feature.path} className={clsx({ active: location.pathname === feature.path })}>
                      {feature.name}
                    </Link>
                  </li>
                );
              });

              return (
                <li key={group.path}>
                  <span className={clsx({ active: location.pathname.startsWith(group.path) })}>{group.groupName}</span>
                  <ul>{features}</ul>
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </div>
  );
}

export default StatusHeader;
