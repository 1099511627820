import React from 'react';

import AsyncSelect from 'react-select/async';
import { InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { ActionMeta } from 'react-select/dist/declarations/src';

const Autocomplete = ({
  placeholder,
  loadOptions,
  onChange,
}: {
  placeholder?: string;
  loadOptions?: (value: string, cb: (options: any[]) => void) => void;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
}) => {
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <AsyncSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minWidth: '330px',
            minHeight: '29px',
            color: '#212529',
            borderColor: state.isFocused ? '#ffc080' : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(255, 128, 0, 0.25)' : 'none',
            '&:hover': {
              borderColor: state.isFocused ? '#ffc080' : '#ced4da',
            },
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '0 0 0 5px',
          }),
          singleValue: (base) => ({
            ...base,
            padding: 0,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            color: '#212529',
            '&:hover': {
              backgroundColor: '#ffe6cc',
            },
          }),
        }}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={onChange}
      />
      <InputGroup.Text
        style={{ height: '29px', borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderLeft: 'none' }}
      >
        <FaSearch />
      </InputGroup.Text>
    </div>
  );
};

export default Autocomplete;
