import { Form, Table } from 'react-bootstrap';

const COLUMN_WIDTH = [120, 400, 100, 100, 100, 100, 100, 100, 100, 400, 400];
const totalWidth = COLUMN_WIDTH.reduce((sum, size) => (sum += size), 0);

function DemoTablePage() {
  return (
    <div className="page-content d-flex flex-column">
      <h1>Demo Table Style</h1>
      <hr />

      {/* Simple Table */}
      <div className="mb-5">
        <div className="mb-3">
          <h4>Traditional Table</h4>
        </div>
        <div className="sticky-table">
          <Table>
            <thead>
              <tr>
                {Array.from(Array(5).keys()).map((columnIndex) => (
                  <th key={columnIndex}>Column {columnIndex + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-yellow">Row 1, Col 1</td>
                <td className="bg-yellow">Row 1, Col 2</td>
                <td className="bg-yellow">Row 1, Col 3</td>
                <td className="bg-yellow">Row 1, Col 4</td>
                <td className="bg-yellow">Row 1, Col 5</td>
              </tr>
              <tr>
                <td>Row 2, Col 1</td>
                <td className="bg-blue">Row 2, Col 2</td>
                <td className="bg-blue">Row 2, Col 3</td>
                <td className="bg-blue">Row 2, Col 4</td>
                <td>Row 3, Col 5</td>
              </tr>
              <tr>
                <td>Row 3, Col 1</td>
                <td>Row 3, Col 2</td>
                <td>Row 3, Col 3</td>
                <td className="bg-red">Row 3, Col 4</td>
                <td className="bg-red">Row 3, Col 5</td>
              </tr>
              <tr>
                <td className="bg-gray">Row 4, Col 1</td>
                <td className="bg-gray">Row 4, Col 2</td>
                <td className="bg-gray">Row 4, Col 3</td>
                <td className="bg-gray">Row 3, Col 4</td>
                <td className="bg-gray">Row 3, Col 5</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className="mb-5">
        <div className="mb-3">
          <h4>Traditional Table with horizontal scroll</h4>
        </div>

        <div className="sticky-table">
          <div style={{ width: `${totalWidth}px` }}>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: `${COLUMN_WIDTH[0]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[1]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[2]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[3]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[4]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[5]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[6]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[7]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[8]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[9]}px` }}>Column 1</th>
                  <th style={{ width: `${COLUMN_WIDTH[10]}px` }}>Column 1</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Check className="anra-checkbox d-flex justify-content-center" label="" type="checkbox" />
                  </td>
                  <td>Row 1, Col 2</td>
                  <td>
                    <Form.Select>
                      <option>option 1</option>
                      <option>option 2</option>
                      <option>option 3</option>
                      <option>option 4</option>
                      <option>option 5</option>
                    </Form.Select>
                  </td>
                  <td>Row 1, Col 4</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                </tr>
                <tr>
                  <td>
                    <Form.Check className="anra-checkbox d-flex justify-content-center" label="" type="checkbox" />
                  </td>
                  <td>Row 1, Col 2</td>
                  <td>
                    <Form.Select>
                      <option>option 1</option>
                      <option>option 2</option>
                      <option>option 3</option>
                      <option>option 4</option>
                      <option>option 5</option>
                    </Form.Select>
                  </td>
                  <td>Row 1, Col 4</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                  <td>Row 1, Col 5</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* Table using div */}
      <div className="mb-5">
        <div className="mb-3">
          <h4>Table using div</h4>
        </div>
        <div>
          <div className="div-table">
            <div className="div-thead div-tr">
              {Array.from(Array(5).keys()).map((columnIndex) => (
                <div className="div-th" key={columnIndex}>
                  Column {columnIndex + 1}
                </div>
              ))}
            </div>
            <div className="div-tr">
              <div className="div-td bg-yellow">Row 1, Col 1</div>
              <div className="div-td bg-yellow">Row 1, Col 2</div>
              <div className="div-td bg-yellow">Row 1, Col 3</div>
              <div className="div-td bg-yellow">Row 1, Col 4</div>
              <div className="div-td bg-yellow">Row 1, Col 5</div>
            </div>
            <div className="div-tr">
              <div className="div-td">Row 2, Col 1</div>
              <div className="div-td bg-blue">Row 2, Col 2</div>
              <div className="div-td bg-blue">Row 2, Col 3</div>
              <div className="div-td bg-blue">Row 2, Col 4</div>
              <div className="div-td">Row 2, Col 5</div>
            </div>
            <div className="div-tr">
              <div className="div-td">Row 3, Col 1</div>
              <div className="div-td">Row 3, Col 2</div>
              <div className="div-td">Row 3, Col 3</div>
              <div className="div-td bg-red">Row 3, Col 4</div>
              <div className="div-td bg-red">Row 3, Col 5</div>
            </div>
            <div className="div-tr">
              <div className="div-td bg-gray">Row 4, Col 1</div>
              <div className="div-td bg-gray">Row 4, Col 2</div>
              <div className="div-td bg-gray">Row 4, Col 3</div>
              <div className="div-td bg-gray">Row 4, Col 4</div>
              <div className="div-td bg-gray">Row 4, Col 5</div>
            </div>
          </div>
        </div>
      </div>

      {/* Table using div width horizontal scroll */}
      <div className="mb-5">
        <div className="mb-3">
          <h4>Table using div with horizontal scroll</h4>
        </div>
        <div className="sticky-table">
          <div className="div-table fixsize-column">
            <div className="div-thead div-tr">
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
                部品コード
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                名称
              </div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[2]}px` }}
                dangerouslySetInnerHTML={{ __html: 'カロリー<br/>(kcal)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[3]}px` }}
                dangerouslySetInnerHTML={{ __html: 'タンパク質<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[4]}px` }}
                dangerouslySetInnerHTML={{ __html: '脂質<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[5]}px` }}
                dangerouslySetInnerHTML={{ __html: '炭水化物<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[6]}px` }}
                dangerouslySetInnerHTML={{ __html: '食物繊維<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[7]}px` }}
                dangerouslySetInnerHTML={{ __html: 'ナトリウム<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[8]}px` }}
                dangerouslySetInnerHTML={{ __html: '食塩相当量<br/>(g)' }}
              ></div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[9]}px` }}>
                特定原材料
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[10]}px` }}>
                特定原材料に準ずる品目
              </div>
            </div>

            <div className="div-tr">
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
                B000001231
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                うちダレ
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                259
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                10.5
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[4]}px` }}>
                1.2
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[5]}px` }}>
                0.2
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[6]}px` }}>
                1.0
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[7]}px` }}>
                0.5
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[8]}px` }}>
                12.2
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[9]}px` }}>
                乳（●）、えび（▲）、かに（▲）
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[10]}px` }}>
                いか（▲）、ごま由来原料（ごま油由来）
              </div>
            </div>
            <div className="div-tr">
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
                B000001231
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                うちダレ
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                259
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                10.5
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[4]}px` }}>
                1.2
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[5]}px` }}>
                0.2
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[6]}px` }}>
                1.0
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[7]}px` }}>
                0.5
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[8]}px` }}>
                12.2
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[9]}px` }}>
                乳（●）、えび（▲）、かに（▲）
              </div>
              <div className="div-td" style={{ width: `${COLUMN_WIDTH[10]}px` }}>
                いか（▲）、ごま由来原料（ごま油由来）
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemoTablePage;
