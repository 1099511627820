import { Button, Col, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';
import { useEffect, useState } from 'react';
import CompositionSearchService from './compositionSearchService';
import { useTranslation } from 'react-i18next';
import { ElementSearch, ProcessItemSearch, PurchaseItemSearch } from './models/compositionSearchModel';

const COLUMN_WIDTH = [20, 40, 40];

interface SearchCompositionModalProps {
  onClose: () => void;
  onClickAddComposition: (composition: PurchaseItemSearch | ProcessItemSearch | ElementSearch) => void;
  selectedCompositions?: string[]; // code
}

const SearchCompositionModal = ({
  onClose,
  onClickAddComposition,
  selectedCompositions = [],
}: SearchCompositionModalProps) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');
  const [compositions, setCompositions] = useState<Array<PurchaseItemSearch | ProcessItemSearch | ElementSearch>>([]);
  const [hideCompositions, setHideCompositions] = useState<string[]>(selectedCompositions);

  const fetchSearchComposition = async (searchText: string) => {
    try {
      const res = await CompositionSearchService.getAll({ searchText });
      setCompositions(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchSearchComposition('');
  }, []);

  const handleApplySearch = () => {
    fetchSearchComposition(searchValue);
  };

  const handleAddComposition = (composition: any) => {
    setHideCompositions([
      ...hideCompositions,
      composition.purchase_item_cd || composition.process_item_cd || composition.element_cd,
    ]);
    onClickAddComposition(composition);
  };

  return (
    <Modal show size="lg" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* filter */}
        <div className="mb-3">
          <Row>
            <Col sm={6}>
              <InputGroup>
                <FormControl
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={t('CompositionSearchModal.search')}
                  aria-label="Search"
                />
                <InputGroup.Text>
                  <FaSearch onClick={handleApplySearch} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </div>

        {/* list */}
        <div className="sticky-table" style={{ height: '540px' }}>
          <div className="div-table fixsize-column">
            <div className="div-thead div-tr">
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}%` }}>
                {t('CompositionSearchModal.select')}
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}%` }}>
                {t('CompositionSearchModal.code')}
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}%` }}>
                {t('CompositionSearchModal.name')}
              </div>
            </div>

            {compositions.map((composition: any, idx: number) => {
              const code = composition.purchase_item_cd || composition.process_item_cd || composition.element_cd;
              const name = composition.purchase_item_name || composition.process_item_name || composition.element_name;
              return (
                <div className="div-tr" key={idx}>
                  <div className="div-td text-center" style={{ width: `${COLUMN_WIDTH[0]}%` }}>
                    <Button
                      onClick={() => handleAddComposition(composition)}
                      disabled={selectedCompositions?.includes(code) || hideCompositions?.includes(code)}
                    >
                      {t('CompositionSearchModal.add')}
                    </Button>
                  </div>
                  <div className="div-td d-flex align-items-center" style={{ width: `${COLUMN_WIDTH[1]}%` }}>
                    {code}
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}%` }}>
                    {name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SearchCompositionModal;
