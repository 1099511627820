import { AllergyMaster, AllergyMasterAddPayload, AllergyMasterUpdatePayload, ApiResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { AllergyMasterFilterValues } from '.';

const ROOT_API_ROUTE = '/allergy-masters';

const allergyMasterService = {
  getAll(filter: AllergyMasterFilterValues): Promise<ApiResponse<AllergyMaster[]>> {
    let url = ROOT_API_ROUTE;

    const params = new URLSearchParams();
    if (filter.type !== 0) {
      params.append('type', filter.type.toString());
    }

    if (filter.displayFlag) {
      params.append('display_flg', `${filter?.displayFlag}`);
    }

    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return axiosClient.get(url);
  },
  add(payload: AllergyMasterAddPayload): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.post(url, payload);
  },
  update(payload: AllergyMasterUpdatePayload[]): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.patch(url, payload);
  },
};

export default allergyMasterService;
