import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AllergyMasterFilterValues } from 'features/MasterDataManagement/AllergyMaster';
import { AllergyMaster, ApiResponse } from 'models';

export interface AllergyMasterState {
  loading: boolean;
  data: AllergyMaster[];
}

const initialState: AllergyMasterState = {
  loading: false,
  data: [],
};

const allergyMasterSlice = createSlice({
  name: 'AllergyMaster',
  initialState,
  reducers: {
    fetchAllergyMasterList(state, action: PayloadAction<AllergyMasterFilterValues>) {
      state.loading = true;
    },
    fetchAllergyMasterListSuccess(state, action: PayloadAction<ApiResponse<AllergyMaster[]>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchAllergyMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const allergyMasterActions = allergyMasterSlice.actions;

// Selectors
export const selectAllergyMasterData = (state: RootState) => state.allergyMaster.data;

// Reducer
export const allergyMasterReducer = allergyMasterSlice.reducer;
