import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import nutritionMasterService from 'features/MasterDataManagement/NutritionMaster/nutritionMasterService';
import { ApiResponse, NutritionMaster } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { nutritionMasterActions } from './nutritionMasterSlice';

function* fetchNutritionMasterList() {
  try {
    const response: ApiResponse<NutritionMaster[]> = yield call(nutritionMasterService.getAll);
    yield put(nutritionMasterActions.fetchNutritionMasterListSuccess(response));
  } catch (e) {
    yield put(nutritionMasterActions.fetchNutritionMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* nutritionMasterSaga() {
  yield takeLatest(nutritionMasterActions.fetchNutritionMasterList.type, fetchNutritionMasterList);
}
