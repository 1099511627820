import { Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ITEM_TABLE_HEADER } from '../constants/tableHeader';
import dayjs from 'dayjs';

const ItemTable = ({
  menus = [],
  elements = [],
  onSelect,
  selectedItems = [],
}: {
  menus: any[];
  elements: any[];
  onSelect: (item: any) => void;
  selectedItems: any[];
}) => {
  const { t } = useTranslation();
  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1" style={{ zIndex: 0 }}>
      <Table>
        <thead>
          <tr>
            {ITEM_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={idx}>
                {t(`${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {/* Menu part */}
          <tr>
            <th
              className="text-start"
              colSpan={ITEM_TABLE_HEADER.length}
              style={{ width: '100%', borderTop: 'none', borderBottom: '1px solid #ced4da' }}
            >
              {t(`ItemReplacementScreen.target_menu`)}
            </th>
          </tr>
          {menus.map((menu, idx) => {
            const code = menu.menu_master.menu_cd;
            const amount = menu.composition_quantity;
            const unit =
              menu.purchase_unit_master?.purchase_unit_cd || menu.element_unit_master?.element_unit_cd || null;
            const unitName = menu.purchase_unit_master?.name || menu.element_unit_master?.name;
            return (
              <tr key={idx}>
                <td>
                  <Form.Check
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                    onChange={() =>
                      onSelect({
                        code,
                        unit,
                        unitName,
                        amount,
                      })
                    }
                    checked={selectedItems.filter((item) => item.code === code).length > 0}
                  />
                </td>
                <td>{menu.menu_master.menu_cd}</td>
                <td>{menu.menu_master.menu_category_master.menu_category_name}</td>
                <td>{menu.menu_master.menu_name}</td>
                <td>{menu.effective_start_date ? `${dayjs(menu.effective_start_date).format('YYYY/MM/DD')}` : ''}</td>
                <td>{menu.effective_end_date ? `${dayjs(menu.effective_end_date).format('YYYY/MM/DD')}` : ''}</td>
                <td className="text-end">{Number(menu.composition_quantity).toFixed(2)}</td>
                <td>{unitName}</td>
                <td>{menu.menu_master.menu_category_master.business_format_master?.name}</td>
              </tr>
            );
          })}

          {/* Element part */}
          <tr>
            <th
              className="text-start"
              colSpan={ITEM_TABLE_HEADER.length}
              style={{ width: '100%', borderTop: 'none', borderBottom: '1px solid #ced4da' }}
            >
              {t(`ItemReplacementScreen.target_part`)}
            </th>
          </tr>
          {elements.map((element, idx) => {
            const code = element.element_master.element_cd;
            const amount = element.composition_quantity;
            const unit =
              element.purchase_unit_master?.purchase_unit_cd || element.element_unit_master?.element_unit_cd || null;
            const unitName = element.purchase_unit_master?.name || element.element_unit_master?.name;
            return (
              <tr key={idx}>
                <td>
                  <Form.Check
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                    onChange={() =>
                      onSelect({
                        code,
                        unit,
                        unitName,
                        amount,
                      })
                    }
                    checked={selectedItems.filter((item) => item.code === code).length > 0}
                  />
                </td>
                <td>{element.element_master.element_cd}</td>
                <td>{element.element_master?.element_category_master?.element_category_name}</td>
                <td>{element.element_master.element_name}</td>
                <td>
                  {element.effective_start_date ? `${dayjs(element.effective_start_date).format('YYYY/MM/DD')}` : ''}
                </td>
                <td>{element.effective_end_date ? `${dayjs(element.effective_end_date).format('YYYY/MM/DD')}` : ''}</td>
                <td className="text-end">{Number(element.composition_quantity).toFixed(2)}</td>
                <td>{unitName}</td>
                <td>{element.element_master?.element_category_master?.business_format_master?.name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export { ItemTable };
