import { ApiResponse } from 'models';
import axiosClient from 'utils/axiosClient';
import { ItemReplacementMaster, UpdateItemReplacement } from './models';
import {
  ElementSearch,
  ProcessItemSearch,
  PurchaseItemSearch,
} from 'features/SearchCompositionModal/models/compositionSearchModel';

const ROOT_API_ROUTE = 'item-replacement';

const ItemReplacementService = {
  getAllItems(payload: any): Promise<ApiResponse<Array<PurchaseItemSearch | ProcessItemSearch | ElementSearch>>> {
    const ROOT_API_ROUTE = 'composition-search';
    const searchText = `?search_text=${payload.searchText}`;
    let url = `${ROOT_API_ROUTE}${searchText}`;
    return axiosClient.get(url);
  },
  getMenuAndElement(payload: any): Promise<ApiResponse<ItemReplacementMaster>> {
    const productCode = `?item_cd=${payload.code}`;
    const businessCodes = `&business_cds=${payload.business}`;
    let url = `${ROOT_API_ROUTE}${productCode}${businessCodes}`;
    return axiosClient.get(url);
  },
  update(payload: Partial<UpdateItemReplacement>): Promise<ApiResponse> {
    const url = `${ROOT_API_ROUTE}`;
    return axiosClient.patch(url, payload);
  },
};

export default ItemReplacementService;
