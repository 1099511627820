import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NutritionMasterUpdatePayload } from 'models/masterdata';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { nutritionMasterActions, selectNutritionMasterData } from 'store/NutritionMaster/nutritionMasterSlice';
import NutritionMasterForm from './components/NutritionMasterForm';
import NutritionMasterList from './components/NutritionMasterList';
import useNutritionMaster from './hooks/useNutritionMaster';
import './style.scss';

export type NutritionMasterListFormValues = {
  data: NutritionMasterUpdatePayload[];
};

function NutritionMasterFeature() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // hooks
  const { updateNutritionMaster } = useNutritionMaster();

  // refs
  const formRef = useRef<HTMLFormElement>(null);
  const nextDisplayOrder = useRef<number>(1);

  // states
  const [showForm, setShowForm] = useState(false);

  // selectors
  const nutritionMasterList = useAppSelector(selectNutritionMasterData);

  // side effects
  useEffect(() => {
    dispatch(nutritionMasterActions.fetchNutritionMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nutritionMasterList.length === 0) {
      nextDisplayOrder.current = 1;
      return;
    }

    const maxDisplayOrder = nutritionMasterList.reduce(
      (max, item) => (item.display_order > max ? item.display_order : max),
      nutritionMasterList[0].display_order
    );
    nextDisplayOrder.current = maxDisplayOrder + 1;
  }, [nutritionMasterList]);

  //events
  function handleOpenForm() {
    setShowForm(true);
  }

  function handleCloseForm() {
    setShowForm(false);
  }

  function handleNutritionMasterFormReflect() {
    dispatch(nutritionMasterActions.fetchNutritionMasterList());
  }

  function handleSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }

  function handleSubmitNutritionMasterListForm(formValues: NutritionMasterListFormValues) {
    (async () => {
      const { data, error } = await updateNutritionMaster(formValues.data);
      if (data) {
        toast.success(data?.message);
        dispatch(nutritionMasterActions.fetchNutritionMasterList());
      } else {
        if (error && error.error) {
          toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        }
      }
    })();
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('NutritionMaster.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleSubmit}>{t('NutritionMaster.update')}</Button>
              <Button onClick={handleOpenForm}>{t('NutritionMaster.register')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* list */}
      <NutritionMasterList
        formRef={formRef}
        data={nutritionMasterList}
        onSubmitForm={handleSubmitNutritionMasterListForm}
      />

      {/* Form Modal */}
      {showForm &&
        createPortal(
          <NutritionMasterForm
            nextDisplayOrder={nextDisplayOrder.current}
            onClose={handleCloseForm}
            onReflect={handleNutritionMasterFormReflect}
          />,
          document.body
        )}
    </div>
  );
}

export default NutritionMasterFeature;
