import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthorityLevels, NEW, PENDING_APPROVAL, PENDING_CONFIRMATION, SYSTEM_ID } from '../constant';
import { useSelector } from 'react-redux';
import { selectUserInfo, selectUserSelectedJob } from 'features/Auth/authSlice';

interface GroupButtonProps {
  status: string;
  lastUpdater: string;
  handleButtonClick: (param: HandleButtonClickParam) => void;
}

export enum HandleButtonClickParam {
  CONFIRMATION_REQUEST = 'Confirmation request',
  APPROVAL_REQUEST = 'Approval request',
  ADMIT = 'Admit',
  REMAND = 'Remand',
}

/**
 * @function GroupButton
 * @description Button group for managing approval flow in Purchase Processed Calorie Allergy Setting detail page.
 * @param {string} status - Current status of the setting.
 * @param {string} lastUpdater - Employee code of the last updater.
 * @param {function} handleButtonClick - Function to handle button click events.
 * @returns {JSX.Element} Button group component.
 */

const GroupButton = ({ status, lastUpdater, handleButtonClick }: GroupButtonProps) => {
  const { t } = useTranslation();
  const selectedJob = useSelector(selectUserSelectedJob);
  const user = useSelector(selectUserInfo);
  const level = parseInt(selectedJob?.levels[SYSTEM_ID] ?? '0') || 0;

  const hasApprovalAuthority = level === AuthorityLevels.ADMIN || level === AuthorityLevels.APPROVAL;

  const hasNormalDifferentAuthority = level === AuthorityLevels.NORMAL && user?.employee_cd !== lastUpdater;

  const hasFullAuthority =
    level === AuthorityLevels.ADMIN || level === AuthorityLevels.APPROVAL || level === AuthorityLevels.NORMAL;

  return (
    <div>
      {(status === NEW || status === '') && (
        <Button
          className="fs-5 fw-bold me-3"
          onClick={() => handleButtonClick(HandleButtonClickParam.CONFIRMATION_REQUEST)}
          disabled={!hasFullAuthority}
        >
          {t('PartsConfigurationDetail.confirm_request')}
        </Button>
      )}
      {status === PENDING_CONFIRMATION && (
        <Button
          className="fs-5 fw-bold me-3"
          onClick={() => handleButtonClick(HandleButtonClickParam.APPROVAL_REQUEST)}
          disabled={hasApprovalAuthority ? false : !hasNormalDifferentAuthority}
        >
          {t('PartsConfigurationDetail.approval_request')}
        </Button>
      )}
      {status === PENDING_APPROVAL && (
        <Button
          className="fs-5 fw-bold me-3"
          onClick={() => handleButtonClick(HandleButtonClickParam.ADMIT)}
          disabled={!hasApprovalAuthority}
        >
          {t('PartsConfigurationDetail.approval')}
        </Button>
      )}
      {(status === PENDING_APPROVAL || status === PENDING_CONFIRMATION) && (
        <Button
          className="fs-5 fw-bold me-3"
          onClick={() => handleButtonClick(HandleButtonClickParam.REMAND)}
          disabled={!hasApprovalAuthority}
        >
          {t('PartsConfigurationDetail.remand')}
        </Button>
      )}
    </div>
  );
};

export default GroupButton;
