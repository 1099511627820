export const nutritionUnitMasterTranslationJP = {
  NutritionUnitMaster: {
    head_title: '栄養価単位マスタメンテナンス',
    update: '更新',
    register: '新規登録',
    delete: '削除',
    nutrition_unit_name: '名称',
    display_order: '表示順',
    pdf_format: 'カロアレ表書式',
    add: '登録',
    warn_field_required: 'このフィールドは必須です',
    warn_number_required: '０以上の数値のみ入力可',
  },
};
