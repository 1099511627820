import { GroupHeaderValueTypeInterface } from '../components/api/api-header';
import { PDF_COLOR } from './style';
import { GROUP_HEADER_TYPE } from './type';

const GROUP_HEADER: GroupHeaderValueTypeInterface[] = [
  {
    title: '',
    group: GROUP_HEADER_TYPE['name'],
    style: {
      backgroundColor: PDF_COLOR['background']['white'],
    },
  },
  {
    title: '栄養成分値',
    group: GROUP_HEADER_TYPE['calory'],
    style: {
      backgroundColor: PDF_COLOR['background']['green'],
    },
    subHeader: [],
  },
  {
    title: '特定原材料',
    group: GROUP_HEADER_TYPE['materials'],
    style: {
      backgroundColor: PDF_COLOR['background']['red'],
    },
  },
  {
    title: '特定原材料に準ずる品目',
    group: GROUP_HEADER_TYPE['equivalent_materials'],
    style: {
      backgroundColor: PDF_COLOR['background']['blue'],
    },
  },
];

export { GROUP_HEADER };
