import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../style.scss';
import { saveAs } from 'file-saver';

import CalorieTable from './components/calorieTable';
import ReactDatePicker from 'react-datepicker';
import { InputFormDatePicker } from 'components/formfields/InputFormDatePicker';
import { CaloryPDF } from 'features/PDF/components/CaloryPDF';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import AddAllergyChartModal from './components/addAllergyChartModal';
import { createPortal } from 'react-dom';
import {
  genWidthGroupHeader,
  GroupHeaderValueTypeInterface,
  mappingGroupHeaderStyleToHeader,
  otherColWidthCal,
} from 'features/PDF/components/api/api-header';
import { useDispatch } from 'react-redux';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import { useAppSelector } from 'app/hooks';
import { GROUP_HEADER } from 'features/PDF/constants/group-header';
import AllergyCaloryChartService from '../../allergyCalorieSettingService';
import nutritionMasterService from 'features/MasterDataManagement/NutritionMaster/nutritionMasterService';
import nutritionUnitMasterService from 'features/MasterDataManagement/NutritionUnitMaster/nutritionUnitMasterService';
import allergyMasterService from 'features/MasterDataManagement/AllergyMaster/allergyMasterService';
import {
  ALLERGY_MASTER_TYPE_SELECTSOURCE,
  DEFAULT_FILTER_ALLERGY_MASTER_TYPE,
} from 'features/MasterDataManagement/AllergyMaster/constants';
import { GROUP_HEADER_TYPE } from 'features/PDF/constants/type';
import { PDF_COL_STYLE_TABLE_BY_TYPE } from 'features/PDF/constants/style';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { AllergyChartMaster } from '../../models';
import { v4 as uuidv4 } from 'uuid';

interface Filter {
  showMoreDate: Date;
  applyShowMoreDate: boolean;
  business?: number;
}

const CalorieAllergySettingList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  // state
  const [filter, setFilter] = useState<Filter>({
    showMoreDate: new Date(),
    applyShowMoreDate: false,
    business: undefined,
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isOpenAllergyChartModal, setIsOpenAllergyChartModal] = useState<boolean>(false);
  const [allergyChart, setAllergyChart] = useState<AllergyChartMaster[] | []>([]);

  const isDisableOutputButton = selectedIds.length === 0;

  const handleSelect = (id: number) => {
    if (selectedIds.includes(id)) {
      const newSelectedIds = selectedIds.filter((item) => item !== id);
      setSelectedIds([...newSelectedIds]);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleCloseAllergyChartModal = () => {
    setIsOpenAllergyChartModal(false);
    fetchAllergyChartMaster();
  };

  // fetch
  const fetchAllergyChartMaster = async () => {
    try {
      if (filter.business === undefined) return;
      const res = await AllergyCaloryChartService.getAll(filter);
      setAllergyChart(res.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    if (businessFormatMasterList.length === 0) {
      dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    }
  }, []);

  useEffect(() => {
    if (businessFormatMasterList.length === 0) return;
    setFilter({
      ...filter,
      business: businessFormatMasterList[0].business_format_cd,
    });
  }, [businessFormatMasterList]);

  useEffect(() => {
    fetchAllergyChartMaster();
  }, [filter]);

  const fetchNutritionMaster = async () => {
    try {
      const nutritionRes = await nutritionMasterService.getAll();
      const unitRes = await nutritionUnitMasterService.getAll();
      const res = nutritionRes.data.map((nutrition) => {
        return {
          ...nutrition,
          nutrition_unit: `(${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          })`,
          nutrition_header_unit: `${
            unitRes.data.find((unit) => unit.nutrition_unit_cd === nutrition.nutrition_unit_master)?.nutrition_unit_name
          }`,
        };
      });
      return res;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchAllergyMaster = async () => {
    try {
      const allergyRes = await allergyMasterService.getAll({
        type: DEFAULT_FILTER_ALLERGY_MASTER_TYPE,
        displayFlag: true,
      });
      return allergyRes.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchDetailAllergyChart = async (id: string) => {
    try {
      const allergyChartDetailRes = await AllergyCaloryChartService.getDetail(id);
      return allergyChartDetailRes.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const updateExportAllergyChart = async (id: string) => {
    try {
      const res = await AllergyCaloryChartService.updateExportPDF(id);
      toast.success(res.message);
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const nutritionsRes = await fetchNutritionMaster();
  //     setNutritionsMaster(nutritionsRes);

  //     const allergiesRes = await fetchAllergyMaster();
  //     setAllergiesMaster(allergiesRes);
  //   };

  //   fetchData();
  // }, []);

  // mapping
  const mapWidthColStyleFromHeader = (nutritionsRes: any, alldergiesRes: any, widthCols: string[]) => {
    let fieldIdx = 0;

    widthCols[fieldIdx] = 'text';
    fieldIdx++;

    nutritionsRes?.forEach(() => {
      widthCols[fieldIdx] = 'number';
      fieldIdx++;
    });

    alldergiesRes?.forEach(() => {
      widthCols[fieldIdx] = 'icon_mark';
      fieldIdx++;
    });

    return widthCols;
  };

  const mapWidthColStyleFromNutritionAndAllergy = (item: any, startFieldIdx: number, widthCols: string[]) => {
    let fieldIdx = startFieldIdx;

    item.nutritions
      // .sort((nutritionA: any, nutritionB: any) => nutritionA.display_order - nutritionB.display_order)
      .forEach(() => {
        widthCols[fieldIdx] = 'number';
        fieldIdx++;
      });

    item.allergies
      // .sort(
      //   (allergyA: any, allergyB: any) =>
      //     allergyA.allergy_master?.type - allergyB.allergy_master?.type ||
      //     allergyA.allergy_master?.display_order - allergyB.allergy_master?.display_order
      // )
      .forEach((allergy: any) => {
        const colWidthType = allergy.allergy_item_master?.chart_col_width;
        const type = colWidthType === 2 || widthCols[fieldIdx] === 'text_mark' ? 'text_mark' : 'icon_mark';
        widthCols[fieldIdx] = type;
        fieldIdx++;
      });
  };

  const mapWidthColStyleFromAllergyChartDetail = (allergy_charts: any, widthCols: string[]) => {
    allergy_charts.forEach((chart: any) => {
      const isCategoty = chart.display_type === 0;
      if (isCategoty) return;
      let fieldIdx = 0;

      widthCols[fieldIdx] = 'text';
      fieldIdx++;

      mapWidthColStyleFromNutritionAndAllergy(chart.menu_master, fieldIdx, widthCols);

      chart.menu_master.menu_composition_masters.forEach((composition: any) => {
        let fieldIdx = 0;
        widthCols[fieldIdx] = 'text';
        fieldIdx++;

        mapWidthColStyleFromNutritionAndAllergy(composition, fieldIdx, widthCols);
      });
    });

    return widthCols;
  };

  const mapMenuFromAllergyChartDetail = (allergyChart: any, widthCols: any) => {
    const mappedMenus = allergyChart.allergy_charts.map((chart: any) => {
      const isCategoty = chart.display_type === 0;
      if (isCategoty) {
        const id = uuidv4();
        return {
          category: true,
          title: chart.text,
          id: id,
          allergyChartId: chart.id,
          isHidden: !chart.display_flg,
          style: {
            backgroundColor: chart.background_color,
          },
        };
      } else {
        let fieldIdx = 0;
        const menuData = chart.menu_master;
        const isConfirm: boolean = chart.verifier_cd ? true : false;
        const isApprove: boolean = chart.approver_cd ? true : false;
        const newMenu: any = {
          id: menuData.menu_cd,
          allergyChartId: chart.id,
          blank: chart.hierarchy,
          isHidden: !chart.display_flg,
          menuCode: menuData.menu_cd,
          isConfirm,
          confirmerCode: isConfirm ? chart.verifier_cd : null,
          confirmDate: isConfirm ? chart.verified_date : null,
          isApprove,
          approverCode: isApprove ? chart.approver_cd : null,
          approveDate: isApprove ? chart.approved_date : null,
          value: {
            [fieldIdx]: {
              style: {
                width: PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'],
              },
              type: 'text',
              value: chart?.text ?? menuData.menu_name,
              blank: chart.hierarchy,
            },
          },
        };
        fieldIdx++;

        mapNutritionAndAllergy(menuData, newMenu, 1, widthCols);

        // newMenu['compositions'] = [];
        // menuData.menu_composition_masters.forEach((composition: any) => {
        //   let fieldIdx = 0;
        //   const newComposition = {
        //     type: 'composition',
        //     id: composition.composition_cd,
        //     value: {
        //       [fieldIdx]: {
        //         style: {
        //           width: (PDF_COL_STYLE_TABLE_BY_TYPE['text']['width'] || 0) / 2,
        //         },
        //         type: 'text',
        //         value: composition.composition_name,
        //         blank: chart.hierarchy,
        //       },
        //     },
        //   };

        //   mapNutritionAndAllergy(composition, newComposition, 1, widthCols);

        //   newMenu['compositions'].push(newComposition);
        // });

        return newMenu;
      }
    });

    return mappedMenus;
  };

  const mapSubGroupHeaders = (groupHeaders: GroupHeaderValueTypeInterface[], nutritions: any) => {
    // type === calory
    const clonedGroupHeader = cloneDeep(groupHeaders);
    const caloriesGroupHeader = clonedGroupHeader.filter((item) => item.group === GROUP_HEADER_TYPE['calory'])[0];
    caloriesGroupHeader.subHeader = nutritions.map((nutrition: any) => {
      return {
        title: nutrition.nutrition_header_unit,
        style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
      };
    });
    return clonedGroupHeader;
  };

  const mapHeaderGroupAndHeader = (nutritionsRes: any, alldergiesRes: any, widthCols: any) => {
    let fieldIdx = 0;
    let headers: any = [];

    const nameCol = {
      title: 'メニュー名',
      group: GROUP_HEADER_TYPE['name'],
      style: PDF_COL_STYLE_TABLE_BY_TYPE['text'],
    };

    headers.push(nameCol);
    fieldIdx++;

    const mappedNutrition = nutritionsRes?.map((nutrition: any) => {
      fieldIdx++;
      return {
        title: nutrition.nutrition_name,
        group: GROUP_HEADER_TYPE['calory'],
        style: PDF_COL_STYLE_TABLE_BY_TYPE['number'],
      };
    });

    const mappedAllergy = alldergiesRes?.map((allergy: any, idx: number) => {
      const type =
        allergy.type === ALLERGY_MASTER_TYPE_SELECTSOURCE[0].value
          ? GROUP_HEADER_TYPE['materials']
          : GROUP_HEADER_TYPE['equivalent_materials'];
      const styleName = widthCols[fieldIdx] === 'text_mark' ? 'text_mark' : 'icon_mark';
      fieldIdx++;
      return {
        title: allergy.allergy_name,
        group: type,
        style: PDF_COL_STYLE_TABLE_BY_TYPE[styleName],
      };
    });

    headers = [...headers, ...(mappedNutrition || []), ...(mappedAllergy || [])];
    const mappedGroupHeaders = mapSubGroupHeaders(GROUP_HEADER, nutritionsRes);

    return {
      headers,
      groupHeaders: mappedGroupHeaders,
    };
  };

  const mapNutritionAndAllergy = (item: any, newItem: any, startFieldIdx: number, widthCols: string[]) => {
    const mappedWidthCols = widthCols.map((item: string) => {
      return {
        style: PDF_COL_STYLE_TABLE_BY_TYPE[item],
      };
    });
    let fieldIdx = startFieldIdx;
    item.nutritions
      // .sort((nutritionA: any, nutritionB: any) => nutritionA.display_order - nutritionB.display_order)
      .forEach((nutrition: any) => {
        newItem['value'] = {
          ...newItem.value,
          [fieldIdx]: {
            style: {
              width: PDF_COL_STYLE_TABLE_BY_TYPE['number']['width'],
            },
            type: 'number',
            value: nutrition?.amount?.toFixed(1),
          },
        };
        fieldIdx++;
      });

    item.allergies
      // .sort(
      //   (allergyA: any, allergyB: any) =>
      //     allergyA.allergy_master?.type - allergyB.allergy_master?.type ||
      //     allergyA.allergy_master?.display_order - allergyB.allergy_master?.display_order
      // )
      .forEach((allergy: any) => {
        const isTextMark = widthCols[fieldIdx] === 'text_mark';
        const isSpecialText = allergy?.allergy_item_master?.pdf_format === 1;

        const width = isTextMark
          ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['width']
          : otherColWidthCal(mappedWidthCols);
        const fontWeight = isTextMark ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['fontWeight'] : null;
        const specialStyle = isSpecialText ? PDF_COL_STYLE_TABLE_BY_TYPE['text_mark']['specialTextStyle'] : null;
        const type = isTextMark ? 'text_mark' : 'icon_mark';
        newItem['value'] = {
          ...newItem.value,
          [fieldIdx]: {
            style: {
              width: width,
              ...(fontWeight ? { fontWeight: fontWeight } : {}),
              ...(specialStyle ? { ...specialStyle } : {}),
            },
            type: type,
            value: `${allergy.allergy_item_master?.allergy_item_name ?? ''}`,
          },
        };
        fieldIdx++;
      });

    return newItem;
  };

  const downloadPDF = async () => {
    try {
      // toast.info(`${selectedIds.length} PDF ${selectedIds.length === 1 ? 'is' : 'are'} downloading!`);
      const allergiesRes = await fetchAllergyMaster();
      const nutritionsRes = await fetchNutritionMaster();
      for (let i = 0; i < selectedIds.length; i++) {
        const allergyChartDetailRes = await fetchDetailAllergyChart(String(selectedIds[i]));

        let widthCols: string[] = [];
        widthCols = mapWidthColStyleFromHeader(nutritionsRes, allergiesRes, widthCols);
        widthCols = mapWidthColStyleFromAllergyChartDetail(allergyChartDetailRes?.allergy_charts, widthCols);

        const { groupHeaders, headers } = mapHeaderGroupAndHeader(nutritionsRes, allergiesRes, widthCols);
        const mappedMenus = mapMenuFromAllergyChartDetail(allergyChartDetailRes, widthCols);
        const mappedHeadersRender = mappingGroupHeaderStyleToHeader(headers, groupHeaders, 100);
        const mappedGroupHeaderRender = genWidthGroupHeader(mappedHeadersRender, groupHeaders, 100);
        const mappedWidthCols = widthCols.map((item: string) => {
          return {
            style: PDF_COL_STYLE_TABLE_BY_TYPE[item],
          };
        });
        const clonedMenus = cloneDeep(mappedMenus);
        const calculatedWidthMenus = clonedMenus.map((menu: any) => {
          if (menu.category) return menu;
          let valueObj = menu.value;
          Object.keys(valueObj).forEach((key) => {
            if (valueObj[key].type === 'icon_mark') {
              menu['value'][key] = {
                ...menu['value'][key],
                style: {
                  width: otherColWidthCal(mappedWidthCols),
                },
              };
            }
          });
          return menu;
        });

        const asPdf = pdf();
        asPdf.updateContainer(
          <CaloryPDF
            values={calculatedWidthMenus}
            headers={mappedHeadersRender}
            groupHeaders={mappedGroupHeaderRender}
            date={allergyChartDetailRes?.revision_date}
            template={allergyChartDetailRes?.template}
          />
        );
        const pdfBlob = await asPdf.toBlob();
        saveAs(pdfBlob, `${allergyChartDetailRes?.title}_${dayjs(new Date()).format('YYYYMMDD')}.pdf`);
        toast.success(
          `PDF ${allergyChartDetailRes?.title}_${dayjs(new Date()).format('YYYYMMDD')} ${t(
            'CalorieManagementSettingScreen.downloaded'
          )}!`
        );
        await updateExportAllergyChart(String(selectedIds[i]));
      }

      fetchAllergyChartMaster();
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleRemoveChart = async (allergy_chart_cd: string) => {
    await AllergyCaloryChartService.delete(allergy_chart_cd);
    fetchAllergyChartMaster();
  };

  return (
    <div className="page-content d-flex flex-column">
      {/* Modal */}
      {isOpenAllergyChartModal &&
        createPortal(<AddAllergyChartModal onClose={handleCloseAllergyChartModal} />, document.body)}

      {/* Header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('CalorieManagementSettingScreen.list_head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={downloadPDF} disabled={isDisableOutputButton}>
                {t('CalorieManagementSettingScreen.output')}
              </Button>
              <Button onClick={() => setIsOpenAllergyChartModal(true)}>
                {t('CalorieManagementSettingScreen.new_register')}
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* Filter form  */}
      <div className="py-3">
        <Row className="align-items-center mb-3 gap-4">
          {/* Search, select filter  */}
          <Row>
            <Col xs="auto">
              <Row className="align-items-center">
                <Form.Label column className="text-start">
                  {t('MenuStructureSettingScreen.business')}
                </Form.Label>

                <Col xs="auto">
                  <Form.Select
                    style={{ width: '200px' }}
                    value={filter.business}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        business: Number(e.target.value),
                      })
                    }
                  >
                    {businessFormatMasterList.map((option: any, idx: number) => {
                      return (
                        <option key={idx} value={option.business_format_cd} hidden={option?.hidden}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Col>

            <Col className="d-flex align-items-center">
              <Stack direction="horizontal" gap={2}>
                <Form.Check
                  className="anra-checkbox"
                  type="checkbox"
                  label={t('')}
                  checked={filter.applyShowMoreDate}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      applyShowMoreDate: !filter.applyShowMoreDate,
                    })
                  }
                />
                <div style={{ maxWidth: '120px' }}>
                  <ReactDatePicker
                    locale="ja"
                    selected={filter.showMoreDate}
                    onChange={(date: Date) => {
                      setFilter({
                        ...filter,
                        showMoreDate: date,
                      });
                    }}
                    customInput={<InputFormDatePicker />}
                    dateFormat="yyyy/MM/dd"
                    name="suspension_date"
                  />
                </div>
                <div className="d-flex">{t(`CalorieManagementSettingScreen.show_more`)}</div>
              </Stack>
            </Col>
          </Row>
        </Row>
      </div>

      {/* Table */}
      <CalorieTable
        charts={allergyChart}
        onSelect={handleSelect}
        selectedIds={selectedIds}
        onRemove={handleRemoveChart}
      />
    </div>
  );
};

export default CalorieAllergySettingList;
