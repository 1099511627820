export const allergyItemMasterTranslationJP = {
  AllergyItemMaster: {
    head_title: 'アレルゲン選択項目マスタメンテナンス',
    update: '更新',
    register: '新規登録',
    delete: '削除',
    allergy_item_name: '名称',
    description: '説明',
    priority: '優先度',
    display_order: '表示順',
    pdf_format: 'カロアレ表書式',
    chart_col_width: 'カロアレ表項目幅',
    add: '登録',

    warn_field_required: 'このフィールドは必須です',
    warn_number_required: '０以上の数値のみ入力可',
  },
};
