import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Company } from './models/company';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import authService from './authService';
import { authActions } from './authSlice';
import { Login, Role, User } from './models/login';
import { ApiError } from 'models';

function* yieldLogout() {
  try {
    yield call(authService.postLogout);
    yield put(authActions.actionLogoutSuccess());
    yield put(push('/login'));
  } catch (e) {
    yield put(authActions.actionLogoutFailed(e as AxiosError));
    yield handleResponseError(e as AxiosError);
  }
}

function* yieldLogin(action: PayloadAction<Login>) {
  try {
    const response: AxiosResponse<User> = yield call(authService.postLogin, action.payload);
    yield put(authActions.actionLoginSuccess(response));
  } catch (e) {
    yield put(authActions.actionLoginFailed(e as AxiosError<ApiError>));
  }
}

function* yieldFetchCompanyList() {
  try {
    const response: AxiosResponse<Company[]> = yield call(authService.getCompanies);
    yield put(authActions.fetchCompanySuccess(response));
  } catch (e) {
    yield put(authActions.fetchCompanyFailed());
    yield handleResponseError(e as AxiosError);
  }
}

function* yieldUserSetSelectedJob(action: PayloadAction<Role>) {
  try {
    const level = Object.values(action.payload.levels)[0] || '';
    yield call(authService.postUserSelectedJobs, level);
  } catch (e) {
    yield put(authActions.actionSetSelectedJobFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.actionLogout.type, yieldLogout);
  yield takeEvery(authActions.actionLogin.type, yieldLogin);
  yield takeLatest(authActions.fetchCompanyList.type, yieldFetchCompanyList);
  yield takeEvery(authActions.actionSetSelectedJob.type, yieldUserSetSelectedJob);
}
