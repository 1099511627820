const commonTranslationEN = {
  App: {
    app_name: 'Authoring system',
    error: 'Error',
    info: 'Info',
    warning: 'Warning',
    confirm: 'Confirmation',
    loading: 'Loading...',
    change: 'Change',
    success: 'Success',
    unexpected_error: 'Unexpected Error',
  },
};

export const translationEN = {
  ...commonTranslationEN,
};
