import { useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';
import DemoFormModal from '../components/DemoFormModal/DemoFormModal';
const COLUMN_WIDTH = [50, 200, 200, 150];

function DemoListPage() {
  const [showModal, setShowModal] = useState(false);

  function handleShowModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">アレルゲンマスタメンテナンス</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button>更新</Button>
              <Button onClick={handleShowModal}>新規登録</Button>
              <Button>戻る</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* filter */}
      <div className="py-3">
        <Row className="align-items-center gap-4">
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="全て"
                  name="group1"
                  type="radio"
                  defaultChecked
                  id="group1-item-1"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="特定原材料のみ"
                  name="group1"
                  type="radio"
                  id="group1-item-2"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="特定原材料に準ずる品目のみ"
                  name="group1"
                  type="radio"
                  id="group1-item-3"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* Pagination */}
      <div className="py-2">
        <div className="anra-pagination d-inline-flex flex-row gap-1 align-items-center">
          <Button size="sm" variant="primary" className="btn--auto me-1">
            <TfiAngleLeft size={16} />
          </Button>
          <Form.Control
            size="sm"
            type="text"
            defaultValue="1"
            className="d-inline-block text-center"
            style={{ width: '5rem' }}
          />
          <span>/20ページ</span>
          <Button size="sm" variant="primary" className="btn--auto ms-1">
            <TfiAngleRight size={16} />
          </Button>
        </div>
      </div>

      {/* list */}
      <div className="sticky-table flex-grow-1 flex-shrink-1">
        <div className="div-table fixsize-column">
          <div className="div-thead div-tr">
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
              削除
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
              種別
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
              名称
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
              表示順
            </div>
          </div>

          {Array.from(Array(120).keys()).map((rowIndex) => {
            return (
              <div className="div-tr" key={rowIndex}>
                <div
                  className="div-td d-flex align-items-center justify-content-center"
                  style={{ width: `${COLUMN_WIDTH[0]}px` }}
                >
                  <Form.Check className="anra-checkbox d-flex justify-content-center" label="" type="checkbox" />
                </div>
                <div className="div-td d-flex align-items-center" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                  Row 1, Col 2
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                  <Form.Select>
                    <option>option 1</option>
                    <option>option 2</option>
                    <option>option 3</option>
                    <option>option 4</option>
                    <option>option 5</option>
                  </Form.Select>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                  <Form.Control type="text" placeholder="Enter number" />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Row>
        <Col sm={6}>
          <div className="border p-2 bg-main fw-bold mt-3 text-center">備考</div>
          <div className="border border-top-0 p-2 overflow-auto" style={{ maxHeight: '80px' }}>
            <div className="p-1">text 1</div>
            <div className="p-1">text 2</div>
            <div className="p-1">text 3</div>
            <div className="p-1">text 4</div>
            <div className="p-1">text 5</div>
          </div>
        </Col>
      </Row>

      {/* Modal */}
      {showModal && createPortal(<DemoFormModal onClose={handleCloseModal} />, document.body)}
    </div>
  );
}

export default DemoListPage;
