import { useCallback, useRef } from 'react';
import { Form } from 'react-bootstrap';
import '../style.scss';
import { AllergyItem } from '../models/PurchaseProcessedCalorieAllergySetting';

interface SelectItemProps {
  value: number;
  id: string;
  options: AllergyItem[];
  readonly: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const AllergyItemSelect = ({ value, options, readonly, id, onChange }: SelectItemProps) => {
  const inputRef = useRef<any>(null);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const parent = inputRef.current?.parentElement;
    if (parent) {
      parent.style.background = '#f8cecc';
    }
    onChange(e);
  };

  const renderOptions = useCallback(
    () =>
      options.map((allergyItem, idx) => (
        <option key={idx} value={allergyItem.allergy_item_cd}>
          {allergyItem.allergy_item_name}
        </option>
      )),
    [options]
  );

  return (
    <Form.Select
      className="form-select form-select-md"
      aria-label=".form-select-sm example"
      value={value}
      onChange={handleChange}
      disabled={readonly}
      ref={inputRef}
      id={id}
    >
      {renderOptions()}
      {value === 0 && <option value={0}></option>}
    </Form.Select>
  );
};

export default AllergyItemSelect;
