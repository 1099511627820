import { KaenteiExportTemplate } from 'features/PDF/components/Template/kaentei';
import { AnrakuteiTemplate } from './anrakutei';
import { DonTemplate } from './don';
import { DonteiTemplate } from './dontei';
import { KaenteiTemplate } from './kaentei';
import { ShichirinboTemplate } from './shichirinbo';
import { VolksTemplate } from './volks';
import { AnrakuteiExportTemplate } from 'features/PDF/components/Template/anrakutei';
import { DonExportTemplate } from 'features/PDF/components/Template/don';
import { VolksExportTemplate } from 'features/PDF/components/Template/volks';
import { ShichirinboExportTemplate } from 'features/PDF/components/Template/shichirinbo';
import { DonteiExportTemplate } from 'features/PDF/components/Template/dontei';

export const CHART_TEMLATES: { name: string; displayComponent: any; exportComponent: any; value: number }[] = [
  {
    name: '★花炎亭【フォーマット_標準】',
    displayComponent: <KaenteiTemplate />,
    exportComponent: <KaenteiExportTemplate />,
    value: 0,
  },
  {
    name: '★安楽亭【フォーマット_標準】',
    displayComponent: <AnrakuteiTemplate />,
    exportComponent: <AnrakuteiExportTemplate />,
    value: 1,
  },
  {
    name: '★七輪房カロアレ表【フォーマット_標準】',
    displayComponent: <ShichirinboTemplate />,
    exportComponent: <ShichirinboExportTemplate />,
    value: 2,
  },
  {
    name: '★どん亭【フォーマット_標準】',
    displayComponent: <DonteiTemplate />,
    exportComponent: <DonteiExportTemplate />,
    value: 3,
  },
  {
    name: '★どん【フォーマット_標準】',
    displayComponent: <DonTemplate />,
    exportComponent: <DonExportTemplate />,
    value: 4,
  },
  {
    name: '★Volks【フォーマット_特殊】',
    displayComponent: <VolksTemplate />,
    exportComponent: <VolksExportTemplate />,
    value: 5,
  },
];
