import { ApiResponse, NutritionMaster, NutritionMasterAddPayload, NutritionMasterUpdatePayload } from 'models';
import axiosClient from 'utils/axiosClient';

const ROOT_API_ROUTE = '/nutrition-masters';

const nutritionMasterService = {
  getAll(): Promise<ApiResponse<NutritionMaster[]>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.get(url);
  },
  add(payload: NutritionMasterAddPayload): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.post(url, payload);
  },
  update(payload: NutritionMasterUpdatePayload[]): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.patch(url, payload);
  },
};

export default nutritionMasterService;
