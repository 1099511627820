import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { MenuCategoryMasterFilterValues } from 'features/MasterDataManagement/MenuCategoryMaster';
import { ApiResponse, MenuCategoryMaster } from 'models';

export interface MenuCategoryMasterState {
  loading: boolean;
  data: MenuCategoryMaster[];
}

const initialState: MenuCategoryMasterState = {
  loading: false,
  data: [],
};

const menuCategoryMasterSlice = createSlice({
  name: 'MenuCategoryMaster',
  initialState,
  reducers: {
    fetchMenuCategoryMasterList(state, action: PayloadAction<MenuCategoryMasterFilterValues | undefined>) {
      state.loading = true;
    },
    fetchMenuCategoryMasterListSuccess(state, action: PayloadAction<ApiResponse<MenuCategoryMaster[]>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchMenuCategoryMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const menuCategoryMasterActions = menuCategoryMasterSlice.actions;

// Selectors
export const selectMenuCategoryMasterData = (state: RootState) => state.menuCategoryMaster.data;

// Reducer
export const menuCategoryMasterReducer = menuCategoryMasterSlice.reducer;
