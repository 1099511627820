import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import allergyItemMasterService from 'features/MasterDataManagement/AllergyItemMaster/allergyItemMasterService';
import { AllergyItemMaster, ApiResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { allergyItemMasterActions } from './allergyItemMasterSlice';

function* fetchAllergyItemMasterList() {
  try {
    const response: ApiResponse<AllergyItemMaster[]> = yield call(allergyItemMasterService.getAll);
    yield put(allergyItemMasterActions.fetchAllergyItemMasterListSuccess(response));
  } catch (e) {
    yield put(allergyItemMasterActions.fetchAllergyItemMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* allergyItemMasterSaga() {
  yield takeLatest(allergyItemMasterActions.fetchAllergyItemMasterList.type, fetchAllergyItemMasterList);
}
