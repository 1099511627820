import ErrorNotification from 'components/common/ErrorNotification';
import { GlobalModalProvider } from 'components/common/GlobalModal/GlobalModalContext';
import LoadingIndicator from 'components/common/LoadingIndicator';
import { AppLayout } from 'components/layout';
import ja from 'date-fns/locale/ja';
import LoginPage from 'features/Auth/pages/Login';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { PrivateRoute } from 'components/common/PrivateRoute';

// register local ja for calendar component
registerLocale('ja', ja);

function App() {
  return (
    <div className="app">
      <GlobalModalProvider>
        <ErrorNotification />
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path="/">
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          </Route>
        </Switch>
      </GlobalModalProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
      <LoadingIndicator />
    </div>
  );
}

export default App;
