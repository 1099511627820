import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MenuCategoryMasterAddPayload } from 'models';
import { useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LiaTimesSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import * as yup from 'yup';
import useMenuCategoryMaster from '../hooks/useMenuCategoryMaster';

interface MenuCategoryMasterFormProps {
  nextDisplayOrder: number;
  onClose: () => void;
  onReflect?: () => void;
}

function MenuCategoryMasterForm({ nextDisplayOrder, onClose, onReflect }: MenuCategoryMasterFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { addMenuCategoryMaster } = useMenuCategoryMaster();

  // selectors
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);

  // schema validation
  const schema = yup
    .object({
      business_format_master: yup.number().required(),
      menu_category_name: yup.string().required(t('MenuCategoryMaster.warn_field_required')),
      display_order: yup
        .number()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && originalValue.trim() === '') {
            return undefined;
          }
          return value;
        })
        .min(1, t('MenuCategoryMaster.warn_number_required'))
        .integer(t('MenuCategoryMaster.warn_number_required'))
        .required(t('MenuCategoryMaster.warn_number_required')),
    })
    .required();

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<MenuCategoryMasterAddPayload>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (payload: MenuCategoryMasterAddPayload) => {
    const { data, error } = await addMenuCategoryMaster(payload);
    if (data) {
      toast.success(data?.message);
      onReflect?.();
      onClose();
    } else {
      if (error && error.error) {
        const values = getValues();
        const fieldNames = Object.keys(values);

        toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        for (const key of Object.keys(error.error)) {
          if (fieldNames.includes(key)) {
            setError(key as keyof MenuCategoryMasterAddPayload, { type: 'custom', message: error.error[key] });
          }
        }
      }
    }
  };

  // side effect
  useEffect(() => {
    dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show
      size="lg"
      dialogClassName="menu-category-master-form-modal"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row} className="mb-3" controlId="type">
            <Form.Label column sm="3" className="text-end">
              {t('MenuCategoryMaster.business_type')}
            </Form.Label>
            <Col sm="9">
              <Form.Select {...register('business_format_master')} autoFocus>
                {businessFormatMasterList.map((option) => (
                  <option key={option.business_format_cd} value={option.business_format_cd}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="menu_category_name">
            <Form.Label column sm="3" className="text-end">
              {t('MenuCategoryMaster.name')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                {...register('menu_category_name')}
                type="text"
                placeholder=""
                isInvalid={!!errors.menu_category_name}
              />
              <Form.Control.Feedback type="invalid">{errors.menu_category_name?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="display_order">
            <Form.Label column sm="3" className="text-end">
              {t('MenuCategoryMaster.display_order')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                {...register('display_order')}
                style={{ width: '80px' }}
                className="text-end"
                type="number"
                placeholder=""
                defaultValue={nextDisplayOrder}
                min={1}
                isInvalid={!!errors.display_order}
              />
              <Form.Control.Feedback type="invalid">{errors.display_order?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className="text-center mt-5">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="d-inline-flex gap-1 justify-content-center btn btn-primary align-items-center"
            >
              <span>{t('MenuCategoryMaster.add')}</span>
              {isSubmitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default MenuCategoryMasterForm;
