const PRE_MENU_TABLE_HEADER = [
  {
    title: '選択',
    width: 15,
  },
  {
    title: 'ステータス',
    width: 20,
  },
  {
    title: 'お知らせ',
    width: 20,
  },
  {
    title: '部品コード',
    width: 50,
  },
  {
    title: '名称',
    width: 70,
  },
];

const POST_MENU_TABLE_HEADER = [
  {
    title: '特定原材料',
    width: 60,
  },
  {
    title: '特定原材料に準ずる品目',
    width: 60,
  },
  {
    title: '最終更新者',
    width: 40,
  },
  {
    title: '最終更新日',
    width: 40,
  },
  {
    title: '確認者',
    width: 40,
  },
  {
    title: '確認日',
    width: 40,
  },
];

export { PRE_MENU_TABLE_HEADER, POST_MENU_TABLE_HEADER };
