import PDFgeneration from 'features/PDF';

function DemoPDF() {
  return (
    <div>
      <div style={{ margin: '30px 30px' }}>
        <PDFgeneration />
      </div>
    </div>
  );
}

export default DemoPDF;
