import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import elementClassificationMasterService from 'features/MasterDataManagement/ElementClassificationMaster/elementClassificationMasterService';
import { ApiResponse, ElementClassificationMaster } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { elementClassificationMasterActions } from './elementClassificationMasterSlice';

function* fetchElementClassificationMasterList() {
  try {
    const response: ApiResponse<ElementClassificationMaster[]> = yield call(elementClassificationMasterService.getAll);
    yield put(elementClassificationMasterActions.fetchElementClassificationMasterListSuccess(response));
  } catch (e) {
    yield put(elementClassificationMasterActions.fetchElementClassificationMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* elementClassificationMasterSaga() {
  yield takeLatest(
    elementClassificationMasterActions.fetchElementClassificationMasterList.type,
    fetchElementClassificationMasterList
  );
}
