import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ApiResponse, ElementClassificationMaster } from 'models';

export interface ElementClassificationMasterState {
  loading: boolean;
  data: ElementClassificationMaster[];
}

const initialState: ElementClassificationMasterState = {
  loading: false,
  data: [],
};

const elementClassificationMasterSlice = createSlice({
  name: 'ElementClassificationMaster',
  initialState,
  reducers: {
    fetchElementClassificationMasterList(state) {
      state.loading = true;
    },
    fetchElementClassificationMasterListSuccess(
      state,
      action: PayloadAction<ApiResponse<ElementClassificationMaster[]>>
    ) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchElementClassificationMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const elementClassificationMasterActions = elementClassificationMasterSlice.actions;

// Selectors
export const selectElementClassificationMasterData = (state: RootState) => state.elementClassificationMaster.data;

// Reducer
export const elementClassificationMasterReducer = elementClassificationMasterSlice.reducer;
