import { Text, View } from '@react-pdf/renderer';
import { styles } from './table-styles';

const TableNotice = () => {
  return (
    <View style={styles.tableNoticeContainer}>
      <Text>
        ★下記メニューの表記は、セットのごはん・スープ・キムチ・ミニサラダ・デザート等を含んだものになります。セットメニュー詳細は下部に記載しております。
      </Text>
      <Text>オプションメニューはグランドメニューページをご参照ください。</Text>
    </View>
  );
};

export { TableNotice };
