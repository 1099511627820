import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { ApiResponse, BusinessFormatMaster } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import businessFormatMasterService from 'services/businessFormatMasterService';
import { businessFormatMasterActions } from './businessFormatMasterSlice';

function* fetchBusinessFormatMasterList() {
  try {
    const response: ApiResponse<BusinessFormatMaster[]> = yield call(businessFormatMasterService.getAll);
    yield put(businessFormatMasterActions.fetchBusinessFormatMasterListSuccess(response));
  } catch (e) {
    yield put(businessFormatMasterActions.fetchBusinessFormatMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* businessFormatMasterSaga() {
  yield takeLatest(businessFormatMasterActions.fetchBusinessFormatMasterList.type, fetchBusinessFormatMasterList);
}
