import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import { CustomInputDatePicker } from 'components/common/CustomInputDatePicker';
import api from '../Service';
import '../style.scss';
import {
  ElementCompositionResponse,
  ElementConfigurationSettingRequest,
  ElementConfigurationSettingResponse,
} from '../models/model';
import { PENDING_CONFIRMATION, PENDING_APPROVAL } from '../constant';
import GroupButton, { HandleButtonClickParam } from '../components/ButtonApproveGroup';
import CheckBoxBusinessFormatItem from '../components/CheckBoxBusinessFormatMaster';
import ClassificationItemSelect from '../components/SelectClassificationMaster';
import DatePickerCustom from '../components/DatePickerCustom';
import SearchCompositionModal from 'features/SearchCompositionModal';
import { ApiError, ApiResponse } from 'models';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import ViewDetailCompositionModal from 'features/ViewDetailCompositionModal';

const COLUMN_WIDTH = [40, 110, 150, 200, 280];

function PartsConfigurationDetailSetting() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { element_cd }: { element_cd: string } = useParams();

  //type
  type RouteAction = 'copy' | 'edit' | 'new';

  interface ErrorObject {
    element_cd: string | null;
    element_name: string | null;
    business_format: string | null;
    element_classification: string | null;
  }

  //ref
  const actionRef = useRef<RouteAction>('new');
  const elementClassificationRef = useRef<string>();
  const businessFormatRef = useRef<string>();
  const applicationDateRef = useRef<Date>(new Date());
  const selectedCompositionsRef = useRef<string[]>([]);
  const currentCompositionsRef = useRef<string>('');
  const supplementary_unit_1Ref = useRef<string>('');
  const supplementary_unit_2Ref = useRef<string>('');

  // constant
  const apiCalls = {
    [HandleButtonClickParam.CONFIRMATION_REQUEST]: api.confirmRequest,
    [HandleButtonClickParam.APPROVAL_REQUEST]: api.approveRequest,
    [HandleButtonClickParam.ADMIT]: api.admitRequest,
    [HandleButtonClickParam.REMAND]: api.cancelRequest,
  };

  //state
  const [data, setData] = useState<ElementConfigurationSettingResponse>();
  const [isOpenAddCompositionModal, setIsOpenAddCompositionModal] = useState<boolean>(false);
  const [isOpenDetailCompositionModal, setIsOpenDetailCompositionModal] = useState<boolean>(false);

  const [status, setStatus] = useState('');
  const [lastUpdater, setLastUpdater] = useState('');

  const [suspendDate, setSuspendDate] = useState(new Date());
  const [applicationDate, setApplicationDate] = useState(new Date());
  const [errors, setErrors] = useState<ErrorObject>({
    element_cd: null,
    business_format: null,
    element_classification: null,
    element_name: null,
  });

  if (location.pathname.includes('copy')) {
    actionRef.current = 'copy';
  } else if (location.pathname.includes('edit')) {
    actionRef.current = 'edit';
  } else if (location.pathname.includes('new')) {
    actionRef.current = 'new';
  }

  //useEffect
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      let id = element_cd;
      const application_date = applicationDateRef.current.toISOString().slice(0, 10);
      if (actionRef.current === 'new') {
        id = '0000000000';
      }
      const response = await api.getById(id, actionRef.current, application_date);
      businessFormatRef.current = response.business_format_cd;
      elementClassificationRef.current = response.element_classification_cd;
      setStatus(response.status);
      setLastUpdater(response.applicant_cd);
      if (response.suspend_date) {
        setSuspendDate(new Date(response.suspend_date));
      }
      setData(response);
      console.log(response);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const confirmRequestStatus = async (
    data: ElementConfigurationSettingRequest,
    callback: (data: ElementConfigurationSettingRequest) => Promise<ApiResponse>
  ) => {
    try {
      const response = await callback(data);
      if (response) {
        toast.success(response.message);
        fetchData();
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const res = (error as AxiosError<ApiError>)?.response?.data;
      const status = (error as AxiosError<ApiError>)?.response?.status;
      if (status === 400 && res?.message) {
        toast.error(res.message);
      }
    }
  };

  const approveRequestStatus = async (
    data: Partial<ElementConfigurationSettingRequest>,
    callback: (data: Partial<ElementConfigurationSettingRequest>) => Promise<ApiResponse>
  ) => {
    try {
      const response = await callback(data);
      if (response) {
        toast.success(response.message);
        fetchData();
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const res = (error as AxiosError<ApiError>)?.response?.data;
      const status = (error as AxiosError<ApiError>)?.response?.status;
      if (status === 400 && res?.message) {
        toast.error(res.message);
      }
    }
  };

  const handleApproveButtonClick = (buttonType: HandleButtonClickParam) => {
    if (!data) return;
    const error = handleError();
    if (error) {
      return;
    }
    const apiCall = apiCalls[buttonType];
    if (buttonType === HandleButtonClickParam.CONFIRMATION_REQUEST) {
      const requestData = {
        element_cd: data.element_cd,
        element_name: data.element_name,
        business_format_cd: data.business_format_cd,
        remarks: data.remarks,
        element_classification_cd: data.element_classification_cd,
        suspend_date: data.suspend_date,
        element_unit_master: data.element_unit_master,
        supplementary_unit_1: data.supplementary_unit_1,
        supplementary_unit_2: data.supplementary_unit_2,
        supplementary_unit_conversion_rate_1: data.supplementary_unit_conversion_rate_1,
        supplementary_unit_conversion_rate_2: data.supplementary_unit_conversion_rate_2,
        change_flg:
          data.element_compositions.some((composition) => composition.change_flg) ||
          data.element_compositions.some((composition) => composition.is_add_new) ||
          data.element_compositions.some((composition) => composition.is_deleted && !composition.is_add_new),
        element_compositions: data.element_compositions.map((composition) => {
          return {
            id: composition.id,
            composition_cd: composition.composition_cd,
            change_flg: composition.change_flg,
            composition_quantity: composition.composition_quantity,
            sort_key: composition.sort_key ?? null,
            effective_start_date: composition.effective_start_date,
            effective_end_date: composition.effective_end_date,
            composition_unit_cd: composition.composition_unit_cd,
            is_deleted: composition.is_deleted || false,
          };
        }),
      } as ElementConfigurationSettingRequest;
      confirmRequestStatus(requestData, async () => await apiCall(requestData));
    } else {
      const approveRequestObj = {
        element_cd: data.element_cd as string,
      } as ElementConfigurationSettingRequest;
      approveRequestStatus(approveRequestObj, async () => await apiCall(approveRequestObj));
    }
  };

  const handleInputCheckboxBusinessFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    businessFormatRef.current = value;
    setErrors({ ...errors, [name]: null });
    setData(data && { ...data, business_format_cd: businessFormatRef.current });
  };

  const handleInputElementClassification = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    elementClassificationRef.current = value;
    setErrors({ ...errors, [name]: null });
    setData(data && { ...data, element_classification_cd: elementClassificationRef.current });
  };

  const handleChangeElementProperty = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    let processedValue = value;
    if (event.target.type === 'number') {
      processedValue = processedValue.replace(/[^\d.]/g, '');
      processedValue = processedValue ? parseFloat(processedValue).toString() : '0';
    }
    setErrors({ ...errors, [name]: null });
    setData(data && { ...data, [name]: processedValue });
  };

  const handleOnChangeUsageUnit = (val: string, elm_composition_cd: string) => {
    const elm = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (elm) {
      elm.composition_unit_cd = parseInt(val);
      elm.change_flg = elm.change_flg || !elm.is_add_new;
      setData(data && { ...data, element_compositions: [...data.element_compositions] });
    }
  };

  const handleChangeElementCompositionQuantity = (val: string, elm_composition_cd: string) => {
    const elm = data?.element_compositions.find((e) => e.composition_cd === elm_composition_cd);
    if (elm) {
      let processedValue = val.replace(/[^\d.]/g, '').replace(/(\..*?)\..*/g, '$1');
      processedValue = processedValue ? Math.abs(parseFloat(processedValue)).toString() : '0';
      elm.composition_quantity = parseFloat(processedValue);
      elm.change_flg = elm.change_flg || !elm.is_add_new;
      setData(data && { ...data, element_compositions: [...data.element_compositions] });
    }
  };

  //todo
  const handleAddComposition = (composition: any) => {
    if (composition !== undefined) {
      const compos = {
        id: 0,
        composition_cd: composition.purchase_item_cd ?? composition.process_item_cd ?? composition.element_cd,
        composition_unit_cd:
          'element_cd' in composition ? data?.element_units[0].unit_cd ?? 0 : data?.purchase_units[0].unit_cd ?? 0,
        sort_key: composition.sort_key ?? null,
        effective_start_date: composition.effective_start_date ?? null,
        effective_end_date: composition.effective_end_date ?? null,
        composition_quantity: 0,
        change_flg: composition.change_flag ?? false,
        purchase_item_name: composition.purchase_item_name ?? '',
        process_item_name: composition.process_item_name ?? '',
        supplier_item_cd: composition.supplier_item_cd ?? '',
        element_assoc_name: composition.element_name ?? '',

        purchase_item_unit_cd: composition.purchase_item_unit_cd ?? '',
        purchase_item_sub_unit_cd_1: composition.purchase_supplementary_unit_cd_1 ?? '',
        purchase_item_sub_unit_cd_2: composition.purchase_supplementary_unit_cd_2 ?? '',

        process_item_unit_cd: composition.process_item_unit_cd ?? '',
        process_item_sub_unit_cd_1: composition.process_supplementary_unit_cd_1 ?? '',
        process_item_sub_unit_cd_2: composition.process_supplementary_unit_cd_2 ?? '',

        element_unit_master_assoc: composition.element_unit_master ?? 0,
        supplementary_unit_1_assoc: composition.element_supplementary_unit_1 ?? 0,
        supplementary_unit_2_assoc: composition.element_supplementary_unit_2 ?? 0,

        is_add_new: true,
        is_deleted: false,
      } as ElementCompositionResponse;

      setData(
        data && {
          ...data,
          element_compositions: [compos, ...(data.element_compositions ?? [])],
        }
      );
    }
  };

  const handleChangeSuspendDate = (date: Date) => {
    setSuspendDate(date);
    if (data) {
      setData(data && { ...data, suspend_date: date.toISOString().slice(0, 10) });
    }
  };

  const handleResetFilter = () => {
    setApplicationDate(new Date());
    applicationDateRef.current = new Date();
    fetchData();
  };

  const handleDeleteElementComposition = (composition_cd: string) => {
    setData(
      data && {
        ...data,
        element_compositions: data?.element_compositions
          .map((c) => {
            if (c.composition_cd === composition_cd && !c.is_add_new) {
              return { ...c, is_deleted: true };
            }
            return c;
          })
          .filter((c) => !(c.composition_cd === composition_cd && c.is_add_new)),
      }
    );
  };

  const handleCreateNewElement = async () => {
    if (!data) return;

    const error = handleError();
    if (error) return;
    const requestData = {
      element_cd: data.element_cd,
      element_name: data.element_name,
      business_format_cd: data.business_format_cd,
      remarks: data.remarks,
      element_classification_cd: data.element_classification_cd,
      suspend_date: data.suspend_date,
      element_unit_master: data.element_unit_master,
      supplementary_unit_1: data.supplementary_unit_1,
      supplementary_unit_2: data.supplementary_unit_2,
      supplementary_unit_conversion_rate_1: data.supplementary_unit_conversion_rate_1 ?? 0,
      supplementary_unit_conversion_rate_2: data.supplementary_unit_conversion_rate_2 ?? 0,
      element_compositions: data.element_compositions.map((composition) => {
        return {
          composition_cd: composition.composition_cd,
          composition_quantity: composition.composition_quantity,
          sort_key: composition.sort_key ?? null,
          effective_start_date: composition.effective_start_date,
          effective_end_date: composition.effective_end_date,
          composition_unit_cd: composition.composition_unit_cd,
        };
      }),
    } as ElementConfigurationSettingRequest;
    try {
      const response = await api.create(requestData);
      if (response) {
        history.push(`/calorie-management/parts-configuration-settings/edit/${requestData.element_cd}`);
        toast.success(response.message);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
      const { response } = error as AxiosError<ApiError>;
      const { data: res, status } = response ?? {};
      if (status === 400 && res?.message) {
        toast.error(res.message);
      }
    }
  };

  const handleOpenDialogAddComposition = () => {
    setIsOpenAddCompositionModal(true);
    selectedCompositionsRef.current = data?.element_compositions?.map((c) => c.composition_cd) ?? [];
    if (element_cd !== undefined) {
      selectedCompositionsRef.current.push(element_cd);
    }
  };

  const handleOpenDialogDetailComposition = (composition_cd: string) => {
    currentCompositionsRef.current = composition_cd;
    setIsOpenDetailCompositionModal(true);
  };

  const validateField = (value: any, name: string, rules: any) => {
    let error = null;
    if (rules.required && (value === '' || value === null || value === undefined)) {
      error = `${name} is required`;
    }
    // if (rules.minLength && value.length < rules.minLength) {
    //   error = `${name} must be at least ${rules.minLength} characters`;
    // }
    // if (rules.pattern && !rules.pattern.test(value)) {
    //   error = `${name} must match the pattern ${rules.pattern}`;
    // }
    return error;
  };

  const handleError = () => {
    const elementCodeError = validateField(data?.element_cd ?? '', '部品コード', {
      required: true,
    });
    const elementNameError = validateField(data?.element_name ?? '', '名称', {
      required: true,
    });
    const businessFormatError = validateField(businessFormatRef.current, '業態', {
      required: true,
    });
    const elementClassificationError = validateField(elementClassificationRef.current, '部品分類', {
      required: true,
    });

    setErrors({
      element_cd: elementCodeError,
      element_name: elementNameError,
      business_format: businessFormatError,
      element_classification: elementClassificationError,
    } as ErrorObject);

    return elementCodeError || elementNameError || businessFormatError || elementClassificationError;
  };

  return (
    <>
      <div className="page-content d-flex flex-column">
        {/* header */}
        <div className="pb-3 border-bottom">
          <Row className="align-items-center">
            <Col xs="4">
              <h4 className="mb-0 fw-bold">
                {actionRef.current === 'edit' && t('PartsConfigurationDetail.head_title')}
                {actionRef.current === 'new' && t('PartsConfigurationDetail.head_title_new')}
              </h4>
            </Col>
            <Col xs="8" className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                {actionRef.current === 'edit' && (
                  <GroupButton status={status} lastUpdater={lastUpdater} handleButtonClick={handleApproveButtonClick} />
                )}
                {(actionRef.current === 'new' || actionRef.current === 'copy') && (
                  <Button className="fs-5 fw-bold" onClick={handleCreateNewElement}>
                    {t('PartsConfigurationDetail.register')}
                  </Button>
                )}
                <Button
                  className="fs-5 fw-bold"
                  onClick={() => history.push('/calorie-management/parts-configuration-settings')}
                >
                  {t('PartsConfigurationDetail.go_back')}
                </Button>
              </Stack>
            </Col>
          </Row>
        </div>

        {/* filter */}
        <div className="container-xl mt-3">
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_cd')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <Form.Control
                type="text"
                className="w-25"
                value={data?.element_cd}
                required
                isInvalid={errors.element_cd !== null}
                disabled={true}
                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                name="element_cd"
                onChange={actionRef.current === 'edit' ? () => {} : handleChangeElementProperty}
              />
              {errors.element_cd && <Form.Control.Feedback type="invalid">{errors.element_cd}</Form.Control.Feedback>}
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.business_format')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <CheckBoxBusinessFormatItem
                selectedValue={
                  data?.business_format_cd !== undefined && data?.business_format_cd !== null
                    ? data.business_format_cd.toString()
                    : ''
                }
                readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                id="business_format"
                isInValid={errors.business_format !== null}
                onChange={handleInputCheckboxBusinessFormat}
              />
              {errors.business_format && (
                <Form.Control.Feedback type="invalid">{errors.business_format}</Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_classification')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <ClassificationItemSelect
                value={
                  data?.element_classification_cd !== undefined && data?.element_classification_cd !== null
                    ? data.element_classification_cd.toString()
                    : ''
                }
                onChange={handleInputElementClassification}
                readonly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                id="element_classification"
                isInValid={errors.element_classification !== null}
              />
              {errors.element_classification && (
                <Form.Control.Feedback type="invalid">{errors.element_classification}</Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_name')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <Form.Control
                type="text"
                className="w-25"
                value={data?.element_name}
                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onChange={handleChangeElementProperty}
                name="element_name"
                isInvalid={errors.element_name !== null}
              />
              {errors.element_name && (
                <Form.Control.Feedback type="invalid">{errors.element_name}</Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.suspend_date')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start">
              <div style={{ maxWidth: '120px' }}>
                <ReactDatePicker
                  locale="ja"
                  selected={suspendDate}
                  readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                  onChange={(date: Date) => handleChangeSuspendDate(date)}
                  customInput={<CustomInputDatePicker />}
                  dateFormat="yyyy/MM/dd"
                  name="suspend_date"
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.composition_unit')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Select
                className="form-select"
                aria-label=".form-select-sm"
                name="element_unit_master"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                value={data?.element_unit_master || data?.element_units[0].unit_cd}
                onChange={handleChangeElementProperty}
              >
                {data?.element_units.map((unit, index) => (
                  <option key={index} value={unit.unit_cd}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_sub_unit_1')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Select
                className="form-select"
                aria-label=".form-select-sm"
                name="supplementary_unit_1"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                value={data?.supplementary_unit_1 || data?.element_units[0].unit_cd}
                onChange={handleChangeElementProperty}
              >
                {data?.element_units.map((unit, index) => (
                  <option key={index} value={unit.unit_cd}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.subunit_conversion_rate_1')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Control
                type="number"
                defaultValue={data?.supplementary_unit_conversion_rate_1}
                step={0.01}
                min={0}
                max={9999}
                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onChange={handleChangeElementProperty}
                name="supplementary_unit_conversion_rate_1"
              />
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.element_sub_unit_2')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Select
                className="form-select"
                aria-label=".form-select-sm"
                name="supplementary_unit_2"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                value={data?.supplementary_unit_2 || data?.element_units[0].unit_cd}
                onChange={handleChangeElementProperty}
              >
                {data?.element_units.map((unit, index) => (
                  <option key={index} value={unit.unit_cd}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col className="justify-content-end" xs="auto">
              {t('PartsConfigurationDetail.subunit_conversion_rate_2')}&nbsp;:&nbsp;
            </Col>
            <Col className="justify-content-start" xs="auto">
              <Form.Control
                type="number"
                defaultValue={data?.supplementary_unit_conversion_rate_2}
                step={0.01}
                min={0}
                max={9999}
                readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onChange={handleChangeElementProperty}
                name="supplementary_unit_conversion_rate_2"
              />
            </Col>
          </Row>
          {/* list */}
          <Row className="mb-1 align-items-center">
            <Col>{t('PartsConfigurationDetail.composition')}&nbsp;:&nbsp;</Col>
            <Col className="d-flex align-items-center">
              <span className="text-nowrap">{t('PartsConfigurationDetail.application_date')}&nbsp;:&nbsp;</span>
              <div style={{ maxWidth: '120px', minWidth: '120px' }}>
                <DatePickerCustom
                  name="application_date"
                  value={applicationDate}
                  onChange={(date: Date) => {
                    date && setApplicationDate(date);
                    applicationDateRef.current = date;
                  }}
                />
              </div>
              <Button
                className="btn-primary  ms-2 me-3"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onClick={fetchData}
              >
                {t('PartsConfigurationDetail.filter')}
              </Button>
              <Button
                className="btn-primary  ms-2 me-3"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onClick={handleResetFilter}
              >
                {t('PartsConfigurationDetail.reset')}
              </Button>
            </Col>
            <Col className="align-items-center text-center">
              <Button
                className="btn-primary"
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                onClick={handleOpenDialogAddComposition}
              >
                {t('PartsConfigurationDetail.add')}
              </Button>
            </Col>
          </Row>
          <div className="mb-3 table-component">
            <div className="div-table">
              <div className="div-thead div-tr" style={{ zIndex: '999' }}>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[0]}px` }}
                >
                  {t('PartsConfigurationDetail.table.changed')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[2]}px` }}
                >
                  {t('PartsConfigurationDetail.table.configuration_cd')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[4]}px` }}
                >
                  {t('PartsConfigurationDetail.table.configuration_name')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[3]}px` }}
                >
                  {t('PartsConfigurationDetail.table.usage_amount')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[1]}px` }}
                >
                  {t('PartsConfigurationDetail.table.sort_key')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[1]}px` }}
                >
                  {t('PartsConfigurationDetail.table.application_start_date')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[1]}px` }}
                >
                  {t('PartsConfigurationDetail.table.application_end_date')}
                </div>
                <div
                  className="div-th d-flex align-items-center justify-content-center lh-1"
                  style={{ width: `${COLUMN_WIDTH[1]}px` }}
                >
                  {t('PartsConfigurationDetail.table.delete')}
                </div>
              </div>
              {data &&
                data?.element_compositions &&
                data.element_compositions
                  .filter((elm) => !elm.is_deleted)
                  .map((elm: ElementCompositionResponse, idx) => {
                    return (
                      <div className={`div-tr ${elm.is_add_new ? 'div-tr-selected' : ''}`} key={idx}>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[0]}px` }}
                        >
                          {elm.change_flg ? <span className="text-danger fs-4 lh-1">●</span> : <span></span>}
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[2]}px` }}
                        >
                          <button
                            type="button"
                            onClick={() => handleOpenDialogDetailComposition(elm.composition_cd)}
                            className="btn btn-link p-0"
                            style={{ color: '#3d8bfd' }}
                          >
                            {elm.composition_cd}
                          </button>
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[4]}px` }}
                        >
                          {elm.element_assoc_name || elm.process_item_name || elm.purchase_item_name}
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[3]}px` }}
                        >
                          <Form.Control
                            type="number"
                            min={0}
                            max={999}
                            step={0.1}
                            required
                            readOnly={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                            defaultValue={elm.composition_quantity.toFixed(2)}
                            className="form-control"
                            onChange={(e) => handleChangeElementCompositionQuantity(e.target.value, elm.composition_cd)}
                          />
                          &nbsp;&nbsp;
                          <Form.Select
                            as="select"
                            className="form-select expand-options"
                            aria-label=".form-select-sm example"
                            disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                            value={
                              elm.composition_unit_cd ||
                              (elm.supplier_item_cd
                                ? data.purchase_units[0].unit_cd ?? 0
                                : data.element_units[0].unit_cd ?? 0)
                            }
                            onChange={(e) => handleOnChangeUsageUnit(e.target.value, elm.composition_cd)}
                          >
                            {elm.supplier_item_cd
                              ? data.purchase_units
                                  .filter(
                                    (unit) =>
                                      unit.unit_cd === elm.purchase_item_unit_cd ||
                                      unit.unit_cd === elm.process_item_unit_cd ||
                                      unit.unit_cd === elm.purchase_item_sub_unit_cd_1 ||
                                      unit.unit_cd === elm.purchase_item_sub_unit_cd_2 ||
                                      unit.unit_cd === elm.process_item_sub_unit_cd_1 ||
                                      unit.unit_cd === elm.process_item_sub_unit_cd_2
                                  )
                                  .map((unit) => (
                                    <option key={unit.unit_cd} value={unit.unit_cd}>
                                      {unit.name}
                                    </option>
                                  ))
                              : data.element_units
                                  .filter(
                                    (unit) =>
                                      unit.unit_cd === elm.element_unit_master_assoc ||
                                      unit.unit_cd === elm.supplementary_unit_1_assoc ||
                                      unit.unit_cd === elm.supplementary_unit_2_assoc
                                  )
                                  .map((unit) => (
                                    <option key={unit.unit_cd} value={unit.unit_cd}>
                                      {unit.name}
                                    </option>
                                  ))}
                          </Form.Select>
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[1]}px` }}
                        >
                          {elm.sort_key}
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[1]}px` }}
                        >
                          {elm.effective_start_date?.replace(/-/g, '/')}
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[1]}px` }}
                        >
                          {elm.effective_end_date?.replace(/-/g, '/')}
                        </div>
                        <div
                          className="div-td d-flex align-items-center justify-content-center lh-1"
                          style={{ width: `${COLUMN_WIDTH[1]}px` }}
                        >
                          <Button
                            variant="primary"
                            disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                            onClick={() => handleDeleteElementComposition(elm.composition_cd)}
                          >
                            {t('PartsConfigurationDetail.table.delete')}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="mb-1 fw-bold">{t('CalorieAllergySettingDetail.remark')}</div>
          <div className="row g-0 ">
            <div className="col-md-6 col-sm-12 bg-main p-1 text-center border">
              {t('CalorieAllergySettingDetail.table.remark')}
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-6 col-sm-12 border border-top-0">
              <textarea
                rows={3}
                onChange={handleChangeElementProperty}
                value={data?.remarks}
                disabled={data?.status === PENDING_CONFIRMATION || data?.status === PENDING_APPROVAL}
                className="form-control border-0 textarea-row"
                name="remarks"
              />
            </div>
          </div>
        </div>
      </div>
      {isOpenAddCompositionModal && (
        <SearchCompositionModal
          onClose={() => setIsOpenAddCompositionModal(false)}
          onClickAddComposition={handleAddComposition}
          selectedCompositions={selectedCompositionsRef.current}
        />
      )}
      {isOpenDetailCompositionModal && (
        <ViewDetailCompositionModal
          onClose={() => setIsOpenDetailCompositionModal(false)}
          chosenDetailComposition={currentCompositionsRef.current}
        />
      )}
    </>
  );
}

export default PartsConfigurationDetailSetting;
