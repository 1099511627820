export const statusHeaderTranslationJP = {
  Status_Header: {
    login_information: 'ログイン情報',
    department_name: '所属',
    employee_name: '社員名',
    employee_code: '社員コード',
    company_name: '会社名',
    job_name: '職務',
    return: '戻る',
    logout: 'ログアウト',
    login_time: 'ログイン日時',

    //menu
    calorie_management: 'カロアレ管理',
    feature_A003: '仕入品・加工品カロアレ設定',
    feature_A008: '部品構成設定',
    feature_A010: 'メニュー構成設定',
    feature_A011: '仕入品・部品一括置換',
    feature_A012: 'カロアレ表設定・出力',

    master_management: 'マスタ管理',
    feature_A006: '栄養価マスタ設定',
    feature_A007: '栄養価単位マスタ設定',
    feature_A004: 'アレルゲンマスタ設定',
    feature_A005: 'アレルゲン選択項目マスタ設定',
    feature_A009: 'メニューカテゴリマスタ設定',
    feature_A013: '部品分類マスタ設定',

    // business_type: '業態共通',
    // bar_guide_list: 'バー案内一覧',
    // group_store: '店舗関連',
    // menu_store_setting: '店舗設定一覧',
    // group_sale_type: '販売タイプ関連',
    // menu_sale_type_list: '販売タイプ一覧',
    // eating_way_list: '食べ方一覧',
    // group_menu_book: 'メニューブック関連',
    // menu_menu_book_list: 'メニューブック一覧',
    // menu_genre_list: 'ジャンル一覧',
    // menu_subgenre_list: 'サブジャンル一覧',
    // menu_menu_list: 'メニュー一覧',
    // subtitle_menu_list: 'サブタイトルメニュー一覧',
    // menu_group_option_list: 'サブメニューグループ一覧',
    // group_delivery: '配信関連',
    // menu_delivery_setting: '配信設定／状況一覧',
    // group_data: 'データ取込',
    // menu_import_register_data: 'レジデータ取込',
  },
};
