import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiError, ApiResponse } from 'models';

interface DataError {
  data: { [key: string]: string[] };
}

type Success = 'success';
type Error = 'error';

export interface errorNotificationState {
  isOpen: boolean;
  error: DataError | undefined;
  message: string | undefined;
  status: Success | Error | undefined;
  status_code: number | undefined;
}

const initialState: errorNotificationState = {
  isOpen: false,
  error: undefined,
  message: '',
  status: 'success',
  status_code: undefined,
};

const errorNotificationSlice = createSlice({
  name: 'error_notification',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<AxiosError<ApiError>>) {
      // if (ERROR_NOTIFICATION_CODES.includes(Number(action.payload.response?.status))) {
      state.isOpen = true;
      state.error = action.payload.response?.data.error as DataError;
      state.message = action.payload.message;
      state.status = 'error';
      state.status_code = action.payload.response?.status;
      // }
    },
    hideError(state) {
      state.isOpen = false;
      state.error = undefined;
      state.message = undefined;
      state.status = undefined;
      state.status_code = undefined;
    },

    setMessage(state, action: PayloadAction<AxiosResponse<ApiResponse>>) {
      state.isOpen = true;
      state.message = action.payload?.data?.message;
      state.status = 'success';
      state.status_code = action.payload?.status;
    },
    clearMessage(state) {
      state.isOpen = false;
      state.message = undefined;
      state.status = undefined;
      state.status_code = undefined;
    },
  },
});

// Actions
export const errorNotificationActions = errorNotificationSlice.actions;

export const { clearMessage, hideError } = errorNotificationSlice.actions;

// Selectors
export const selectNotificationIsOpen = (state: RootState) => state.errorNotification.isOpen;
export const selectNotificationError = (state: RootState) => state.errorNotification.error;
export const selectNotificationMessage = (state: RootState) => state.errorNotification.message;
export const selectNotificationStatus = (state: RootState) => state.errorNotification.status;

// Reducer
export const errorNotificationReducer = errorNotificationSlice.reducer;
