import { forwardRef } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
export const InputFormDatePicker = forwardRef((props: any, ref: any) => {
  // You can style this further
  return (
    <>
      <div className="position-relative">
        <FaCalendarAlt style={{ position: 'absolute', top: '0.625rem', left: '0.625rem' }} onClick={props.onClick} />
        <input
          disabled={props.disabled}
          className="form-control"
          onClick={props.onClick}
          style={{ paddingLeft: '32px' }}
          // defaultValue={props.value}
          value={props.value}
          type="text"
        />
      </div>
    </>
  );
});
