export const elementClassificationMasterTranslationJP = {
  ElementClassificationMaster: {
    head_title: '部品分類マスタメンテナンス',
    update: '更新',
    register: '新規登録',
    delete: '削除',
    element_classification_name: '名称',
    display_order: '表示順',
    add: '登録',
    warn_field_required: 'このフィールドは必須です',
    warn_number_required: '０以上の数値のみ入力可',
  },
};
