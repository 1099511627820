import { remainWidthAfterSelection } from 'features/PDF/components/api/api-header';
import {
  PDF_COL_STYLE_TABLE_BY_TYPE,
  PDF_COLOR,
  PDF_HIDDEN_BRIGHT,
  PDF_SELECT_BRIGHT,
} from 'features/PDF/constants/style';
import '../../style.scss';

const CategoryRow = ({ styles, record, isSelected, onChangeCategoryTitle, isAllowedEdit }: any) => {
  return (
    <div style={{ ...styles.row }}>
      {/* Select temp col */}
      <div
        style={{
          ...styles.col,
          ...styles.colApprove,
          padding: 0,
          backgroundColor: 'white',
          width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
          ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
          ...(isSelected ? { filter: `brightness(${PDF_SELECT_BRIGHT}%)` } : {}),
        }}
      ></div>
      <div
        style={{
          ...styles.col,
          ...styles.colConfirm,
          padding: 0,
          backgroundColor: 'white',
          width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
          ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
          ...(isSelected ? { filter: `brightness(${PDF_SELECT_BRIGHT}%)` } : {}),
        }}
      ></div>

      {/* Check box */}
      <div
        style={{
          ...styles.col,
          ...styles.colCategory,
          ...{
            backgroundColor: PDF_COLOR['background'][record.style.backgroundColor],
          },
          ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
          ...(isSelected ? { filter: `brightness(${PDF_SELECT_BRIGHT}%)` } : {}),
          width: remainWidthAfterSelection() + '%',
        }}
      >
        <input
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="chart_text"
          type="text"
          value={record.title}
          onChange={(e: any) => onChangeCategoryTitle(e, record)}
          disabled={!isAllowedEdit}
          style={{
            backgroundColor: PDF_COLOR['background'][record.style.backgroundColor],
            border: 'none',
            borderRight: 'solid 1px black',
            width: '50%',
          }}
        />
      </div>
    </div>
  );
};

export { CategoryRow };
