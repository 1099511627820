import { Text, View } from '@react-pdf/renderer';
import { PDF_COLOR } from 'features/PDF/constants/style';

export const YellowFrame = () => {
  const ALLERGENS_LEFT_MARGIN = 16;
  const INFO_BACKGROUD_COLOR = PDF_COLOR['background']['yellow'];

  return (
    <View style={{ marginBottom: 2, width: '80%' }}>
      <View style={{ backgroundColor: INFO_BACKGROUD_COLOR, padding: 1 }}>
        <Text
          style={{
            fontWeight: 'black',
            marginLeft: ALLERGENS_LEFT_MARGIN,
            marginBottom: 3,
          }}
        >
          〈小麦アレルゲン由来原料〉
        </Text>
        <Text
          style={{
            fontWeight: 'black',
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            color: PDF_COLOR['text']['red'],
            marginBottom: 3,
          }}
        >
          小麦⇒小麦製品を由来原料とするもの(小麦たんぱく加水分解物を含む）
        </Text>
        <Text
          style={{
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            marginBottom: 3,
          }}
        >
          調味料⇒醤油、味噌などを由来原料とするもの
        </Text>
      </View>
      <View style={{ backgroundColor: INFO_BACKGROUD_COLOR, padding: 1 }}>
        <Text
          style={{
            fontWeight: 'black',
            marginLeft: ALLERGENS_LEFT_MARGIN,
            marginBottom: 3,
          }}
        >
          〈小麦アレルゲン由来原料〉
        </Text>
        <Text
          style={{
            fontWeight: 'black',
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            color: PDF_COLOR['text']['red'],
            marginBottom: 3,
          }}
        >
          小麦⇒小麦製品を由来原料とするもの(小麦たんぱく加水分解物を含む）
        </Text>
        <Text
          style={{
            marginLeft: ALLERGENS_LEFT_MARGIN * 2,
            marginBottom: 2,
          }}
        >
          調味料⇒醤油、味噌などを由来原料とするもの
        </Text>
      </View>
    </View>
  );
};
