import { PrivilegeRole, PrivilegeStatus } from 'components/formfields/Privilege/models/privilege';
import ApproveGroupButton from 'features/ApproveGroupButton';
import { ApproveButtonResource } from 'components/formfields/Privilege/models/viewApproveButtonprivilege';
import { MouseEvent, useState } from 'react';

const STATUS = ['none', 'new', 'wait_for_confirmed', 'wait_for_approval', 'admitted'];

const ROLE = ['normal', 'approval'];

function DemoPrivilege() {
  const [show, setShow] = useState('');

  const [curUserId, setCurUserId] = useState<string>('12');
  const [confirmUserId, setConfirmUserId] = useState<string>('11');
  const [status, setStatus] = useState<PrivilegeStatus>('none');
  const [role, setRole] = useState<PrivilegeRole>('normal');

  const hanldeClick = (resource: ApproveButtonResource, event: MouseEvent<HTMLElement>) => {
    if (resource === 'confirmation_request_btn') {
      setShow('confirm request press');
    }
    if (resource === 'approval_request_btn') {
      setShow('approve request press');
    }
    if (resource === 'approval_btn') {
      setShow('approve press');
    }
    if (resource === 'turn_back_btn') {
      setShow('turn back press');
    }
    if (resource === 'confirmation_cancel_btn') {
      setShow('confirm cancel press');
    }
  };

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      <div>
        curUserId:
        <input type="text" onChange={(event: any) => setCurUserId(event.target.value)} value={curUserId} />
      </div>

      <div>
        confirmUserId:
        <input type="text" onChange={(event: any) => setConfirmUserId(event.target.value)} value={confirmUserId} />
      </div>

      <div>
        role:
        <select name="" id="" onChange={(event: any) => setRole(event.target.value)} value={role}>
          {ROLE.map((role, idx) => (
            <option key={idx} value={role}>
              {role}
            </option>
          ))}
        </select>
      </div>

      <div>
        status:
        <select name="" id="" onChange={(event: any) => setStatus(event.target.value)} value={status}>
          {STATUS.map((status, idx) => (
            <option key={idx} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>

      <div style={{ color: 'red' }}>action: {show}</div>
      <div>
        button:
        <ApproveGroupButton
          status={status}
          // role={role}
          // curUserId={curUserId}
          confirmUserId={confirmUserId}
          onClick={hanldeClick}
        />
      </div>
    </div>
  );
}

export default DemoPrivilege;
