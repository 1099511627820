import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ApiResponse, NutritionUnitMaster } from 'models';

export interface NutritionUnitMasterState {
  loading: boolean;
  data: NutritionUnitMaster[];
}

const initialState: NutritionUnitMasterState = {
  loading: false,
  data: [],
};

const nutritionUnitMasterSlice = createSlice({
  name: 'NutritionUnitMaster',
  initialState,
  reducers: {
    fetchNutritionUnitMasterList(state) {
      state.loading = true;
    },
    fetchNutritionUnitMasterListSuccess(state, action: PayloadAction<ApiResponse<NutritionUnitMaster[]>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchNutritionUnitMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const nutritionUnitMasterActions = nutritionUnitMasterSlice.actions;

// Selectors
export const selectNutritionUnitMasterData = (state: RootState) => state.nutritionUnitMaster.data;

// Reducer
export const nutritionUnitMasterReducer = nutritionUnitMasterSlice.reducer;
