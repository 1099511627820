const PRE_DETAIL_COMPOSITION_TABLE_HEADER = [
  {
    title: '部品コード',
    width: 15,
  },
  {
    title: '名称',
    width: 20,
  },
];

const POST_DETAIL_COMPOSITION_TABLE_HEADER = [
  {
    title: '特定原材料',
    width: 60,
  },
  {
    title: '特定原材料に準ずる品目',
    width: 60,
  },
];

export { PRE_DETAIL_COMPOSITION_TABLE_HEADER, POST_DETAIL_COMPOSITION_TABLE_HEADER };
