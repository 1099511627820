import { StyleSheet, Text } from '@react-pdf/renderer';
import { PDF_FONT_SIZE } from '../../constants/style';

const PageNumber = () => {
  const styles = StyleSheet.create({
    pageNumber: {
      position: 'absolute',
      fontSize: PDF_FONT_SIZE + 1,
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: 'center',
      fontWeight: 'extralight',
    },
  });
  return (
    <Text
      fixed
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages} ページ`}
    />
  );
};

export { PageNumber };
