interface TableStyleByTypeInterface {
  width: number | null;
  verticalHeader?: boolean;
  manualHeader?: boolean;
  backgroundColor?: string;
}
export interface ColTableStyleByTypeInterFace {
  select: TableStyleByTypeInterface;
  text: TableStyleByTypeInterface;
  number: TableStyleByTypeInterface;
  icon_mark: TableStyleByTypeInterface;
  text_mark:
    | TableStyleByTypeInterface & {
        specialTextStyle: {
          color: string;
          backgroundColor: string;
        };
        boldTextStyle: {
          fontWeight: 'black';
        };
      };
}

const PDF_LETTET_BLANK: number = 5;
const PDF_FONT_SIZE: number = 5;
const PDF_LETTER_SPACING: number = 0;
const PDF_COL_HEIGHT: number = 12;
const PDF_SELECT_BRIGHT: number = 90;
const PDF_SELECT_BACKGOUND_COLOR: string = '#fffddc';
const PDF_HIDDEN_BRIGHT: number = 30;
const PDF_SUSPEND_BRIGHT: number = 60;

const PDF_COLOR: { [key: string]: any } = {
  text: {
    red: '#ff0800',
  },
  background: {
    red: '#ff9acd',
    green: '#cdffcd',
    blue: '#9acdff',
    yellow: '#ffff00',
    lightOrange: '#ffcd00',
    orange: '#ff9a00',
    white: 'white',
  },
};

const PDF_COL_STYLE_TABLE_BY_TYPE: any = {
  select: {
    width: 6,
  },
  text: {
    width: 24,
  },
  number: {
    width: 3,
    verticalHeader: true,
  },
  icon_mark: {
    width: null, // devide from the other
    verticalHeader: true,
  },
  text_mark: {
    width: 6,
    manualHeader: true,
    fontWeight: 'black',
    specialTextStyle: {
      color: PDF_COLOR['text']['red'],
      backgroundColor: PDF_COLOR['background']['yellow'],
    },
  },
};

const PDF_TEXT_MARK_SPECIAL_VALUES: string[] = ['いり胡麻', '小麦', 'ごま'];
const PDF_TEXT_MARK_BOLD_VALUES: string[] = [...PDF_TEXT_MARK_SPECIAL_VALUES, '調味料', 'ごま油'];

const PDF_BREAK_TITLES = [
  {
    value: '※小麦由来原料',
    breakPosition: [3],
  },
  {
    value: '※ごま由来原料',
    breakPosition: [3],
  },
];

export {
  PDF_LETTET_BLANK,
  PDF_FONT_SIZE,
  PDF_COL_STYLE_TABLE_BY_TYPE,
  PDF_TEXT_MARK_SPECIAL_VALUES,
  PDF_TEXT_MARK_BOLD_VALUES,
  PDF_COLOR,
  PDF_COL_HEIGHT,
  PDF_LETTER_SPACING,
  PDF_BREAK_TITLES,
  PDF_SELECT_BRIGHT,
  PDF_HIDDEN_BRIGHT,
  PDF_SELECT_BACKGOUND_COLOR,
  PDF_SUSPEND_BRIGHT,
};
