import { Privilege } from '../models/privilege';

// permission func
const isNotSameUser: (curUserId: string, confirmUserId: string) => boolean = (curUserId, confirmedUserId) => {
  return curUserId !== confirmedUserId;
};

// privilege has the highest fineness, if any values are in array, split them into each row
const PRIVILEGE: Privilege = {
  viewApproveButton: [
    {
      resource: 'confirmation_request_btn',
      role: 'normal',
      status: 'new',
    },
    {
      resource: 'confirmation_request_btn',
      role: 'normal',
      status: 'none',
    },
    {
      resource: 'approval_request_btn',
      role: 'normal',
      status: 'wait_for_confirmed',
      isNotSameUser: isNotSameUser,
    },
    {
      resource: 'confirmation_cancel_btn',
      role: 'normal',
      status: 'wait_for_confirmed',
      isNotSameUser: isNotSameUser,
    },
    {
      resource: 'confirmation_cancel_btn',
      role: 'approval',
      status: 'wait_for_confirmed',
    },
    {
      resource: 'confirmation_request_btn',
      role: 'approval',
      status: 'new',
    },
    {
      resource: 'confirmation_request_btn',
      role: 'approval',
      status: 'none',
    },
    {
      resource: 'approval_request_btn',
      role: 'approval',
      status: 'wait_for_confirmed',
    },
    {
      resource: 'approval_btn',
      role: 'approval',
      status: 'wait_for_approval',
    },
    {
      resource: 'turn_back_btn',
      role: 'approval',
      status: 'wait_for_approval',
    },
  ],
  editFormFields: [
    {
      role: 'normal',
      status: 'new',
    },
    {
      role: 'normal',
      status: 'none',
    },
    {
      role: 'approval',
      status: 'new',
    },
    {
      role: 'approval',
      status: 'none',
    },
  ],
};

export { PRIVILEGE };
