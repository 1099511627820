import { yupResolver } from '@hookform/resolvers/yup';
import { AllergyMaster } from 'models/masterdata';
import { RefObject, memo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AllergyMasterListFormValues } from '..';
import { ALLERGY_MASTER_TYPE_SELECTSOURCE } from '../constants';

const COLUMN_WIDTH = [50, 220, 220, 130, 100];

export interface AllergyMasterListProps {
  formRef: RefObject<HTMLFormElement>;
  data: AllergyMaster[];
  onSubmitForm?: (formValues: AllergyMasterListFormValues) => void;
}

function AllergyMasterList({ formRef, data, onSubmitForm }: AllergyMasterListProps) {
  const { t } = useTranslation();

  // schema validation
  const schema = yup.object({
    data: yup
      .array(
        yup.object({
          allergy_cd: yup.number().required(),
          type: yup.number().required(),
          allergy_name: yup.string().required(t('AllergyMaster.warn_field_required')),
          display_order: yup
            .number()
            .transform((value, originalValue) => {
              if (typeof originalValue === 'string' && originalValue.trim() === '') {
                return undefined;
              }
              return value;
            })
            .min(1, t('AllergyMaster.warn_number_required'))
            .integer(t('AllergyMaster.warn_number_required'))
            .required(t('AllergyMaster.warn_number_required')),
          display_flg: yup.boolean().required(),
          deleted: yup.boolean().required(),
        })
      )
      .required(),
  });

  // form setup
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AllergyMasterListFormValues>({
    resolver: yupResolver(schema),
    defaultValues: { data: data },
    mode: 'onSubmit',
  });
  const { fields } = useFieldArray({
    name: 'data',
    control,
    // keyName: 'key',
  });

  // side effects
  useEffect(() => {
    reset({
      data: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //events
  function onSubmit(formValues: AllergyMasterListFormValues) {
    onSubmitForm?.(formValues);
  }

  return (
    <div className="sticky-table flex-grow-1 flex-shrink-1">
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="div-table fixsize-column">
          <div className="div-thead div-tr">
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
              {t('AllergyMaster.delete')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
              {t('AllergyMaster.type')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
              {t('AllergyMaster.name')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
              {t('AllergyMaster.display_order')}
            </div>
            <div className="div-th" style={{ width: `${COLUMN_WIDTH[4]}px` }}>
              {t('AllergyMaster.display_flg')}
            </div>
          </div>

          {fields.map((field, index) => {
            return (
              <div className="div-tr" key={field.allergy_cd}>
                <div
                  className="div-td d-flex align-items-center justify-content-center"
                  style={{ width: `${COLUMN_WIDTH[0]}px` }}
                >
                  <Form.Check
                    {...register(`data.${index}.deleted`)}
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                  />
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                  <Form.Select {...register(`data.${index}.type`)}>
                    {ALLERGY_MASTER_TYPE_SELECTSOURCE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.allergy_name`)}
                    type="text"
                    placeholder=""
                    isInvalid={!!errors?.data?.[index]?.allergy_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.allergy_name?.message}
                  </Form.Control.Feedback>
                </div>
                <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                  <Form.Control
                    {...register(`data.${index}.display_order`)}
                    className="text-end"
                    type="number"
                    placeholder=""
                    min={1}
                    isInvalid={!!errors?.data?.[index]?.display_order}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.data?.[index]?.display_order?.message}
                  </Form.Control.Feedback>
                </div>
                <div
                  className="div-td d-flex align-items-center justify-content-center"
                  style={{ width: `${COLUMN_WIDTH[4]}px` }}
                >
                  <Form.Check
                    {...register(`data.${index}.display_flg`)}
                    className="anra-checkbox d-flex justify-content-center"
                    label=""
                    type="checkbox"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
}

export default memo(AllergyMasterList);
