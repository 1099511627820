// import { MenuBookCourseBase } from './models/MenuBookCourseBase';
import { MultiSelectToArg, MutilDragAwareArg, ReorderResult } from './types';

export function reorder(list: Array<any>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const reorderSingleDrag = ({ data, selectedItemIds, source, destination }: MutilDragAwareArg): ReorderResult => {
  const shallow = [...data];
  let reorderData = shallow;

  reorderData = reorder(shallow, source.index, destination.index);

  return {
    data: reorderData,
    selectedItemIds,
  };
};

const reorderMultiDrag = ({ data, selectedItemIds, source, destination }: MutilDragAwareArg): ReorderResult => {
  const sourceList = data;
  const draggedItem = sourceList[source.index];
  const dragged = draggedItem.id;

  const insertAtIndex: number = (() => {
    const destinationIndexOffset: number = selectedItemIds.reduce((previous: number, current: string): number => {
      if (current === dragged) {
        return previous;
      }

      const index = sourceList.findIndex((item) => item.id === current);

      if (index >= destination.index) {
        return previous;
      }

      // the selected item is before the destination index
      // we need to account for this when inserting into the new location
      return previous + 1;
    }, 0);

    const result: number = destination.index - destinationIndexOffset;
    return result;
  })();

  // doing the ordering now as we are required to look up columns
  // and know original ordering
  const orderedSelectedItemIds = [...selectedItemIds];

  const remainings = sourceList.filter((item) => !selectedItemIds.includes(item.id));
  const base = [...remainings];
  const orderedSelectedItem = sourceList.filter((item) => selectedItemIds.includes(item.id));
  base.splice(insertAtIndex, 0, ...orderedSelectedItem);

  const updated = base;

  return {
    data: updated,
    selectedItemIds: orderedSelectedItemIds,
  };
};

export const mutliDragAwareReorder = (args: MutilDragAwareArg): ReorderResult => {
  if (args.selectedItemIds.length > 1) {
    return reorderMultiDrag(args);
  }

  return reorderSingleDrag(args);
};

export const multiSelectTo = ({ data, selectedItemIds }: MultiSelectToArg, newItemId: string): string[] | null => {
  const sourceList = data;

  // Nothing already selected
  if (!selectedItemIds.length) {
    return [newItemId];
  }

  const indexOfNew = sourceList.findIndex((item) => item.id === newItemId);
  const lastSelected = selectedItemIds[selectedItemIds.length - 1];
  const indexOfLast = sourceList.findIndex((item) => item.id === lastSelected);

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null;
  }

  const isSelectingForwards: boolean = indexOfNew > indexOfLast;
  const start: number = isSelectingForwards ? indexOfLast : indexOfNew;
  const end: number = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetweenItems = sourceList.slice(start, end + 1);
  const inBetween = inBetweenItems.map((item) => item.id);

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected
  const toAdd = inBetween.filter((id: string): boolean => {
    // if already selected: then no need to select it again
    if (selectedItemIds.includes(id)) {
      return false;
    }
    return true;
  });

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedItemIds, ...sorted];

  return combined;
};

// Determines if the platform specific toggle selection in group key was used
export function wasToggleInSelectionGroupKeyUsed(
  event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
) {
  const platform = (navigator as any).userAgentData?.platform;
  const isUsingWindows = platform && (platform.indexOf('Win') >= 0 || platform.indexOf('Linux') >= 0);
  return isUsingWindows ? event.ctrlKey : event.metaKey;
}

// Determines if the multiSelect key was used
export function wasMultiSelectKeyUsed(event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) {
  return event.shiftKey;
}
