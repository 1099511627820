import { AxiosError } from 'axios';
import { ApiError, NutritionUnitMasterAddPayload, NutritionUnitMasterUpdatePayload } from 'models';
import nutritionUnitMasterService from '../nutritionUnitMasterService';

function useNutritionUnitMaster() {
  async function addNutritionUnitMaster(payload: NutritionUnitMasterAddPayload) {
    try {
      const response = await nutritionUnitMasterService.add(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  async function updateNutritionUnitMaster(payload: NutritionUnitMasterUpdatePayload[]) {
    try {
      const response = await nutritionUnitMasterService.update(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  return { addNutritionUnitMaster, updateNutritionUnitMaster };
}

export default useNutritionUnitMaster;
