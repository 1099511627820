import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AlertModal from '../AlertModal';
import useGlobalModal from '../GlobalModal/useGlobalModal';
import {
  errorNotificationActions,
  selectNotificationIsOpen,
  selectNotificationMessage,
  selectNotificationStatus,
} from './errorNotificationSlice';

function ErrorNotification() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectNotificationIsOpen);
  const status = useAppSelector(selectNotificationStatus);
  const message = useAppSelector(selectNotificationMessage);
  const { showGlobalModal } = useGlobalModal();

  function handleClose() {
    dispatch(errorNotificationActions.hideError());
  }

  useEffect(() => {
    if (isOpen && status === 'error') {
      showGlobalModal(() => <AlertModal title={t('App.warning')} message={message ?? ''} onClose={handleClose} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, status]);

  return <></>;
}

export default ErrorNotification;
