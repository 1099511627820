import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import nutritionUnitMasterService from 'features/MasterDataManagement/NutritionUnitMaster/nutritionUnitMasterService';
import { ApiResponse, NutritionUnitMaster } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { nutritionUnitMasterActions } from './nutritionUnitMasterSlice';

function* fetchNutritionUnitMasterList() {
  try {
    const response: ApiResponse<NutritionUnitMaster[]> = yield call(nutritionUnitMasterService.getAll);
    yield put(nutritionUnitMasterActions.fetchNutritionUnitMasterListSuccess(response));
  } catch (e) {
    yield put(nutritionUnitMasterActions.fetchNutritionUnitMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* nutritionUnitMasterSaga() {
  yield takeLatest(nutritionUnitMasterActions.fetchNutritionUnitMasterList.type, fetchNutritionUnitMasterList);
}
