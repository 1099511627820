import axiosClient from 'utils/axiosClient';

const ROOT_API_ROUTE = 'composition-detail';

const CompositionDetailService = {
  get(payload: any): Promise<any> {
    const search = `?search=${payload.searchText}`;
    const url = `${ROOT_API_ROUTE}${search}`;
    return axiosClient.get(url);
  },
};

export default CompositionDetailService;
