import { Button, Stack } from 'react-bootstrap';
import {
  ApproveButtonResource,
  ViewApproveButtonPrivilege,
} from '../../components/formfields/Privilege/models/viewApproveButtonprivilege';
import { useTranslation } from 'react-i18next';
import { CSSProperties, MouseEvent } from 'react';
import { PrivilegeStatus } from 'components/formfields/Privilege/models/privilege';
import { PRIVILEGE } from 'components/formfields/Privilege/components/privilege';
import { AuthorityLevels, SYSTEM_ID } from 'features/CalorieManagement/PurchaseProcessedCalorieAllergySetting/constant';
import { useSelector } from 'react-redux';
import { selectUserInfo, selectUserSelectedJob } from 'features/Auth/authSlice';

interface ApproveGroupButtonProps {
  status: PrivilegeStatus;
  confirmUserId?: string;
  onClick?: (resource: ApproveButtonResource, event: MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
}

const ApproveGroupButton = ({ status, confirmUserId, onClick, style }: ApproveGroupButtonProps) => {
  const selectedJob = useSelector(selectUserSelectedJob);
  const user = useSelector(selectUserInfo);
  const curUserId = user?.employee_cd;
  const level = Number(selectedJob?.levels[SYSTEM_ID] ?? '0') || 0;
  let role = '';

  if (level < AuthorityLevels.APPROVAL) {
    role = 'normal';
  }
  if (level >= AuthorityLevels.APPROVAL) {
    role = 'approval';
  }

  const { t } = useTranslation();
  const VIEW_PRIVILEGE = PRIVILEGE['viewApproveButton'];
  let acceptedResources: ApproveButtonResource[] = [];

  VIEW_PRIVILEGE.forEach((permissionObj: ViewApproveButtonPrivilege) => {
    // isNotSameUser doesn't exist -> true, isNotSameUser exist and isFunction -> call function -> true/false
    const checkNotSameUser =
      !permissionObj?.isNotSameUser ||
      (typeof permissionObj.isNotSameUser === 'function' && permissionObj.isNotSameUser(curUserId, confirmUserId));
    const checkRole = permissionObj.role === role;
    const checkStatus = permissionObj.status === status;

    if (checkRole && checkStatus && checkNotSameUser) {
      acceptedResources = [...acceptedResources, permissionObj.resource];
    }
  });

  const handleOnClick = (resource: ApproveButtonResource, event: MouseEvent<HTMLElement>) => {
    if (typeof onClick === 'function') {
      onClick(resource, event);
    }
  };

  return (
    <Stack direction="horizontal" gap={4} style={style}>
      {acceptedResources.map((resource, idx) => {
        return (
          <Button key={idx} onClick={(event) => handleOnClick(resource, event)}>
            {t(`button.${resource}`)}
          </Button>
        );
      })}
    </Stack>
  );
};

export default ApproveGroupButton;
