import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { LiaTimesSolid } from 'react-icons/lia';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  businessFormatMasterActions,
  selectBusinessFormatMasterData,
} from 'store/BusinessFormatMaster/businessFormatMasterSlice';
import { useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AllergyCaloryChartService from 'features/CalorieManagement/CalorieAllergySetting/allergyCalorieSettingService';
import { CHART_TEMLATES } from '../../Template';

interface ReplaceItemModalProps {
  onClose: () => void;
}

export interface ReplaceItemModalForm {
  business: string;
  template: string;
  name: string;
}

const AddAllergyChartModal = ({ onClose }: ReplaceItemModalProps) => {
  const { t } = useTranslation();
  const businessFormatMasterList = useAppSelector(selectBusinessFormatMasterData);
  const dispatch = useDispatch();

  const schema = yup.object({
    business: yup.string().required(t('CalorieManagementSettingScreen.warn_field_required')),
    name: yup.string().required(t('CalorieManagementSettingScreen.warn_field_required')),
    template: yup.string().required(t('CalorieManagementSettingScreen.warn_field_required')),
  });

  useEffect(() => {
    dispatch(businessFormatMasterActions.fetchBusinessFormatMasterList());
  }, []);

  // react-hook-form setup
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ReplaceItemModalForm>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const onSubmit = async (data: any) => {
    try {
      const mappedData = {
        title: data.name,
        business_format_master: data.business,
        template: data.template,
      };
      const res = await AllergyCaloryChartService.create(mappedData);
      toast.success(res.message);
      onClose();
    } catch (error: any) {
      const message = error.response.data.message;
      toast.error(message);
    }
  };

  return (
    <Modal show size="lg" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Row className="align-items-center mb-3 gap-4">
              {/* Search filter  */}
              <Row>
                <Col xs="auto">
                  <Row className="align-items-center">
                    <Col xs="auto"></Col>
                    <Col xs="auto"></Col>
                  </Row>
                </Col>
              </Row>

              <Form.Group as={Row} className="mb-3" controlId="combobox">
                <Form.Label column sm="3" className="text-end">
                  {t('CalorieManagementSettingScreen.business')}
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    isInvalid={Boolean(errors?.business)}
                    style={{ width: '200px' }}
                    {...register(`business`)}
                  >
                    {businessFormatMasterList.map((option, idx) => {
                      return (
                        <option key={idx} value={option.business_format_cd}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors?.business && (
                    <span className="small text-danger d-inline-block mt-1">{errors?.business?.message}</span>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="textbox">
                <Form.Label column sm="3" className="text-end">
                  {t('MenuStructureSettingScreen.name')}
                </Form.Label>
                <Col sm="8">
                  <Form.Control {...register('name')} type="text" isInvalid={Boolean(errors?.name?.message)} />
                  {errors?.name && (
                    <span className="small text-danger d-inline-block mt-1">{errors?.name?.message}</span>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="combobox">
                <Form.Label column sm="3" className="text-end">
                  {t('CalorieManagementSettingScreen.template')}
                </Form.Label>
                <Col sm="8">
                  <Form.Select
                    isInvalid={Boolean(errors?.template)}
                    style={{ width: '300px' }}
                    {...register(`template`)}
                  >
                    {CHART_TEMLATES.map((option, idx) => {
                      return (
                        <option key={idx} value={option.value}>
                          {option.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors?.template && (
                    <span className="small text-danger d-inline-block mt-1">{errors?.template?.message}</span>
                  )}
                </Col>
              </Form.Group>
            </Row>
          </div>

          <div className="d-flex justify-content-center" style={{ marginTop: '12px' }}>
            <Button type="submit">{t('CalorieManagementSettingScreen.register')}</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAllergyChartModal;
