import { PayloadAction } from '@reduxjs/toolkit';
import { handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { AllergyMasterFilterValues } from 'features/MasterDataManagement/AllergyMaster';
import allergyMasterService from 'features/MasterDataManagement/AllergyMaster/allergyMasterService';
import { AllergyMaster, ApiResponse } from 'models';
import { call, put, takeLatest } from 'redux-saga/effects';
import { allergyMasterActions } from './allergyMasterSlice';

function* fetchAllergyMasterList(action: PayloadAction<AllergyMasterFilterValues>) {
  try {
    const response: ApiResponse<AllergyMaster[]> = yield call(allergyMasterService.getAll, action.payload);
    yield put(allergyMasterActions.fetchAllergyMasterListSuccess(response));
  } catch (e) {
    yield put(allergyMasterActions.fetchAllergyMasterListFailed());
    yield handleResponseError(e as AxiosError);
  }
}

export default function* allergyMasterSaga() {
  yield takeLatest(allergyMasterActions.fetchAllergyMasterList.type, fetchAllergyMasterList);
}
