import axiosClient from 'utils/axiosClient';
import { ElementSearch, ProcessItemSearch, PurchaseItemSearch } from './models/compositionSearchModel';
import { ApiResponse } from 'models';

const ROOT_API_ROUTE = 'composition-search';

const CompositionSearchService = {
  getAll(payload: any): Promise<ApiResponse<Array<PurchaseItemSearch | ProcessItemSearch | ElementSearch>>> {
    const searchText = `?search_text=${payload.searchText}`;
    let url = `${ROOT_API_ROUTE}${searchText}`;
    return axiosClient.get(url);
  },
};

export default CompositionSearchService;
