import { Button, Col, Form, Row, Stack, Table } from 'react-bootstrap';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';

const COLUMN_WIDTH = [50, 200, 200, 150];
const totalWidth = COLUMN_WIDTH.reduce((sum, size) => (sum += size), 0);

function DemoTraditionalListPage() {
  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">Demo Traditional Table List</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button>更新</Button>
              <Button>新規登録</Button>
              <Button>戻る</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* filter */}
      <div className="py-3">
        <Row className="align-items-center gap-4">
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="全て"
                  name="group1"
                  type="radio"
                  defaultChecked
                  id="group1-item-1"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="特定原材料のみ"
                  name="group1"
                  type="radio"
                  id="group1-item-2"
                />
                <Form.Check
                  className="anra-checkbox"
                  inline
                  label="特定原材料に準ずる品目のみ"
                  name="group1"
                  type="radio"
                  id="group1-item-3"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* Pagination */}
      <div className="py-2">
        <div className="anra-pagination d-inline-flex flex-row gap-1 align-items-center">
          <Button size="sm" variant="primary" className="btn--auto me-1">
            <TfiAngleLeft size={16} />
          </Button>
          <Form.Control
            size="sm"
            type="text"
            defaultValue="1"
            className="d-inline-block text-center"
            style={{ width: '5rem' }}
          />
          <span>/20ページ</span>
          <Button size="sm" variant="primary" className="btn--auto ms-1">
            <TfiAngleRight size={16} />
          </Button>
        </div>
      </div>

      {/* list */}
      <div className="sticky-table flex-grow-1 flex-shrink-1">
        <div style={{ width: `${totalWidth}px` }}>
          <Table>
            <thead>
              <tr>
                <th style={{ width: `${COLUMN_WIDTH[0]}px` }}>削除</th>
                <th style={{ width: `${COLUMN_WIDTH[1]}px` }}>種別</th>
                <th style={{ width: `${COLUMN_WIDTH[2]}px` }}>名称</th>
                <th style={{ width: `${COLUMN_WIDTH[3]}px` }}>表示順</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(Array(120).keys()).map((rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    <td>
                      <Form.Check className="anra-checkbox d-flex justify-content-center" label="" type="checkbox" />
                    </td>
                    <td>Row 1, Col 2</td>
                    <td>
                      <Form.Select>
                        <option>option 1</option>
                        <option>option 2</option>
                        <option>option 3</option>
                        <option>option 4</option>
                        <option>option 5</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control type="text" placeholder="Enter number" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <Row>
        <Col sm={6}>
          <div className="border p-2 bg-main fw-bold mt-3 text-center">備考</div>
          <div className="border border-top-0 p-2 overflow-auto" style={{ maxHeight: '80px' }}>
            <div className="p-1">text 1</div>
            <div className="p-1">text 2</div>
            <div className="p-1">text 3</div>
            <div className="p-1">text 4</div>
            <div className="p-1">text 5</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DemoTraditionalListPage;
