import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import DemoFormModal from '../components/DemoFormModal/DemoFormModal';
import DemoListModal from '../components/DemoListModal/DemoListModal';
import DemoOverflowListModal from '../components/DemoOverflowListModal/DemoOverflowListModal';

function DemoModalPage() {
  const [showDemoFormModal, setShowDemoFormModal] = useState(false);
  const [showDemoListModal, setShowDemoListModal] = useState(false);
  const [showDemoOverflowListModal, setShowDemoOverflowListModal] = useState(false);

  function handleShowDemoFormModal() {
    setShowDemoFormModal(true);
  }

  function handleCloseDemoFormModal() {
    setShowDemoFormModal(false);
  }

  function handleShowDemoListModal() {
    setShowDemoListModal(true);
  }

  function handleCloseDemoListModal() {
    setShowDemoListModal(false);
  }

  function handleShowDemoOverflowListModal() {
    setShowDemoOverflowListModal(true);
  }

  function handleCloseDemoOverflowListModal() {
    setShowDemoOverflowListModal(false);
  }

  return (
    <div className="page-content d-flex flex-column">
      <h1>Demo Form Controls</h1>
      <hr />
      <div className="mb-5">
        <div className="mb-3">
          <h4>Buttons</h4>
        </div>
        <div className="d-flex gap-3 mb-3">
          <Button onClick={handleShowDemoFormModal}>Form Modal</Button>
          <Button onClick={handleShowDemoListModal} variant="secondary">
            Demo List Modal
          </Button>
          <Button onClick={handleShowDemoOverflowListModal} variant="secondary">
            Demo Overflow List Modal
          </Button>
        </div>
      </div>

      {/* Modal */}
      {showDemoFormModal && createPortal(<DemoFormModal onClose={handleCloseDemoFormModal} />, document.body)}
      {showDemoListModal && createPortal(<DemoListModal onClose={handleCloseDemoListModal} />, document.body)}
      {showDemoOverflowListModal &&
        createPortal(<DemoOverflowListModal onClose={handleCloseDemoOverflowListModal} />, document.body)}
    </div>
  );
}

export default DemoModalPage;
