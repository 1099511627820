import { StyleSheet } from '@react-pdf/renderer';
import { PDF_COL_HEIGHT, PDF_COLOR, PDF_FONT_SIZE, PDF_LETTER_SPACING } from '../../../constants/style';

export const styles = StyleSheet.create({
  tableContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: PDF_LETTER_SPACING,
    fontSize: PDF_FONT_SIZE,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  colLeftStart: {
    borderLeftWidth: 0.5,
  },
  colGroupHeader: {
    borderRightWidth: 0.5,
    borderTopWidth: 0.5,
  },
  colSubGroupHeader: {
    borderRightWidth: 0.5,
    borderTopWidth: 0.5,
  },
  colEmptyGroupHeader: {
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  colHeader: {
    borderRightWidth: 0.5,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
  },
  colValue: {
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    minHeight: PDF_COL_HEIGHT,
  },
  colNameValue: {
    textAlign: 'left',
    paddingLeft: 1,
    borderLeftWidth: 0.5,
  },
  colCategory: {
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,
    borderBottomWidth: 0.5,
    fontWeight: 'bold',
    minHeight: PDF_COL_HEIGHT,
  },
  tableNoticeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderLeftWidth: 0.5,
    fontSize: PDF_FONT_SIZE,
    color: PDF_COLOR['text']['red'],
    fontWeight: 'extrabold',
  },
});
