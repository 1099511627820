import { Text, View } from '@react-pdf/renderer';
import { styles } from './table-styles';
import { GroupHeaderValueTypeInterface } from '../../api/api-header';

const GroupHeader = ({ groupHeaders }: any) => {
  return (
    <View>
      <View style={styles.row}>
        {groupHeaders.map((groupHeader: GroupHeaderValueTypeInterface, colIndex: number) => {
          return (
            // GROUP HEADER
            <View
              style={[
                styles.col,
                styles.colGroupHeader,
                colIndex === 0 ? styles.colEmptyGroupHeader : {},
                {
                  width: groupHeader.style.width + '%',
                  backgroundColor: groupHeader.style.backgroundColor,
                },
              ]}
              key={colIndex}
            >
              <Text>{groupHeader.title}</Text>
            </View>
          );
        })}
      </View>
      <View style={[styles.row]}>
        {groupHeaders.map((groupHeader: GroupHeaderValueTypeInterface, colIndex: number) => {
          if (groupHeader.subHeader) {
            return groupHeader?.subHeader?.map((subHeader, subColIndex: number) => {
              // SUB GROUP HEADER
              return (
                <View
                  style={[
                    styles.col,
                    styles.colSubGroupHeader,
                    colIndex === 0 ? styles.colEmptyGroupHeader : {},
                    {
                      width: subHeader.style.width + '%',
                      backgroundColor: groupHeader.style.backgroundColor,
                    },
                  ]}
                  key={subColIndex}
                >
                  <Text>{subHeader.title}</Text>
                </View>
              );
            });
          } else {
            return (
              // EMPTY GROUP HEADER FOR SUBHEADER
              <View
                style={[
                  styles.col,
                  styles.colGroupHeader,
                  colIndex === 0 || !groupHeader.subHeader ? styles.colEmptyGroupHeader : {},
                  {
                    width: groupHeader.style.width + '%',
                    backgroundColor: groupHeader.style.backgroundColor,
                  },
                ]}
                key={colIndex}
              ></View>
            );
          }
        })}
      </View>
    </View>
  );
};

export { GroupHeader };
