import {
  PDF_HIDDEN_BRIGHT,
  PDF_LETTET_BLANK,
  PDF_SELECT_BRIGHT,
  PDF_SUSPEND_BRIGHT,
} from 'features/PDF/constants/style';
import { ApproveSelect, ConfirmSelect } from '../ApproveSelect/approveSelect';
import { useGetRole } from 'components/formfields/Privilege/components/editForm';
import { PRIVILEGE_ROLE } from 'components/formfields/constants/privilege';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/Auth/authSlice';
import '../../style.scss';

const MenuRow = ({
  record,
  styles,
  index,
  isSelected,
  onApprove,
  onConfirm,
  confirmUserId,
  revisionDate,
  onChangeMenuName,
  isAllowedEdit,
}: any) => {
  const role = useGetRole();
  const menuRecord = record.value;
  const user = useSelector(selectUserInfo);
  const curUserId = user?.employee_cd;
  const isSuspend = new Date(revisionDate) > new Date(record.suspendDate);
  return (
    <div
      style={{ ...styles.row } as any}
      key={index}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ApproveSelect
        onApprove={onApprove}
        record={record}
        isSelected={isSelected}
        isSuspend={isSuspend}
        disabled={role === PRIVILEGE_ROLE.NORMAL || !isAllowedEdit}
      />
      <ConfirmSelect
        onConfirm={onConfirm}
        record={record}
        isSelected={isSelected}
        disabled={(curUserId === confirmUserId && role === PRIVILEGE_ROLE.NORMAL) || !isAllowedEdit}
        isSuspend={isSuspend}
      />

      {Object.keys(menuRecord).map((key, colIndex) => {
        return (
          // VALUE
          <div
            style={
              {
                ...styles.col,
                ...styles.colValue,
                ...{
                  ...menuRecord[key]?.style,
                  width: menuRecord[key].style?.width + '%',
                },
                ...(colIndex === 0 ? styles.colNameValue : {}),
                ...(record.isHidden ? { filter: `brightness(${PDF_HIDDEN_BRIGHT}%)` } : {}),
                ...(isSuspend ? { filter: `brightness(${PDF_SUSPEND_BRIGHT}%)` } : {}),
                ...(isSelected ? { filter: `brightness(${PDF_SELECT_BRIGHT}%)` } : {}),
              } as any
            }
            key={colIndex + 'menuCol'}
          >
            <div
              style={{
                marginLeft: colIndex === 0 ? PDF_LETTET_BLANK * menuRecord[key]?.blank || 0 : 0,
                height: '100%',
              }}
            >
              {colIndex === 0 ? (
                <input
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="chart_text"
                  type="text"
                  value={menuRecord[key]?.value}
                  onChange={(e: any) => onChangeMenuName(e, record, key)}
                  disabled={!isAllowedEdit}
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '100%',
                  }}
                />
              ) : (
                menuRecord[key].value
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { MenuRow };
