import { Button, Col, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';
import './style.scss';

const COLUMN_WIDTH = [120, 400, 100, 100, 100, 100, 100, 100, 100, 400, 400];

interface DemoListModalProps {
  onClose: () => void;
}

function DemoOverflowListModal({ onClose }: DemoListModalProps) {
  return (
    <Modal
      show
      size="lg"
      dialogClassName="demo-overflow-list-modal"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* filter */}
        <div className="mb-3">
          <Row>
            <Col sm={2}>
              <InputGroup>
                <FormControl placeholder="Search" aria-label="Search" />
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </div>

        {/* list */}
        <div className="sticky-table" style={{ height: '540px' }}>
          <div className="div-table fixsize-column">
            <div className="div-thead div-tr">
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
                部品コード
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                名称
              </div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[2]}px` }}
                dangerouslySetInnerHTML={{ __html: 'カロリー<br/>(kcal)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[3]}px` }}
                dangerouslySetInnerHTML={{ __html: 'タンパク質<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[4]}px` }}
                dangerouslySetInnerHTML={{ __html: '脂質<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[5]}px` }}
                dangerouslySetInnerHTML={{ __html: '炭水化物<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[6]}px` }}
                dangerouslySetInnerHTML={{ __html: '食物繊維<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[7]}px` }}
                dangerouslySetInnerHTML={{ __html: 'ナトリウム<br/>(g)' }}
              ></div>
              <div
                className="div-th"
                style={{ width: `${COLUMN_WIDTH[8]}px` }}
                dangerouslySetInnerHTML={{ __html: '食塩相当量<br/>(g)' }}
              ></div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[9]}px` }}>
                特定原材料
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[10]}px` }}>
                特定原材料に準ずる品目
              </div>
            </div>

            {Array.from(Array(120).keys()).map((rowIndex) => {
              return (
                <div className="div-tr" key={rowIndex}>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[0]}px` }}>
                    B000001231
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[1]}px` }}>
                    うちダレ
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}px` }}>
                    259
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}px` }}>
                    10.5
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[4]}px` }}>
                    1.2
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[5]}px` }}>
                    0.2
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[6]}px` }}>
                    1.0
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[7]}px` }}>
                    0.5
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[8]}px` }}>
                    12.2
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[9]}px` }}>
                    乳（●）、えび（▲）、かに（▲）
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[10]}px` }}>
                    いか（▲）、ごま由来原料（ごま油由来）
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DemoOverflowListModal;
