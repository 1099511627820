import { EditFieldsFormPrivilege } from '../models/editFormPrivilege';
import { PrivilegeStatus } from '../models/privilege';
import { PRIVILEGE } from './privilege';
import { useSelector } from 'react-redux';
import { selectUserSelectedJob } from 'features/Auth/authSlice';
import { AuthorityLevels, SYSTEM_ID } from 'features/CalorieManagement/PurchaseProcessedCalorieAllergySetting/constant';

interface IsAllowEditProps {
  privileges?: EditFieldsFormPrivilege[];
  status: PrivilegeStatus;
}

const useGetRole: () => string | null = () => {
  const selectedJob = useSelector(selectUserSelectedJob);
  const level = Number(selectedJob?.levels[SYSTEM_ID] ?? '0') || 0;

  let role = null;
  if (level < AuthorityLevels.APPROVAL) {
    role = 'normal';
  }
  if (level >= AuthorityLevels.APPROVAL) {
    role = 'approval';
  }

  return role;
};

const useIsAllowEdit: (props: IsAllowEditProps) => boolean = (props) => {
  const { status, privileges = PRIVILEGE['editFormFields'] } = props;
  const role = useGetRole();

  return privileges.some((privilege) => {
    const checkStatus = privilege.status === status;
    const checkRole = privilege.role === role;
    if (checkStatus && checkRole) {
      return true;
    }
    return false;
  });
};

export { useIsAllowEdit, useGetRole };
