const REPLACE_ITEM_TABLE_HEADER: { title: string; width: number | string }[] = [
  {
    title: 'コード',
    width: 15,
  },
  {
    title: '名称',
    width: 20,
  },
  {
    title: '適用開始日',
    width: 50,
  },
  {
    title: '適用終了日',
    width: 60,
  },
  {
    title: '構成量',
    width: 60,
  },
  {
    title: '構成単位',
    width: 40,
  },
];

export { REPLACE_ITEM_TABLE_HEADER };
