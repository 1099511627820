import { Text, View } from '@react-pdf/renderer';
import { styles } from './table-styles';
import { ManualText, VerticalText } from '../../common/text';
import { HeaderValueTypeInterface } from '../../api/api-header';

const getHeaderStyle = (header: any) => {
  if (header?.style?.manualHeader) {
    return <ManualText text={header.title} spaceWidth={header.style.width} />;
  }
  if (header?.style?.verticalHeader) {
    return <VerticalText text={header.title} />;
  }
  return <Text>{header.title}</Text>;
};

const Header = ({ headers }: any) => {
  return (
    <View fixed style={[styles.row]}>
      {headers.map((header: HeaderValueTypeInterface, colIndex: number) => {
        return (
          // HEADER
          <View
            style={[
              styles.col,
              styles.colHeader,
              colIndex === 0 ? styles.colLeftStart : {},
              {
                width: header.style.width + '%',
                backgroundColor: header.style.backgroundColor,
              },
            ]}
            key={colIndex}
          >
            {getHeaderStyle(header)}
          </View>
        );
      })}
    </View>
  );
};

export { Header };
