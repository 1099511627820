import { CommonResponse } from '../../../../models/common';
import axiosClient from '../../../../utils/axiosClient';

const ROOT_API_ROUTE = 'purchase-unit-master';

const PurchaseUnitMasterService = {
  getAll(): Promise<CommonResponse<any>> {
    let url = `${ROOT_API_ROUTE}`;
    return axiosClient.get(url);
  },
};

export default PurchaseUnitMasterService;
