import { AxiosError } from 'axios';
import { AllergyItemMasterAddPayload, AllergyItemMasterUpdatePayload, ApiError } from 'models';
import allergyItemMasterService from '../allergyItemMasterService';

function useAllergyItemMaster() {
  async function addAllergyItemMaster(payload: AllergyItemMasterAddPayload) {
    try {
      const response = await allergyItemMasterService.add(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  async function updateAllergyItemMaster(payload: AllergyItemMasterUpdatePayload[]) {
    try {
      const response = await allergyItemMasterService.update(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  return { addAllergyItemMaster, updateAllergyItemMaster };
}

export default useAllergyItemMaster;
