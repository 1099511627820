/* eslint-disable react/react-in-jsx-scope */
interface AraLogoIconProps {
  width?: number | string;
  height?: number | string;
}
export const AraLogoIcon = (props: AraLogoIconProps) => {
  return (
    <svg {...props} viewBox="0 0 101 32" fill="none">
      <path
        d="M76.9405 25.2059H84.2986C84.3263 28.1275 84.5068 28.1964 81.0777 28.1137C80.7861 28.1137 80.4807 28.0999 80.1614 28.0861V31.9862C80.4668 32 80.7722 31.9862 81.0777 31.9862C87.9915 31.9862 88.7273 31.1318 88.7412 25.1921H94.3777V21.8157H76.9266V25.1921L76.9405 25.2059Z"
        fill={'#BC1224'}
      />
      <path
        d="M96.5296 7.59346H74.8163V15.2558H96.5296V7.59346ZM92.2397 12.6649H79.1201V10.1843H92.2397V12.6649Z"
        fill={'#BC1224'}
      />
      <path d="M101 16.882H70.3182V26.0465H75.0246V20.1344H96.2936V26.0465H101V16.882Z" fill={'#BC1224'} />
      <path d="M83.3128 0V2.39793H70.3321V6.02239H101V2.39793H88.0192V0H83.3128Z" fill={'#BC1224'} />
      <path
        d="M1.04123 9.77089H5.65044V6.47718H25.1007V9.77089H29.7238V2.85271H17.6871V0H13.064V2.85271H1.04123V9.77089Z"
        fill={'#BC1224'}
      />
      <path
        d="M16.1878 8.04823H11.0926C10.6623 9.13695 10.1347 10.2946 9.50997 11.5211H0V15.6692H7.14983C6.09471 17.3643 4.90076 19.087 3.56797 20.7683C6.02529 21.7054 9.37113 22.863 12.9669 24.186C10.3985 25.3574 6.80275 25.9914 1.74928 26.4048V31.1593C9.37113 30.4565 14.7439 28.7752 18.062 26.143C22.2547 27.8243 26.3919 29.6985 29.5156 31.683V26.6391C27.0028 25.2885 24.0734 24.0896 21.0746 22.9595C22.7129 20.8096 23.768 18.5633 24.6426 15.683H30.7512V11.5349H14.5634C15.1882 10.3497 15.7435 9.17829 16.1878 8.06201V8.04823ZM20.1445 15.6692C19.2698 17.9294 18.1869 19.6658 16.6876 21.3609C14.3552 20.534 12.0784 19.7347 10.0236 18.9216C10.7178 17.9018 11.4536 16.7993 12.1616 15.6692H20.1445Z"
        fill={'#BC1224'}
      />
      <path
        d="M52.8532 18.1223H58.6425V2.81137H52.7144L53.6862 0H48.8965L48.3689 2.81137H42.4408V18.1085H48.2301V20.2308H35.166V24.0896H45.7034C43.0794 25.7709 39.1227 27.0525 35.166 27.2179V31.5314C39.6225 30.9526 44.5649 29.8777 48.2301 26.708V31.9862H52.8532V26.0189C55.4632 28.4031 59.5865 30.4289 65.9172 31.876V27.466C61.5441 26.6529 58.337 25.4401 56.1713 24.0896H65.9172V20.2308H52.8532V18.1085V18.1223ZM46.703 6.20155H54.3942V9.05426H46.703V6.20155ZM46.703 14.7459V11.8932H54.3942V14.7459H46.703Z"
        fill={'#BC1224'}
      />
      <path
        d="M40.8165 4.57537C38.9839 3.76227 37.4429 2.72868 35.166 0.744186V6.11886C36.721 7.59345 38.1093 8.50301 40.8165 9.56417V4.57537Z"
        fill={'#BC1224'}
      />
      <path
        d="M40.8165 10.5151C38.4008 11.3971 36.9709 12.1137 35.166 13.4643V19.1421C37.0819 17.3781 38.7479 16.4134 40.8165 15.4074V10.5013V10.5151Z"
        fill={'#BC1224'}
      />
      <path
        d="M60.2529 4.57537V9.56417C62.9601 8.50302 64.3484 7.59346 65.9034 6.11887V0.730408C63.6265 2.7149 62.0855 3.7485 60.2529 4.56159V4.57537Z"
        fill={'#BC1224'}
      />
      <path
        d="M60.2529 15.4212C62.3215 16.4272 63.9875 17.3919 65.9034 19.1559V13.478C64.0985 12.1137 62.6547 11.4109 60.2529 10.5289V15.435V15.4212Z"
        fill={'#BC1224'}
      />
    </svg>
  );
};
