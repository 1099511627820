import { Route, Switch, useRouteMatch } from 'react-router-dom';
import MenuStructureSettingList from './screens/MenuStructureSettingList';
import MenuStructureSettingEdit from './screens/MenuStructureSettingEdit';
import MenuStructureSettingCreate from './screens/MenuStructureSettingCreate';

function MenuStructureSettingManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <MenuStructureSettingList />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <MenuStructureSettingEdit />
      </Route>
      <Route exact path={`${path}/create`}>
        <MenuStructureSettingCreate />
      </Route>
      <Route exact path={`${path}/copy/:id`}>
        <MenuStructureSettingCreate />
      </Route>
    </Switch>
  );
}

export default MenuStructureSettingManagement;
