export const menuCategoryMasterTranslationJP = {
  MenuCategoryMaster: {
    head_title: 'メニューカテゴリマスタメンテナンス',
    update: '更新',
    register: '新規登録',
    all: '全て',
    delete: '削除',
    business_type: '業態',
    name: '名称',
    display_order: '表示順',
    add: '登録',

    warn_field_required: 'このフィールドは必須です',
    warn_number_required: '０以上の数値のみ入力可',
  },
};
