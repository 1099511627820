export const DEFAULT_FILTER_ALLERGY_MASTER_TYPE = 0;

export const ALLERGY_MASTER_TYPE_FILERSOURCE = [
  {
    value: 0,
    label: '全て',
  },
  {
    value: 1,
    label: '特定原材料のみ',
  },
  {
    value: 2,
    label: '特定原材料に準ずる品目のみ',
  },
];

export const ALLERGY_MASTER_TYPE_SELECTSOURCE = [
  {
    value: 1,
    label: '特定原材料',
  },
  {
    value: 2,
    label: '特定原材料に準ずる品目',
  },
];
