export const CALORIE_TABLE_HEADER = [
  {
    title: '選択',
    width: '5%',
  },
  {
    title: '変更有',
    width: '5%',
  },
  {
    title: 'ステータス',
    width: '5%',
  },
  {
    title: '業態',
    width: '5%',
  },
  {
    title: '名称',
    width: '20%',
  },
  {
    title: '改定日',
    width: '10%',
  },
  {
    title: '最終更新日時',
    width: '10%',
  },
  {
    title: '承認日時',
    width: '5%',
  },
  {
    title: '出力日',
    width: '5%',
  },
  {
    title: '備考',
    width: '20%',
  },
  {
    title: '編集',
    width: '10%',
  },
];
