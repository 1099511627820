export const nutritionMasterTranslationJP = {
  NutritionMaster: {
    head_title: '栄養価マスタメンテナンス',
    update: '更新',
    register: '新規登録',
    delete: '削除',
    nutrition_name: '名称',
    nutrition_unit_master: '単位',
    display_order: '表示順',
    add: '登録',
    warn_field_required: 'このフィールドは必須です',
    warn_number_required: '０以上の数値のみ入力可',
  },
};
