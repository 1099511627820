import { InputFormDatePicker } from 'components/formfields/InputFormDatePicker';
import ReactDatePicker from 'react-datepicker';
import { CHART_TEMLATES } from '../../Template';

const IntroductionFrame = ({ onDateChange, date, isAllowedEdit, template = 0 }: any) => {
  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginBottom: 6,
          paddingRight: 6,
          fontWeight: 'bold',
        }}
      >
        <div style={{ marginRight: '10px' }}>{`ランチ一覧 `}</div>
        <div>
          <div style={{ maxWidth: '110px' }}>
            <ReactDatePicker
              locale="ja"
              selected={date}
              onChange={(date: Date) => onDateChange(date)}
              customInput={<InputFormDatePicker />}
              dateFormat="yyyy/MM/dd"
              disabled={!isAllowedEdit}
            />
          </div>
        </div>
        <div style={{ marginLeft: '10px' }}> 改訂</div>
      </div>

      {CHART_TEMLATES[template].displayComponent}
    </div>
  );
};

export { IntroductionFrame };
