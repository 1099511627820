import {
  ApiResponse,
  ElementClassificationMaster,
  ElementClassificationMasterAddPayload,
  ElementClassificationMasterUpdatePayload,
} from 'models';
import axiosClient from 'utils/axiosClient';

const ROOT_API_ROUTE = '/element-classification-masters';

const elementClassificationMasterService = {
  getAll(): Promise<ApiResponse<ElementClassificationMaster[]>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.get(url);
  },
  add(payload: ElementClassificationMasterAddPayload): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.post(url, payload);
  },
  update(payload: ElementClassificationMasterUpdatePayload[]): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.patch(url, payload);
  },
};

export default elementClassificationMasterService;
