import { PayloadAction } from '@reduxjs/toolkit';
import { CommonResponse } from '../../../../models';
import { call, put, takeLatest } from 'redux-saga/effects';
import menuStructureSettingService from './menuStructureSettingService';
import { menuStructureSettingActions } from './menuStructureSettingSlice';
import { MenuStructureSetting } from '../models';

function* fetchMenuStructureSetting(action: PayloadAction<{ id: string }>) {
  try {
    const response: CommonResponse<MenuStructureSetting> = yield call(
      menuStructureSettingService.getItem,
      action.payload.id
    );
    yield put(menuStructureSettingActions.fetchMenuStructureSettingSuccess(response));
  } catch (e) {
    yield put(menuStructureSettingActions.fetchMenuStructureSettingFailed());
  }
}

export default function* menuStructureSettingSaga() {
  yield takeLatest(menuStructureSettingActions.fetchMenuStructureSetting.type, fetchMenuStructureSetting);
}
