import { AxiosError } from 'axios';
import { ApiError, MenuCategoryMasterAddPayload, MenuCategoryMasterUpdatePayload } from 'models';
import menuCategoryMasterService from '../menuCategoryMasterService';

function useMenuCategoryMaster() {
  async function addMenuCategoryMaster(payload: MenuCategoryMasterAddPayload) {
    try {
      const response = await menuCategoryMasterService.add(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  async function updateMenuCategoryMaster(payload: MenuCategoryMasterUpdatePayload[]) {
    try {
      const response = await menuCategoryMasterService.update(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  return { addMenuCategoryMaster, updateMenuCategoryMaster };
}

export default useMenuCategoryMaster;
