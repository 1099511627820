import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AllergyMasterUpdatePayload } from 'models';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { allergyMasterActions, selectAllergyMasterData } from 'store/AllergyMaster/allergyMasterSlice';
import AllergyMasterFilter from './components/AllergyMasterFilter';
import AllergyMasterForm from './components/AllergyMasterForm';
import AllergyMasterList from './components/AllergyMasterList';
import { DEFAULT_FILTER_ALLERGY_MASTER_TYPE } from './constants';
import useAllergyMaster from './hooks/useAllergyMaster';
import './style.scss';

export type AllergyMasterListFormValues = {
  data: AllergyMasterUpdatePayload[];
};

export type AllergyMasterFilterValues = {
  type: number;
  displayFlag?: boolean;
};

function AllergyMasterFeature() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // hooks
  const { updateAllergyMaster } = useAllergyMaster();

  // refs
  const formRef = useRef<HTMLFormElement>(null);
  const nextDisplayOrder = useRef<number>(1);

  // states
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState<AllergyMasterFilterValues>({ type: DEFAULT_FILTER_ALLERGY_MASTER_TYPE });

  // selectors
  const allergyMasterList = useAppSelector(selectAllergyMasterData);

  // side effects
  useEffect(() => {
    dispatch(allergyMasterActions.fetchAllergyMasterList(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (allergyMasterList.length === 0) {
      nextDisplayOrder.current = 1;
      return;
    }

    const maxDisplayOrder = allergyMasterList.reduce(
      (max, item) => (item.display_order > max ? item.display_order : max),
      allergyMasterList[0].display_order
    );
    nextDisplayOrder.current = maxDisplayOrder + 1;
  }, [allergyMasterList]);

  // events
  function handleOpenForm() {
    setShowForm(true);
  }

  function handleCloseForm() {
    setShowForm(false);
  }

  function handleAllergyMasterFormReflect() {
    dispatch(allergyMasterActions.fetchAllergyMasterList(filter));
  }

  function handleSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }

  function handleSubmitAllergyMasterListForm(formValues: AllergyMasterListFormValues) {
    (async () => {
      const { data, error } = await updateAllergyMaster(formValues.data);
      if (data) {
        toast.success(data?.message);
        dispatch(allergyMasterActions.fetchAllergyMasterList(filter));
      } else {
        if (error && error.error) {
          toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        }
      }
    })();
  }

  function handleFilter(filter: AllergyMasterFilterValues) {
    setFilter({
      ...filter,
      type: filter.type,
    });
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('AllergyMaster.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleSubmit}>{t('AllergyMaster.update')}</Button>
              <Button onClick={handleOpenForm}>{t('AllergyMaster.register')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* filter */}
      <AllergyMasterFilter onFilter={handleFilter} />

      {/* list */}
      <AllergyMasterList formRef={formRef} data={allergyMasterList} onSubmitForm={handleSubmitAllergyMasterListForm} />

      {/* Form Modal */}
      {showForm &&
        createPortal(
          <AllergyMasterForm
            nextDisplayOrder={nextDisplayOrder.current}
            onClose={handleCloseForm}
            onReflect={handleAllergyMasterFormReflect}
          />,
          document.body
        )}
    </div>
  );
}

export default AllergyMasterFeature;
