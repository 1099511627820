import { AllergyItemMaster, AllergyItemMasterAddPayload, AllergyItemMasterUpdatePayload, ApiResponse } from 'models';
import axiosClient from 'utils/axiosClient';

const ROOT_API_ROUTE = '/allergy-item-masters';

const allergyItemMasterService = {
  getAll(): Promise<ApiResponse<AllergyItemMaster[]>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.get(url);
  },
  add(payload: AllergyItemMasterAddPayload): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.post(url, payload);
  },
  update(payload: AllergyItemMasterUpdatePayload[]): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.patch(url, payload);
  },
};

export default allergyItemMasterService;
