import { PDF_FONT_SIZE } from 'features/PDF/constants/style';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { YellowFrame } from './yellowFrame';
import { AraExportLogoIcon } from '../logos/anrakutei';
import { InlineText } from '../common/text';

const INFO_LEFT_MARGIN = 8;

export const AnrakuteiExportTemplate = () => {
  const styles = StyleSheet.create({
    IntroductionFrameContainer: {
      fontSize: PDF_FONT_SIZE,
      margin: '10 0',
      width: '100%',
      fontWeight: 'light',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: 2,
    },
    infomationTitle: {
      width: '27%',
      fontWeight: 'bold',
      textAlign: 'center',
      border: 0.5,
      padding: '1 2',
      marginBottom: 3,
    },
  });
  return (
    <View style={styles.IntroductionFrameContainer}>
      <View style={styles.logoContainer}>
        <AraExportLogoIcon width={200} height={80} />
      </View>

      {/* text in black frame */}
      <View style={styles.infomationTitle}>
        <Text>食品衛生法に基づき、下記について表示をしております</Text>
      </View>

      <View style={{ marginLeft: INFO_LEFT_MARGIN }}>
        <InlineText>
          <Text style={{ marginBottom: 1 }}>
            アレルゲン27品目を含む商品「○」、原材料にコンタミの可能性があるものは「▲」を表示しております。
          </Text>
          <Text style={{ fontWeight: 'bold' }}>詳細についてはアレルゲンTOPページをご確認ください。</Text>
        </InlineText>

        <Text style={{ marginBottom: 5 }}>
          栄養成分値は日本食品標準成分表による数値および、検査機関での分析値を元に算出しております。卓上のタレをかけた場合の栄養成分はこちらに表示しておりません。あらかじめご了承ください。
        </Text>

        <Text style={{ marginBottom: 2 }}>
          ★該当アレルゲンを含む商品として「○」表記のあるものから、下記のように注釈を入れております。
        </Text>
      </View>

      {/* yello frame */}
      <YellowFrame />

      {/* 4 sentance */}
      <Text>
        ※店舗によりメニュー、原材料が異なる場合がございます。
        また、同一メニューであっても原材料は都合により変わることがあります。ご利用の際は最新のものをご確認くださいますようお願い致します。
      </Text>
    </View>
  );
};
