import {
  PDF_COL_HEIGHT,
  PDF_COL_STYLE_TABLE_BY_TYPE,
  PDF_COLOR,
  PDF_FONT_SIZE,
  PDF_LETTER_SPACING,
} from 'features/PDF/constants/style';

const styles: any = {
  tableContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: PDF_LETTER_SPACING,
    fontSize: PDF_FONT_SIZE,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  colGroupHeader: {
    borderRight: 'solid 1px black',
    borderTop: 'solid 1px black',
  },
  colSubGroupHeader: {
    borderRight: 'solid 1px black',
    borderTop: 'solid 1px black',
  },
  colHeader: {
    borderRight: 'solid 1px black',
    borderTop: 'solid 1px black',
  },
  colEmptyGroupHeader: {
    borderLeft: 0,
    borderTop: 0,
  },
  colValue: {
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
    backgroundColor: 'white',
    minHeight: PDF_COL_HEIGHT,
  },
  selectedCol: {
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
    backgroundColor: 'rgb(220,220,220)',
  },
  colNameValue: {
    // borderLeft: 'solid 1px black',
    display: 'block',
    textAlign: 'left',
    paddingLeft: 1,
  },
  colCategory: {
    // borderLeft: 'solid 1px black',
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'left',
  },
  colApprove: {
    borderRight: 'solid 1px black',
    borderLeft: 'solid 1px black',
    borderBottom: 'solid 1px black',
  },
  colConfirm: {
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
  },
  selectedCategory: {
    // borderLeft: 'solid 1px black',
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'left',
    backgroundColor: 'rgb(220,220,220)',
  },
  tableNoticeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
    borderLeft: 'solid 1px black',
    fontSize: PDF_FONT_SIZE,
    color: PDF_COLOR['text']['red'],
    fontWeight: 'extrabold',
  },
  selectHeader: {
    width: PDF_COL_STYLE_TABLE_BY_TYPE['select']['width'] + '%',
    borderRight: 'solid 1px black',
    borderBottom: 'solid 1px black',
    borderTop: 'solid 1px black',
    backgroundColor: 'orange',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export { styles };
