import { BusinessFormatMaster } from 'models';
import { ChangeEvent, memo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MenuCategoryMasterFilterValues } from '..';

interface MenuCategoryMasterFilterProps {
  businessTypeData: BusinessFormatMaster[];
  onFilter?: (filterValue: MenuCategoryMasterFilterValues) => void;
}

function MenuCategoryMasterFilter({ businessTypeData, onFilter }: MenuCategoryMasterFilterProps) {
  const { t } = useTranslation();

  // // side effect
  // useEffect(() => {
  //   if (businessTypeData.length > 0) {
  //     onFilter && onFilter({ businessType: businessTypeData[0].business_format_cd }); // reset filter when component mounts or businessTypeData changes
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [businessTypeData]);

  // events
  function handleTypeFilterChange(e: ChangeEvent<HTMLSelectElement>) {
    const businessType = parseInt((e.target as HTMLSelectElement).value);
    onFilter && onFilter({ businessType: businessType });
  }

  return (
    <div className="my-3">
      <div className="d-flex align-items-center gap-2">
        <Form.Group as={Row} controlId="search-business-type">
          <Form.Label column sm="auto">
            {t('MenuCategoryMaster.business_type')}
          </Form.Label>
          <Col sm="auto">
            <Form.Select id="search-business-type" onChange={handleTypeFilterChange} style={{ maxWidth: '130px' }}>
              {businessTypeData.length > 0 &&
                businessTypeData.map((option) => (
                  <option key={option.business_format_cd} value={option.business_format_cd}>
                    {option.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Form.Group>
      </div>
    </div>
  );
}

export default memo(MenuCategoryMasterFilter);
