import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import { forwardRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaCalendarAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface DatePickerCustomProps {
  onChange?: (date: Date) => void;
  value?: Date;
  name?: string;
}

const DatePickerCustom: React.FC<DatePickerCustomProps> = (props) => {
  const [startDate, setStartDate] = useState(props.value ?? new Date());
  const years = range(1990, 2100, 1);
  const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

  const handleChangeDate = (date: Date) => {
    setStartDate(date);
    if (props.onChange) {
      props.onChange(date);
    }
  };

  return (
    <DatePicker
      dateFormat="yyyy/MM/dd"
      locale={'ja'}
      maxDate={new Date('2099/12/31')}
      minDate={new Date('1990/01/01')}
      //showIcon
      //icon={<FaCalendarAlt />}
      customInput={<CustomInputDatePicker />}
      toggleCalendarOnIconClick
      className="form-control"
      selected={props.value ?? startDate}
      onChange={handleChangeDate}
      name={props.name}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            style={{ minWidth: '40px', backgroundColor: 'transparent', border: 'none' }}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <FaChevronLeft size={12} style={{ color: 'black' }} />
          </button>
          <Form.Select
            className="form-select form-select-sm"
            size="sm"
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(parseInt(value))}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>

          <Form.Select
            className="form-select form-select-sm"
            size="sm"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>

          <button
            className="btn btn-sm btn-primary"
            style={{ minWidth: '40px', backgroundColor: 'transparent', border: 'none' }}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <FaChevronRight size={12} style={{ color: 'black' }} />
          </button>
        </div>
      )}
    />
  );
};

const CustomInputDatePicker = forwardRef((props: any, ref: any) => {
  // You can style this further
  return (
    <>
      <div className="position-relative">
        <FaCalendarAlt
          style={{ position: 'absolute', top: '0.7rem', left: '0.8rem', cursor: 'pointer' }}
          onClick={props.onClick}
        />
        <input
          className="form-control"
          onClick={props.onClick}
          style={{ paddingLeft: '32px' }}
          value={props.value}
          readOnly={true}
          type="text"
        />
      </div>
    </>
  );
});

export default DatePickerCustom;
