import { yupResolver } from '@hookform/resolvers/yup';
import { ElementClassificationMasterAddPayload } from 'models';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LiaTimesSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import useElementClassificationMaster from '../hooks/useElementClassificationMaster';

interface ElementClassificationMasterFormProps {
  nextDisplayOrder: number;
  onClose: () => void;
  onReflect?: () => void;
}

function ElementClassificationMasterForm({
  nextDisplayOrder,
  onClose,
  onReflect,
}: ElementClassificationMasterFormProps) {
  const { t } = useTranslation();

  const { addElementClassificationMaster } = useElementClassificationMaster();

  // schema validation
  const schema = yup
    .object({
      element_classification_name: yup.string().required(t('ElementClassificationMaster.warn_field_required')),
      display_order: yup
        .number()
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string' && originalValue.trim() === '') {
            return undefined;
          }
          return value;
        })
        .min(1, t('ElementClassificationMaster.warn_number_required'))
        .integer(t('ElementClassificationMaster.warn_number_required'))
        .required(t('ElementClassificationMaster.warn_number_required')),
    })
    .required();

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<ElementClassificationMasterAddPayload>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (payload: ElementClassificationMasterAddPayload) => {
    const { data, error } = await addElementClassificationMaster(payload);
    if (data) {
      toast.success(data?.message);
      onReflect?.();
      onClose();
    } else {
      if (error && error.error) {
        const values = getValues();
        const fieldNames = Object.keys(values);
        toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        for (const key of Object.keys(error.error)) {
          if (fieldNames.includes(key)) {
            setError(key as keyof ElementClassificationMasterAddPayload, { type: 'custom', message: error.error[key] });
          }
        }
      }
    }
  };

  return (
    <Modal
      show
      size="lg"
      dialogClassName="element-classification-master-form-modal"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group as={Row} className="mb-3" controlId="element_classification_name">
            <Form.Label column sm="3" className="text-end">
              {t('ElementClassificationMaster.element_classification_name')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                {...register('element_classification_name')}
                type="text"
                placeholder=""
                isInvalid={!!errors.element_classification_name}
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                {errors.element_classification_name?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="display_order">
            <Form.Label column sm="3" className="text-end">
              {t('ElementClassificationMaster.display_order')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                {...register('display_order')}
                className="text-end"
                style={{ width: '80px' }}
                type="number"
                placeholder=""
                defaultValue={nextDisplayOrder}
                min={1}
                isInvalid={!!errors.display_order}
              />
              <Form.Control.Feedback type="invalid">{errors.display_order?.message}</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div className="text-center mt-5">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="d-inline-flex gap-1 justify-content-center btn btn-primary align-items-center"
            >
              <span>{t('ElementClassificationMaster.add')}</span>
              {isSubmitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ElementClassificationMasterForm;
