import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ApiResponse, BusinessFormatMaster } from 'models';

export interface BusinessFormatMasterState {
  loading: boolean;
  data: BusinessFormatMaster[];
}

const initialState: BusinessFormatMasterState = {
  loading: false,
  data: [],
};

const businessFormatMasterSlice = createSlice({
  name: 'BusinessFormatMaster',
  initialState,
  reducers: {
    fetchBusinessFormatMasterList(state) {
      state.loading = true;
    },
    fetchBusinessFormatMasterListSuccess(state, action: PayloadAction<ApiResponse<BusinessFormatMaster[]>>) {
      state.data = action.payload.data;
      state.loading = false;
    },
    fetchBusinessFormatMasterListFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const businessFormatMasterActions = businessFormatMasterSlice.actions;

// Selectors
export const selectBusinessFormatMasterData = (state: RootState) => state.businessFormatMaster.data;

// Reducer
export const businessFormatMasterReducer = businessFormatMasterSlice.reducer;
