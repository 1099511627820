export const allergyMasterTranslationJP = {
  AllergyMaster: {
    head_title: 'アレルゲンマスタメンテナンス',
    update: '更新',
    register: '新規登録',
    all: '全て',
    delete: '削除',
    type: '種別',
    name: '名称',
    display_order: '表示順',
    display_flg: '表示フラグ',
    add: '登録',

    warn_field_required: 'このフィールドは必須です',
    warn_number_required: '０以上の数値のみ入力可',
  },
};
