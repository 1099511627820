import JapanFontThin from '../../../assets/fonts/NotoSansJP-Thin.ttf';
import JapanFontExtraLight from '../../../assets/fonts/NotoSansJP-ExtraLight.ttf';
import JapanFontLight from '../../../assets/fonts/NotoSansJP-Light.ttf';
import JapanFontRegular from '../../../assets/fonts/NotoSansJP-Regular.ttf';
import JapanFontMedium from '../../../assets/fonts/NotoSansJP-Medium.ttf';
import JapanFontSemiBold from '../../../assets/fonts/NotoSansJP-SemiBold.ttf';
import JapanFontBold from '../../../assets/fonts/NotoSansJP-Bold.ttf';
import JapanFontExtraBold from '../../../assets/fonts/NotoSansJP-ExtraBold.ttf';
import JapanFontBlack from '../../../assets/fonts/NotoSansJP-Black.ttf';

// only import the fonts that are used, prevent loading perfomance
const PDF_JAPAN_FONT = {
  thin: {
    src: JapanFontThin,
    fontWeight: 100,
  },
  extraLight: {
    src: JapanFontExtraLight,
    fontWeight: 200,
  },
  light: {
    src: JapanFontLight,
    fontWeight: 300,
  },
  regular: {
    src: JapanFontRegular,
    fontWeight: 400,
  },
  medium: {
    src: JapanFontMedium,
    fontWeight: 500,
  },
  semiBold: {
    src: JapanFontSemiBold,
    fontWeight: 600,
  },
  bold: {
    src: JapanFontBold,
    fontWeight: 700,
  },
  extraBold: {
    src: JapanFontExtraBold,
    fontWeight: 800,
  },
  black: {
    src: JapanFontBlack,
    fontWeight: 900,
  },
};

export { PDF_JAPAN_FONT };
