//import { Redirect, useLocation } from 'react-router-dom';
//import authStorage from '../../features/Auth/authStorage';

export interface PrivateRouteProps {
  children: JSX.Element;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  return children;
}
