import {
  ApiResponse,
  NutritionUnitMaster,
  NutritionUnitMasterAddPayload,
  NutritionUnitMasterUpdatePayload,
} from 'models';
import axiosClient from 'utils/axiosClient';

const ROOT_API_ROUTE = '/nutrition-unit-masters';

const nutritionUnitMasterService = {
  getAll(): Promise<ApiResponse<NutritionUnitMaster[]>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.get(url);
  },
  add(payload: NutritionUnitMasterAddPayload): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.post(url, payload);
  },
  update(payload: NutritionUnitMasterUpdatePayload[]): Promise<ApiResponse<any>> {
    let url = ROOT_API_ROUTE;
    return axiosClient.patch(url, payload);
  },
};

export default nutritionUnitMasterService;
