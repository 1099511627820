import { Button, Col, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';
import './style.scss';

const COLUMN_WIDTH = [10, 30, 30, 30];

interface DemoListModalProps {
  onClose: () => void;
}

function DemoListModal({ onClose }: DemoListModalProps) {
  return (
    <Modal
      show
      size="lg"
      dialogClassName="demo-list-modal"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {/* filter */}
        <div className="mb-3">
          <Row>
            <Col sm={6}>
              <InputGroup>
                <FormControl placeholder="Search" aria-label="Search" />
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
        </div>

        {/* list */}
        <div className="sticky-table" style={{ height: '540px' }}>
          <div className="div-table fixsize-column">
            <div className="div-thead div-tr">
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[0]}%` }}>
                削除
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[1]}%` }}>
                種別
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[2]}%` }}>
                名称
              </div>
              <div className="div-th" style={{ width: `${COLUMN_WIDTH[3]}%` }}>
                表示順
              </div>
            </div>

            {Array.from(Array(120).keys()).map((rowIndex) => {
              return (
                <div className="div-tr" key={rowIndex}>
                  <div
                    className="div-td d-flex align-items-center justify-content-center"
                    style={{ width: `${COLUMN_WIDTH[0]}%` }}
                  >
                    <Form.Check className="anra-checkbox d-flex justify-content-center" label="" type="checkbox" />
                  </div>
                  <div className="div-td d-flex align-items-center" style={{ width: `${COLUMN_WIDTH[1]}%` }}>
                    Row 1, Col 2
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[2]}%` }}>
                    <Form.Select>
                      <option>option 1</option>
                      <option>option 2</option>
                      <option>option 3</option>
                      <option>option 4</option>
                      <option>option 5</option>
                    </Form.Select>
                  </div>
                  <div className="div-td" style={{ width: `${COLUMN_WIDTH[3]}%` }}>
                    <Form.Control type="text" placeholder="Enter number" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DemoListModal;
