import { AxiosError } from 'axios';
import { ApiError, NutritionMasterAddPayload, NutritionMasterUpdatePayload } from 'models';
import nutritionMasterService from '../nutritionMasterService';

function useNutritionMaster() {
  async function addNutritionMaster(payload: NutritionMasterAddPayload) {
    try {
      const response = await nutritionMasterService.add(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  async function updateNutritionMaster(payload: NutritionMasterUpdatePayload[]) {
    try {
      const response = await nutritionMasterService.update(payload);
      return {
        data: response,
        error: undefined,
      };
    } catch (e) {
      return {
        data: null,
        error: (e as AxiosError<ApiError>).response?.data,
      };
    }
  }

  return { addNutritionMaster, updateNutritionMaster };
}

export default useNutritionMaster;
