import { CustomInputDatePicker } from 'components/common/CustomInputDatePicker';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { LiaTimesSolid } from 'react-icons/lia';
import './style.scss';

interface DemoFormModalProps {
  onClose: () => void;
}

function DemoFormModal({ onClose }: DemoFormModalProps) {
  return (
    <Modal
      show
      size="lg"
      dialogClassName="demo-form-modal"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header className="border-bottom-0 justify-content-end">
        <Button variant="primary" className="btn--auto" onClick={onClose}>
          <LiaTimesSolid size={16} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="textbox">
            <Form.Label column sm="4" className="text-end">
              TextBox
            </Form.Label>
            <Col sm="8">
              <Form.Control type="text" placeholder="Enter text" isInvalid={true} />
              <Form.Control.Feedback type="invalid">This field is required.</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="textarea">
            <Form.Label column sm="4" className="text-end">
              Example textarea
            </Form.Label>
            <Col sm="8">
              <Form.Control as="textarea" rows={3} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="combobox">
            <Form.Label column sm="4" className="text-end">
              Example select
            </Form.Label>
            <Col sm="8">
              <Form.Select>
                <option>option 1</option>
                <option>option 2</option>
                <option>option 3</option>
                <option>option 4</option>
                <option>option 5</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="4" className="text-end">
              Example input numeric
            </Form.Label>
            <Col sm="8">
              <Form.Control type="number" placeholder="0" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="textnumeric">
            <Form.Label column sm="4" className="text-end">
              Example Date
            </Form.Label>
            <Col sm="8">
              <div style={{ maxWidth: '120px' }}>
                <ReactDatePicker
                  locale="ja"
                  selected={new Date()}
                  onChange={(date: Date) => {
                    // setFormSearchValue({ ...formSearchValue, dataDate: date });
                  }}
                  customInput={<CustomInputDatePicker />}
                  dateFormat="yyyy/MM/dd"
                  name="data_date"
                />
              </div>
            </Col>
          </Form.Group>
          <div className="text-center mt-5">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default DemoFormModal;
