import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { REPLACE_ITEM_TABLE_HEADER } from '../constants/replaceItemTableHeader';
import ReactDatePicker from 'react-datepicker';
import { InputFormDatePicker } from 'components/formfields/InputFormDatePicker';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { ReplaceItemModalForm } from './replaceItemModal';

const ReplaceItemTable = ({
  selectedItems,
  chosenProduct,
  replaceProduct,
  setValue,
  date,
  errors,
}: {
  chosenProduct: any;
  replaceProduct: any;
  selectedItems: any[];
  setValue: UseFormSetValue<ReplaceItemModalForm>;
  errors: FieldErrors<ReplaceItemModalForm>;

  date: {
    selectedEndDateValue: Date;
    replaceEndDateValue: Date;
    replaceStartDateValue: Date;
  };
}) => {
  const { t } = useTranslation();
  const isChosenReplaceItem = replaceProduct.code !== undefined;
  const unitName = selectedItems.every((item) => item.unit === selectedItems[0].unit) ? selectedItems[0].unitName : '';

  return (
    <div className="sticky-table" style={{ overflow: 'inherit', zIndex: 0 }}>
      <Table>
        <thead>
          <tr>
            {REPLACE_ITEM_TABLE_HEADER.map((header, idx) => (
              <th style={{ width: header.width }} key={idx}>
                {t(`${header.title}`)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {/* Chosen product */}
          <tr>
            <th
              className="text-start"
              colSpan={REPLACE_ITEM_TABLE_HEADER.length}
              style={{ width: '100%', borderTop: 'none', borderBottom: '1px solid #ced4da' }}
            >
              {t(`ItemReplacementScreen.replaced_target`)}
            </th>
          </tr>

          <tr>
            <td>{chosenProduct.code}</td>
            <td>{chosenProduct.name}</td>
            <td>{}</td>
            <td style={{ width: '16%' }}>
              <div style={{ maxWidth: '120px' }}>
                <ReactDatePicker
                  locale="ja"
                  selected={date.selectedEndDateValue}
                  onChange={(date: Date) => {
                    setValue('selected_end_date', date);
                  }}
                  customInput={<InputFormDatePicker />}
                  dateFormat="yyyy/MM/dd"
                  name="selected_end_date"
                  disabled={isChosenReplaceItem}
                />
              </div>
              {errors?.selected_end_date && (
                <span className="small text-danger d-inline-block mt-1">{errors?.selected_end_date?.message}</span>
              )}
            </td>
            <td>{isChosenReplaceItem ? 0 : ''}</td>
            <td className="text-end">{unitName}</td>
          </tr>

          {/* Replace product */}
          <tr>
            <th
              className="text-start"
              colSpan={REPLACE_ITEM_TABLE_HEADER.length}
              style={{ width: '100%', borderTop: 'none', borderBottom: '1px solid #ced4da' }}
            >
              {t(`ItemReplacementScreen.replace_target`)}
            </th>
          </tr>
          {replaceProduct.code ? (
            <tr>
              <td>{replaceProduct.code}</td>
              <td>{replaceProduct.name}</td>
              <td style={{ width: '16%' }}>
                <div style={{ maxWidth: '120px' }}>
                  <ReactDatePicker
                    locale="ja"
                    selected={date.replaceStartDateValue}
                    onChange={(date: Date) => {
                      setValue('replace_start_date', date);
                    }}
                    customInput={<InputFormDatePicker />}
                    dateFormat="yyyy/MM/dd"
                    name="replace_start_date"
                  />
                </div>
                {errors?.replace_start_date && (
                  <span className="small text-danger d-inline-block mt-1">{errors?.replace_start_date?.message}</span>
                )}
              </td>
              <td>
                {' '}
                <div style={{ maxWidth: '120px' }}>
                  <ReactDatePicker
                    locale="ja"
                    selected={date.replaceEndDateValue}
                    onChange={(date: Date) => {
                      setValue('replace_end_date', date);
                    }}
                    customInput={<InputFormDatePicker />}
                    dateFormat="yyyy/MM/dd"
                    name="replace_end_date"
                  />
                </div>
                {errors?.replace_end_date && (
                  <span className="small text-danger d-inline-block mt-1">{errors?.replace_end_date?.message}</span>
                )}
              </td>
              <td></td>
              <td>{replaceProduct.unit}</td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export { ReplaceItemTable };
