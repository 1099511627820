import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ElementClassificationMasterUpdatePayload } from 'models/masterdata';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Stack } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  elementClassificationMasterActions,
  selectElementClassificationMasterData,
} from 'store/ElementClassificationMaster/elementClassificationMasterSlice';
import ElementClassificationMasterForm from './components/ElementClassificationMasterForm';
import ElementClassificationMasterList from './components/ElementClassificationMasterList';
import useElementClassificationMaster from './hooks/useElementClassificationMaster';
import './style.scss';

export type ElementClassificationMasterListFormValues = {
  data: ElementClassificationMasterUpdatePayload[];
};

function ElementClassificationMasterFeature() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // hooks
  const { updateElementClassificationMaster } = useElementClassificationMaster();

  // refs
  const formRef = useRef<HTMLFormElement>(null);
  const nextDisplayOrder = useRef<number>(1);

  // states
  const [showForm, setShowForm] = useState(false);

  // selectors
  const elementClassificationMasterList = useAppSelector(selectElementClassificationMasterData);

  // side effects
  useEffect(() => {
    dispatch(elementClassificationMasterActions.fetchElementClassificationMasterList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (elementClassificationMasterList.length === 0) {
      nextDisplayOrder.current = 1;
      return;
    }

    const maxDisplayOrder = elementClassificationMasterList.reduce(
      (max, item) => (item.display_order > max ? item.display_order : max),
      elementClassificationMasterList[0].display_order
    );
    nextDisplayOrder.current = maxDisplayOrder + 1;
  }, [elementClassificationMasterList]);

  //events
  function handleOpenForm() {
    setShowForm(true);
  }

  function handleCloseForm() {
    setShowForm(false);
  }

  function handleElementClassificationMasterFormReflect() {
    dispatch(elementClassificationMasterActions.fetchElementClassificationMasterList());
  }

  function handleSubmit() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }

  function handleSubmitElementClassificationMasterListForm(formValues: ElementClassificationMasterListFormValues) {
    (async () => {
      const { data, error } = await updateElementClassificationMaster(formValues.data);
      if (data) {
        toast.success(data?.message);
        dispatch(elementClassificationMasterActions.fetchElementClassificationMasterList());
      } else {
        if (error && error.error) {
          toast.error(<div dangerouslySetInnerHTML={{ __html: error.message || t('App.unexpected_error') }}></div>);
        }
      }
    })();
  }

  return (
    <div className="page-content d-flex flex-column">
      {/* header */}
      <div className="pb-3 border-bottom">
        <Row>
          <Col xs="4">
            <h4 className="mb-0">{t('ElementClassificationMaster.head_title')}</h4>
          </Col>
          <Col xs="8" className="d-flex justify-content-end">
            <Stack direction="horizontal" gap={4}>
              <Button onClick={handleSubmit}>{t('ElementClassificationMaster.update')}</Button>
              <Button onClick={handleOpenForm}>{t('ElementClassificationMaster.register')}</Button>
            </Stack>
          </Col>
        </Row>
      </div>

      {/* list */}
      <ElementClassificationMasterList
        formRef={formRef}
        data={elementClassificationMasterList}
        onSubmitForm={handleSubmitElementClassificationMasterListForm}
      />

      {/* Form Modal */}
      {showForm &&
        createPortal(
          <ElementClassificationMasterForm
            nextDisplayOrder={nextDisplayOrder.current}
            onClose={handleCloseForm}
            onReflect={handleElementClassificationMasterFormReflect}
          />,
          document.body
        )}
    </div>
  );
}

export default ElementClassificationMasterFeature;
