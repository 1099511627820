import { Text, View } from '@react-pdf/renderer';
import { styles } from './table-styles';
import { PDF_COLOR, PDF_LETTET_BLANK } from '../../../constants/style';

const Body = ({ values }: any) => {
  return (
    <View>
      {values.map((obj: any, rowIndex: number) => {
        if (obj.isHidden) return <></>;
        if (obj.category) {
          return (
            // CATEGORY
            <View
              style={[
                styles.col,
                styles.colCategory,
                {
                  backgroundColor: PDF_COLOR['background'][obj.style.backgroundColor],
                },
              ]}
            >
              <Text style={[{ paddingLeft: 1 }]}>{obj.title}</Text>
            </View>
          );
        } else {
          const valueObj = obj.value;
          return (
            <View style={styles.row} key={rowIndex} wrap={false}>
              {Object.keys(valueObj).map((key, colIndex) => {
                return (
                  // VALUE
                  <View
                    style={[
                      styles.col,
                      styles.colValue,
                      colIndex === 0 ? styles.colNameValue : {},
                      {
                        ...valueObj[key]?.style,
                        width: valueObj[key].style?.width + '%',
                      },
                    ]}
                    key={colIndex}
                  >
                    <Text
                      style={{
                        marginLeft: colIndex === 0 ? PDF_LETTET_BLANK * valueObj[key]?.blank || 0 : 0,
                      }}
                    >
                      {valueObj[key].value}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        }
      })}
    </View>
  );
};

export { Body };
