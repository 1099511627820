import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import '../style.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  elementClassificationMasterActions,
  selectElementClassificationMasterData,
} from 'store/ElementClassificationMaster/elementClassificationMasterSlice';

interface SelectItemProps {
  value: string;
  id: string;
  readonly: boolean;
  style?: React.CSSProperties;
  showFirstOption?: boolean;
  isInValid?: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ClassificationItemSelect = ({
  value,
  readonly,
  id,
  onChange,
  style,
  showFirstOption,
  isInValid,
}: SelectItemProps) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const dispatch = useAppDispatch();
  const elementClassificationMasterList = useAppSelector(selectElementClassificationMasterData);

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (elementClassificationMasterList.length === 0) {
      dispatch(elementClassificationMasterActions.fetchElementClassificationMasterList());
    }
  }, [dispatch, elementClassificationMasterList.length]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    onChange(e);
  };

  const renderOptions = useCallback(
    () =>
      elementClassificationMasterList.map((classificationItem, idx) => (
        <option key={idx} value={classificationItem.element_classification_cd}>
          {classificationItem.element_classification_name}
        </option>
      )),
    [elementClassificationMasterList]
  );

  return (
    <Form.Select
      className="form-select"
      aria-label=".form-select-sm"
      value={selectedValue}
      onChange={handleChange}
      disabled={readonly}
      ref={inputRef}
      id={id}
      name={id}
      style={style}
      isInvalid={isInValid ?? false}
    >
      {showFirstOption && <option value="all">全て</option>}
      {selectedValue === '' && <option value=""></option>}
      {renderOptions()}
    </Form.Select>
  );
};

export default ClassificationItemSelect;
