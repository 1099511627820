import { NotFound } from 'components/common/NotFound';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AllergyItemMasterFeature from './AllergyItemMaster';
import AllergyMasterFeature from './AllergyMaster';
import ElementClassificationMaster from './ElementClassificationMaster';
import MenuCategoryMasterFeature from './MenuCategoryMaster';
import NutritionMasterFeature from './NutritionMaster';
import NutritionUnitMasterFeature from './NutritionUnitMaster';

function MasterDataManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/allergy-master`} />
      </Route>
      <Route path={`${path}/allergy-master`}>
        <AllergyMasterFeature />
      </Route>
      <Route path={`${path}/allergy-item-master`}>
        <AllergyItemMasterFeature />
      </Route>
      <Route path={`${path}/nutrition-master`}>
        <NutritionMasterFeature />
      </Route>
      <Route path={`${path}/nutrition-unit-master`}>
        <NutritionUnitMasterFeature />
      </Route>
      <Route path={`${path}/menu-category-master`}>
        <MenuCategoryMasterFeature />
      </Route>
      <Route path={`${path}/element-classification-master`}>
        <ElementClassificationMaster />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default MasterDataManagement;
