export const APP_NAME = 'Allergen Calories Management';
export const APP_VERSION = '1.0';

export const DATE_FORMAT_YYYY_MM_DD = 'yyyy/MM/DD';
export const DATE_HYPHEN_FORMAT = 'YYYY-MM-DD';

export const MODAL_TYPES = {
  ALERT_MODAL: 'ALERT_MODAL',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
};

// not insert 400 into this list
// error code 400 is handled by your feature
export const ERROR_NOTIFICATION_CODES = [403, 404, 500, 503];

export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';

// export const APPROVAL_STATUS_APPROVED_COMPLETED_REPORT_COMPLETED = 1;
// export const APPROVAL_STATUS_APPROVED_COMPLETED_NEED_REPORT = 2;
// export const APPROVAL_STATUS_APPROVED_COMPLETED = 3;
// export const APPROVAL_STATUS_SENT = 4;
// export const APPROVAL_STATUS_SEND_BACK = 5;
// export const APPROVAL_STATUS_UNSENT = 6;

export const PERMISSIONS = {};

export const AREA_MANAGEMENT_LEVEL = '300';
export const BLOCK_MANAGEMENT_LEVEL = '200';

// A-005, A-007
export const PDF_FORMAT_SOURCE = [
  {
    value: 0,
    label: '普通',
  },
  {
    value: 1,
    label: '特殊',
  },
];

export const CHART_COL_WIDTH_SOURCE = [
  {
    value: 1,
    label: '通常',
  },
  {
    value: 2,
    label: '幅広',
  },
];
