import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  authActions,
  selectCompanyOptions,
  selectErrorMessage,
  selectIsLoading,
  selectUserJob,
  selectFormErrors,
} from 'features/Auth/authSlice';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal, ModalProps, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Login, Role } from '../models/login';
import './Login.scss';
import { selectNotificationStatus } from 'components/common/ErrorNotification/errorNotificationSlice';
import authStorage from '../authStorage';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // params
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  const isLoggedIn = authStorage.isAuthenticated();

  // selector
  const companiesOptions = useAppSelector(selectCompanyOptions);
  const isLoading = useAppSelector(selectIsLoading);
  const jobList = useAppSelector(selectUserJob);
  const errorMessage = useAppSelector(selectErrorMessage);
  const formErrors = useAppSelector(selectFormErrors);
  // state
  const [validated, setValidated] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  //ref
  const formLoginRef = React.createRef<HTMLFormElement>();

  // useEffect
  useEffect(() => {
    dispatch(authActions.clearErrorMessage()); // Clear the error message
    dispatch(authActions.clearFormErrors()); // Clear the form errors
  }, [dispatch]);

  useEffect(() => {
    if (companiesOptions.length === 0) {
      dispatch(authActions.fetchCompanyList());
    }
  }, [dispatch, companiesOptions.length]);

  useEffect(() => {
    if (isLoggedIn) {
      if (jobList && jobList.length > 1) {
        setShowJobModal(true);
      } else if (jobList && jobList.length === 1) {
        if (redirectUrl && !isEmpty(redirectUrl)) {
          history.push(redirectUrl);
        } else {
          history.push('/');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn, jobList?.length, redirectUrl, history]);

  //submit form
  const submitForm = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      let params = {
        company_cd: event.target.company_cd.value,
        cd: event.target.cd.value,
        password: event.target.password.value,
        preym: 0,
      } as Login;
      dispatch(authActions.actionLogin(params));
    }
    event.currentTarget.blur();
    event.preventDefault();
  };

  // Function to render error messages for a field
  const renderErrorMessages = (field: string) => {
    const errors = formErrors ? formErrors[field] : undefined;
    return Array.isArray(errors) ? (
      <>
        {errors.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </>
    ) : null;
  };

  type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

  const handleChange = (event: ChangeEvent<FormControlElement>) => {
    const fieldName = event.target.name; // Assuming your form inputs have a 'name' attribute
    const errors = formErrors ? formErrors[fieldName] : undefined;
    if (errors) {
      dispatch(authActions.clearFormFieldError(fieldName));
    }
    if (!isEmpty(errorMessage)) {
      dispatch(authActions.clearErrorMessage());
    }
  };

  return (
    <>
      <div className="container-login bg-main">
        <div className="card-login">
          <div className="text">
            <h3>{t('Login.title')}</h3>
          </div>
          <br />
          <Form noValidate validated={validated} onSubmit={submitForm} ref={formLoginRef}>
            <Form.Group className="mb-3">
              <div className="row">
                <Form.Label className="col-4 col-form-label text-start">{t('Login.company_name')}</Form.Label>

                <Form.Select
                  onChange={handleChange}
                  required
                  defaultValue=""
                  isInvalid={!!formErrors?.company_cd && formErrors?.company_cd.length > 0}
                  name="company_cd"
                >
                  <option disabled value="">
                    {t('Login.please_select_a_company')}
                  </option>
                  {companiesOptions &&
                    companiesOptions.map((company, index) => {
                      return (
                        <option key={index} value={company.value}>
                          {company.label}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {renderErrorMessages('company_cd') || t('Error.company')}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row>
                <Form.Label className="col-4 col-form-label text-start">{t('Login.employee_code')}</Form.Label>
                <Form.Control
                  type="text"
                  name="cd"
                  isInvalid={!!formErrors?.cd && formErrors?.cd.length > 0}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {renderErrorMessages('cd') || t('Error.employee_id')}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row>
                <Form.Label className="col-4 col-form-label text-start">{t('Login.password')}</Form.Label>

                <Form.Control
                  type="password"
                  name="password"
                  isInvalid={!!formErrors?.password && formErrors?.password.length > 0}
                  onChange={handleChange}
                  autoComplete="true"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {renderErrorMessages('password') || t('Error.password')}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
            {errorMessage ? (
              <div className="error my-3">
                <span>{errorMessage}</span>
              </div>
            ) : (
              ''
            )}
            <div className="d-flex justify-content-center">
              <Button type="submit">
                {t('Login.sign_in')}
                {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <LoginWithOrganization jobList={jobList} show={showJobModal} />
    </>
  );
};

export default LoginPage;

interface ModalJobLogin extends ModalProps {
  jobList: Role[] | undefined;
}

function LoginWithOrganization({ jobList, ...rest }: ModalJobLogin) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [selectedJobCd, setSelectedJobCd] = useState('');
  const [selectedCompanyCd, setSelectedCompanyCd] = useState('');
  const [selectedDepartmentCd, setSelectedDepartmentCd] = useState('');

  const status = useAppSelector(selectNotificationStatus);

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  // params
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');

  const handleRadioChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      //split string by - and get company cd, department cd , job cd
      const [companyCd, departmentCd, jobCd] = event.currentTarget.value.split('-');
      setSelectedCompanyCd(companyCd);
      setSelectedDepartmentCd(departmentCd);
      setSelectedJobCd(jobCd);
    }
  };

  function handleSaveJob() {
    const selectedJob =
      jobList?.find(
        (job) =>
          job.job_cd === selectedJobCd &&
          job.company_cd === selectedCompanyCd &&
          job.department_cd === selectedDepartmentCd
      ) || jobList?.[0];

    if (selectedJob) {
      dispatch(authActions.actionSetSelectedJob(selectedJob));
      if (status === 'success') {
        if (redirectUrl && !isEmpty(redirectUrl)) {
          history.push(redirectUrl);
        } else {
          history.push('/');
        }
      }
    }
  }

  return (
    <>
      <Modal {...rest} size="lg" dialogClassName="dialog-job" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="justify-content-center">
          <Modal.Title className="text-center">
            <div className="d-flex flex-column text-center">
              <div className="text">
                <h5>{t('Login.concurrent_post_proxy_authority_exists')} </h5>{' '}
              </div>
              <div className="text">
                <h5>{t('Login.please_select_a_job')}</h5>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <div className="row">
            <div className="col">
              <Form.Group className="mb-3">
                {jobList?.map((job: Role, index) => {
                  return (
                    <Form.Check
                      className="anra-checkbox"
                      key={index}
                      type="radio"
                      label={`${job.company_name}  ${job.department_name} ${
                        job.job_name === '' ? '「-」' : job.job_name
                      }`}
                      id={`job-${job.job_cd}-${index}`}
                      name="radioGroup"
                      value={`${job.company_cd}-${job.department_cd}-${job.job_cd}`}
                      defaultChecked={index === 0 ? true : false}
                      onChange={handleRadioChange}
                    />
                  );
                })}
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button onClick={handleSaveJob}>{t('Login.selection')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
