import { ChangeEvent, memo } from 'react';
import { Form } from 'react-bootstrap';
import { AllergyMasterFilterValues } from '..';
import { ALLERGY_MASTER_TYPE_FILERSOURCE, DEFAULT_FILTER_ALLERGY_MASTER_TYPE } from '../constants';

interface AllergyMasterFilterProps {
  onFilter?: (filterValue: AllergyMasterFilterValues) => void;
}

function AllergyMasterFilter({ onFilter }: AllergyMasterFilterProps) {
  function handleTypeFilterChange(e: ChangeEvent<HTMLInputElement>) {
    const type = parseInt((e.target as HTMLInputElement).value);
    onFilter && onFilter({ type });
  }

  return (
    <div className="py-3">
      <div className="d-flex align-items-center gap-2">
        {ALLERGY_MASTER_TYPE_FILERSOURCE.map((typeFilter) => {
          return (
            <Form.Check
              key={typeFilter.value}
              className="anra-checkbox"
              inline
              label={typeFilter.label}
              value={typeFilter.value}
              defaultChecked={typeFilter.value === DEFAULT_FILTER_ALLERGY_MASTER_TYPE}
              name="type"
              type="radio"
              id={`type-${typeFilter.value}`}
              onChange={handleTypeFilterChange}
            />
          );
        })}
      </div>
    </div>
  );
}

export default memo(AllergyMasterFilter);
