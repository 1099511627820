import axiosClient from 'utils/axiosClient';
import { API } from './Interface';
import { ElementConfigurationSettingList, ElementConfigurationSettingRequest, NutritionMaster } from './models/model';
import { ApiResponse, PaginationResponse } from 'models/common';

const api: API = {
  getById: async (id: string, action: string, application_date?: string) => {
    return await axiosClient.get(
      `parts-configuration-setting/edit/${id}?action=${action}&application_date=${application_date}`
    );
  },
  create: function (data: ElementConfigurationSettingRequest): Promise<ApiResponse> {
    return axiosClient.post(`parts-configuration-setting/new`, data);
  },
  confirmRequest: async (data: ElementConfigurationSettingRequest) => {
    return await axiosClient.post(`parts-configuration-setting/confirm-request`, data);
  },
  approveRequest: async (data: Partial<ElementConfigurationSettingRequest>) => {
    return await axiosClient.post(`parts-configuration-setting/approve-request`, data);
  },
  admitRequest: async (data: Partial<ElementConfigurationSettingRequest>) => {
    return await axiosClient.post(`parts-configuration-setting/admit-request`, data);
  },
  cancelRequest: async (data: Partial<ElementConfigurationSettingRequest>) => {
    return await axiosClient.post(`parts-configuration-setting/reject-request`, data);
  },
  getAll: async (
    url: string = 'parts-configuration-setting'
  ): Promise<PaginationResponse<ElementConfigurationSettingList>> => {
    return await axiosClient.get(url);
  },
  getAllNutritions: async (): Promise<NutritionMaster[]> => {
    return await axiosClient.get('nutritions');
  },
};

export default api;
